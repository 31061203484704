.flex-col-53 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 26px;
  min-height: 310px;
  padding: 18px 11px 18px 11px;
  width: 390px;
  filter: drop-shadow(0px 0px 10px rgba(33, 66, 125, 0.2));
}

.flex-col-54 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 9px;
  margin-top: 7px;
  width: 218px;
}

.fish {
  letter-spacing: 0;
}

.lot-weight-457kg {
  letter-spacing: 0;
  margin-top: 12px;
}

.flex-row-8 {
  align-items: flex-start;
  display: flex;
  margin-top: 12px;
  min-width: 337px;
  margin-left: 9px;
  position: relative;
}

.flex-col-55 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 119px;
}

.pln-boat-0409954-sealess-rock {
  letter-spacing: 0;
  margin-top: 12px;
}

.group-117 {
  height: 130px;
  margin-left: 88px;
  width: 130px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.group-117 > img {
  height: 130px;
  width: 130px;
}

.overlap-group-container-2 {
  align-items: flex-start;
  display: flex;
  margin-top: 25px;
  min-width: 390px;
}

.overlap-group2-22 {
  align-items: flex-start;
  background-color: var(--x5);
  border-radius: 8px;
  display: flex;
  height: 100px;
  min-width: 187px;
  padding: 9px 14px;
}

.fishing-30 {
  letter-spacing: 0;
  min-height: 21px;
  min-width: 53px;
}

.fishing-step-icon {
  align-self: flex-end;
  height: 52px;
  margin-bottom: 6px;
  margin-left: 51px;
  width: 45px;
}

.overlap-group3-3 {
  align-items: flex-start;
  background-color: var(--x5);
  border-radius: 8px;
  display: flex;
  height: 100px;
  margin-left: 16px;
  min-width: 187px;
  padding: 10px 16px;
}

.place-6 {
  letter-spacing: 0;
  min-height: 21px;
  min-width: 59px;
}

.landing-step-icon {
  align-self: flex-end;
  height: 54px;
  margin-bottom: 5px;
  margin-left: 38px;
  width: 54px;
}

.overlap-group-container-3 {
  align-items: flex-start;
  display: flex;
  margin-top: 12px;
  min-width: 390px;
}

.overlap-group4-7 {
  align-items: flex-start;
  background-color: var(--x5);
  border-radius: 8px;
  display: flex;
  height: 100px;
  min-width: 187px;
  padding: 10px 14px;
}

.weighting {
  letter-spacing: 0;
  min-height: 21px;
  min-width: 76px;
}

.weighting-step-icon {
  align-self: flex-end;
  height: 52px;
  margin-bottom: 4px;
  margin-left: 31px;
  width: 45px;
}

.overlap-group5-4 {
  align-items: flex-start;
  background-color: var(--x5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  min-height: 100px;
  padding: 10px 16px;
  width: 187px;
}

.auctioning {
  letter-spacing: 0;
  min-height: 21px;
  width: 100px;
}

.auctioning-step-icon {
  align-self: flex-end;
  height: 51px;
  margin-right: 3.48px;
  margin-top: 2px;
  width: 52px;
}

.overlap-group-container-4 {
  align-items: flex-start;
  display: flex;
  height: 100px;
  margin-top: 12px;
  min-width: 390px;
}

.overlap-group6-4 {
  align-items: flex-start;
  background-color: var(--x5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  padding: 11px 16px;
  width: 187px;
}

.transportation {
  letter-spacing: 0;
  min-height: 21px;
}

.transport-step-icon {
  align-self: flex-end;
  height: 44px;
  margin-right: 4.89px;
  margin-top: 10px;
  width: 70px;
}

.overlap-group7-4 {
  align-items: flex-end;
  background-color: var(--x5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  min-height: 100px;
  padding: 9px 18px;
  width: 187px;
}

.custom-clearance {
  letter-spacing: 0;
  margin-right: 17px;
  min-height: 21px;
  min-width: 134px;
}

.custom-cl-step-icon {
  height: 50px;
  margin-right: 2.92px;
  margin-top: 8px;
  width: 42px;
}

.passport-button {
  cursor: pointer;
}
.passport-button svg {
  color: #1e3c72;
  opacity: 0.2;
}

.passport-active-button {
  background: linear-gradient(#1e3c72 0%, #2a5298 100%);
  color: var(--white);
}
.passport-active-button svg {
  color: var(--white);
}

.passport-disabled-button {
  background-color: #e7e7e7;
  cursor: default;
  color: #c0c0c0;
}
.passport-disabled-button svg {
  color: #d4d4d4;
  opacity: 1;
}

.passport-transaction-fields {
  margin-left: 9px;
}

.transaction-id-field {
  margin-top: 12px;
  min-height: 44px;
}

.transaction-id-field-label {
  margin-bottom: 7px;
  display: flex;
  align-items: center;
}

.transaction-id-field-label > *:not(:last-child) {
  margin-right: 12px;
}

.transaction-id-field-value {
  display: block;
  height: 14px;
  color: #767676;
}

.transaction-url-field {
  margin-top: 8px;
  display: flex;
  align-items: center;
  color: var(--bay-of-many);
}

.transaction-url-field:hover > *:first-child {
  text-decoration: underline;
}

.transaction-url-field > *:not(:last-child) {
  margin-right: 12px;
}

.hash-tooltip {
  cursor: pointer;
}
