.first-addon,
.first-addon-1,
.first-addon-2,
.first-addon-3,
.first-addon-4,
.first-addon-5,
.first-addon-6,
.first-addon-7,
.first-addon-8,
.first-addon-9,
.first-addon-10,
.first-addon-11,
.first-addon-12,
.first-addon-13,
.first-addon-14,
.first-addon-15,
.first-addon-16,
.first-addon-17,
.first-addon-18,
.first-addon-19 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  height: 16px;
  min-width: 16px;
  position: relative;
}

