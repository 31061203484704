.pills-content {
  align-items: center;
  display: flex;
  height: 20px;
  min-width: 57px;
  padding: 0 8px;
  position: relative;
}

.us {
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 8px;
  margin-top: -1px;
  min-height: 20px;
  white-space: nowrap;
}

.pills-content.pills-content-1,
.pills-content.pills-content-4,
.pills-content.pills-content-7,
.pills-content.pills-content-10,
.pills-content.pills-content-13,
.pills-content.pills-content-16,
.pills-content.pills-content-19,
.pills-content.pills-content-22,
.pills-content.pills-content-25,
.pills-content.pills-content-28,
.pills-content.pills-content-31,
.pills-content.pills-content-34,
.pills-content.pills-content-37,
.pills-content.pills-content-40,
.pills-content.pills-content-43,
.pills-content.pills-content-46,
.pills-content.pills-content-49,
.pills-content.pills-content-52,
.pills-content.pills-content-55,
.pills-content.pills-content-58 {
  min-width: 59px;
}

.pills-content.pills-content-2,
.pills-content.pills-content-5,
.pills-content.pills-content-8,
.pills-content.pills-content-11,
.pills-content.pills-content-14,
.pills-content.pills-content-17,
.pills-content.pills-content-20,
.pills-content.pills-content-23,
.pills-content.pills-content-26,
.pills-content.pills-content-29,
.pills-content.pills-content-32,
.pills-content.pills-content-35,
.pills-content.pills-content-38,
.pills-content.pills-content-41,
.pills-content.pills-content-44,
.pills-content.pills-content-47,
.pills-content.pills-content-50,
.pills-content.pills-content-53,
.pills-content.pills-content-56,
.pills-content.pills-content-59 {
  min-width: 89px;
}
