.group-454 {
  align-items: flex-start;
  display: flex;
  height: 567px;
  left: 50%;
  min-width: 403px;
  position: fixed;
  top: 50%;
  z-index: calc(var(--modal-zindex) + 1);
  transform: translate(-50%, -50%);
}

.overlap-group11-1x {
  height: 567px;
  width: 395px;
}

.rectangle-447 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 4px 8px #00000040;
  height: 551px;
  left: 0;
  position: absolute;
  top: 16px;
  width: 384px;
}

.report-an-issue {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-weight: 700;
  left: 29px;
  letter-spacing: 0;
  position: absolute;
  top: 48px;
}

.rectangle-448 {
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  height: 248px;
  left: 23px;
  position: absolute;
  top: 217px;
  width: 328px;
}

.rectangle-449 {
  background-color: var(--bay-of-many);
  border-radius: 10px;
  height: 40px;
  left: 153px;
  position: absolute;
  top: 497px;
  width: 77px;
}

.rectangle-450 {
  border: 2px solid var(--gravel);
  border-radius: 10px;
  height: 40px;
  left: 30px;
  position: absolute;
  top: 112px;
  width: 126px;
}

.place-26 {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-weight: 600;
  left: 171px;
  letter-spacing: 0;
  position: absolute;
  top: 508px;
}

.subject {
  left: 52px;
  letter-spacing: 0;
  position: absolute;
  top: 124px;
}

.write-a-message {
  left: 29px;
  letter-spacing: 0;
  position: absolute;
  top: 185px;
}

.group-429-1 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 0px 0px 4px #00000040;
  display: flex;
  height: 32px;
  left: 363px;
  min-width: 32px;
  padding: 1.1px 1.1px;
  position: absolute;
  top: 0;
  cursor: pointer;
}

.cancel {
  height: 30px;
  width: 30px;
}

.x4-1 {
  height: 26px;
  left: 117px;
  position: absolute;
  top: 119px;
  width: 26px;
}
