.group-436,
.group-436-1 {
  align-items: flex-start;
  display: flex;
  height: 62px;
  left: 38px;
  min-width: 333px;
  position: absolute;
  top: 183px;
}

.flex-col-93,
.flex-col-96 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 62px;
  width: 90px;
}

.lot-size,
.lot-size-1 {
  letter-spacing: 0;
  margin-left: 9px;
  min-height: 20px;
}

.rectangle-1-13,
.rectangle-1-14 {
  background-color: var(--white);
  border-radius: 5px;
  height: 34px;
  margin-top: 8px;
  width: 90px;
}

.flex-col-94,
.flex-col-97 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 26px;
  min-height: 62px;
  width: 90px;
}

.lot-grade-6,
.lot-grade-7 {
  letter-spacing: 0;
  margin-left: 3px;
  min-height: 20px;
}

.flex-col-95,
.flex-col-98 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 31px;
  min-height: 62px;
  width: 90px;
}

.lot-weight-6,
.lot-weight-7 {
  letter-spacing: 0;
  margin-right: 9.0px;
  min-height: 20px;
  min-width: 71px;
}

