.number-48 {
  color: var(--text);
  font-weight: 300;
  left: 567px;
  position: absolute;
  top: 610px;
}

.rectangle-134-8 {
  -webkit-backdrop-filter: blur(4px) brightness(100%);
  backdrop-filter: blur(4px) brightness(100%);
  background-color: var(--concord);
  height: 973px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1445px;
}

.overlap-group12-14 {
  height: 685px;
  left: 510px;
  position: absolute;
  top: 73px;
  width: 410px;
  z-index: calc(var(--modal-zindex) + 1);
}

.rectangle-135-8 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 7px 27px #00000040;
  height: 670px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 400px;
}

.sales-note-14 {
  left: 39px;
  letter-spacing: 0;
  position: absolute;
  top: 48px;
}

.name-38 {
  color: var(--x66);
  font-weight: 300;
  left: 55px;
  position: absolute;
  top: 542px;
}

.x13kg-5 {
  left: 267px;
  letter-spacing: 0;
  position: absolute;
  top: 349px;
}

.span1-24 {
  color: var(--x66);
  font-weight: 300;
}

.grade-a-4 {
  color: var(--x66);
  font-weight: 300;
  left: 55px;
  position: absolute;
  top: 349px;
}

.pollack-6 {
  color: var(--x66);
  font-weight: 300;
  left: 55px;
  position: absolute;
  top: 253px;
}

.devante-ltd-8 {
  color: var(--text);
  font-weight: 300;
  left: 55px;
  position: absolute;
  top: 156px;
}

.x13kg-6 {
  color: var(--text);
  font-weight: 300;
  left: 267px;
  position: absolute;
  top: 446px;
}

.number-49 {
  color: var(--text);
  font-weight: 300;
  left: 55px;
  position: absolute;
  top: 446px;
}
