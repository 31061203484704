.overlap-group13-20 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  min-height: 316px;
  width: 576px;
}

.audit-logs-17 {
  letter-spacing: 0;
  margin-left: 33px;
  min-height: 24px;
  padding-top: 18px;
  padding-bottom: 20px;
}

.flex-row-61 {
  align-items: center;
  align-self: center;
  display: flex;
  height: 20px;
  margin-left: 9px;
  margin-top: 10px;
  min-width: 519px;
}

.scheduled-2-documents-to-be-issued-27 {
  letter-spacing: 0;
  min-height: 20px;
  width: 276px;
}

.view-140 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(30, 60, 114) 0%,
    rgb(42, 82, 152) 100%
  );
  background-clip: text;
  letter-spacing: 0;
  margin-bottom: 1px;
  margin-left: 217px;
  min-height: 17px;
  min-width: 26px;
}

.overlap-group1-60 {
  align-items: flex-start;
  background-image: url(/static/img/vector-39@1x.svg);
  background-size: 100% 100%;
  display: flex;
  margin-left: 1px;
  margin-top: 8px;
  min-width: 574px;
}

.flex-row-58 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  height: 21px;
  margin-left: 9px;
  margin-top: 8px;
  min-width: 519px;
}

.scheduled-7 {
  align-self: flex-end;
  letter-spacing: 0;
  min-height: 20px;
  width: 276px;
}

.view-139 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(30, 60, 114) 0%,
    rgb(42, 82, 152) 100%
  );
  background-clip: text;
  letter-spacing: 0;
  margin-left: 217px;
  min-height: 17px;
}

.flex-row-62 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  height: 22px;
  margin-left: 9px;
  margin-top: 7px;
  min-width: 519px;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: var(--gray-nurse);
  margin-top: 8px;
}

.overlap-group11-17 {
  height: 23px;
  left: 599px;
  position: absolute;
  top: 23px;
  width: 20px;
}

.rectangle-133-17 {
  background-color: var(--x5);
  border-radius: 3.23px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 20px;
}

.text-31-1 {
  left: 5px;
  letter-spacing: 0;
  position: absolute;
  top: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.date-66 {
  letter-spacing: 0;
  margin-left: 32px;
  margin-top: 1px;
  min-height: 17px;
}
