.tab-line,
.tab-line-1,
.tab-line-2,
.tab-line-3,
.tab-line-4,
.tab-line-5,
.tab-line-6,
.tab-line-7,
.tab-line-8,
.tab-line-9,
.tab-line-10,
.tab-line-11,
.tab-line-12,
.tab-line-13,
.tab-line-14,
.tab-line-15,
.tab-line-16,
.tab-line-17,
.tab-line-18,
.tab-line-19 {
  align-items: flex-start;
  display: flex;
  height: 22px;
  margin-left: 353px;
  margin-top: 3px;
  min-width: 49px;
  position: relative;
}

