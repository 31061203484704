.pills-item,
.pills-item-1,
.pills-item-2,
.pills-item-3,
.pills-item-4,
.pills-item-5,
.pills-item-6,
.pills-item-7,
.pills-item-8,
.pills-item-9,
.pills-item-10,
.pills-item-11,
.pills-item-12,
.pills-item-13,
.pills-item-14,
.pills-item-15,
.pills-item-16,
.pills-item-17,
.pills-item-18,
.pills-item-19 {
  align-items: center;
  background-color: var(--azure-radiance-2);
  border-radius: 6px 0px 0px 6px;
  display: flex;
  height: 28px;
  overflow: hidden;
  position: relative;
  width: 57px;
}

