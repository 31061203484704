.group-506 {
  align-items: flex-start;
  display: flex;
  height: 685px;
  left: 510px;
  min-width: 432px;
  position: absolute;
  top: 157px;
  z-index: calc(var(--modal-zindex) + 1);
  top: 73px;
  left: 50%;
  transform: translateX(-50%);
}

.overlap-group11-19 {
  height: 685px;
  position: relative;
  width: 410px;
}

.rectangle-135-11 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 7px 27px #00000040;
  height: 670px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 400px;
}

.lot-data-21 {
  left: 39px;
  letter-spacing: 0;
  position: absolute;
  top: 48px;
}

.group-412-21 {
  height: 18px;
  left: 123px;
  position: absolute;
  top: 49px;
  width: 27px;
}

.name-53 {
  color: var(--text);
  font-weight: 300;
  left: 56px;
  position: absolute;
  top: 139px;
}

.small {
  left: 56px;
  letter-spacing: 0;
  position: absolute;
  top: 221px;
}

.span1-29 {
  color: var(--text);
  font-weight: 300;
}

.grade-a-7 {
  color: var(--text);
  font-weight: 300;
  left: 170px;
  position: absolute;
  top: 221px;
}

.x13kg-9 {
  color: var(--text);
  font-weight: 300;
  left: 307px;
  position: absolute;
  top: 221px;
}

.st-georges-channel-3 {
  color: var(--text);
  font-weight: 300;
  left: 56px;
  position: absolute;
  top: 302px;
}

.by-net-2 {
  color: var(--text);
  font-weight: 300;
  left: 256px;
  position: absolute;
  top: 302px;
}

.phone-4 {
  color: var(--text);
  font-weight: 300;
  left: 56px;
  position: absolute;
  top: 465px;
}

.sealess-rock-4 {
  color: var(--text);
  font-weight: 300;
  left: 244px;
  position: absolute;
  top: 465px;
}

.name-54 {
  color: var(--text);
  font-weight: 300;
  left: 56px;
  position: absolute;
  top: 545px;
}

.pollack-9 {
  color: var(--text);
  font-weight: 300;
  left: 56px;
  position: absolute;
  top: 384px;
}
