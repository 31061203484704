.verify-header {
  background: unset;
  background-color: #1d1b5a;
  position: sticky;
  top: 0;
}

.home-menu-button {
  height: 26px;
  letter-spacing: 0;
  min-width: 44px;
}

.about-menu-button {
  height: 26px;
  letter-spacing: 0;
  margin-left: 30px;
  min-width: 44px;
}

.what-we-offer-menu-button {
  height: 26px;
  letter-spacing: 0;
  margin-left: 30px;
  min-width: 107px;
}

.how-it-works-menu-button {
  height: 26px;
  letter-spacing: 0;
  margin-left: 30px;
  min-width: 97px;
}

.contact-menu-button {
  height: 26px;
  letter-spacing: 0;
  margin-left: 30px;
  min-width: 56px;
}

.verification {
  color: var(--white);
  font-family: var(--font-family-khula);
  font-size: 28px;
  font-weight: 700;
  height: 45px;
  left: 51px;
  letter-spacing: 0;
  position: absolute;
  top: 21px;
  text-transform: uppercase;
}

@media screen and (max-width: 900px) {
  .verify-header {
    min-width: unset;
    padding-right: 51px;
    width: 100vw;
  }
  .verify-header .verification {
    font-size: 24px;
    top: 24px;
  }
  .navigation-menu > .home-menu-button,
  .navigation-menu > .about-menu-button,
  .navigation-menu > .what-we-offer-menu-button,
  .navigation-menu > .how-it-works-menu-button,
  .navigation-menu > .contact-menu-button {
    width: 0;
    overflow: hidden;
    display: none;
  }
  .verify-header > .navigation-menu:before {
    content: "";
    background-image: url(/static/img/burger-menu.svg);
    width: 33px;
    height: 22px;
  }
}
