@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,900,300,500,600,300italic|Inter:400|Khula:700,600|Open+Sans:400,700,600,800,800italic|Roboto:400");

@font-face {
  font-family: "SF Pro Display-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("static/fonts/SFPRODISPLAYMEDIUM.OTF");
}
@font-face {
  font-family: "SF Pro Display-Semibold";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/SF-Pro-Display-Semibold.otf") format("opentype");
}

html {
  font-size: 62.5%;
}

body {
  min-height: 100vh;
}

#app {
  min-height: 100vh;
}

header {
  min-width: 1440px;
  z-index: var(--app-bar-zindex);
}

.portal-page-content {
  background-color: var(--alabaster);
  padding: 35px 91px;
  min-width: 1440px;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: calc(100vh - 84px);
}

.portal-page-content a {
  text-decoration: none;
  color: inherit;
}

.verify-page-content {
  padding: 60px 29px 0 51px;
  background-color: var(--white);
  min-width: 1440px;
  min-height: calc(100vh - 84px);
}

.verify-page-content a {
  text-decoration: none;
}

.center-dialog {
  height: 956px;
  position: absolute;
  min-width: 1440px;
  top: 0;
}

.overlay {
  -webkit-backdrop-filter: blur(4px) brightness(100%);
  backdrop-filter: blur(4px) brightness(100%);
  background-color: var(--concord);
  left: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: var(--modal-zindex);
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.span-newline {
  position: relative;
  top: 4px;
}

* {
  box-sizing: border-box;
}
