.group-394,
.group-394-1,
.group-394-2,
.group-394-3,
.group-394-4,
.group-394-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  min-height: 294px;
  margin-left: 37px;
  width: 237px;
}

.group-383,
.group-383-1,
.group-383-2,
.group-383-3,
.group-383-4,
.group-383-5 {
  display: flex;
  height: 23px;
  min-width: 107px;
}

.weighting-2,
.weighting-3,
.weighting-4,
.weighting-5,
.weighting-6,
.weighting-7 {
  letter-spacing: 0;
  min-height: 23px;
  min-width: 79px;
}

.fishery-name-james-miles,
.fishery-name-james-miles-1,
.fishery-name-james-miles-2,
.fishery-name-james-miles-3,
.fishery-name-james-miles-4,
.fishery-name-james-miles-5 {
  letter-spacing: 0;
  margin-top: 11px;
  min-height: 20px;
}

.span1-2,
.span1-3,
.span1-4,
.span1-5,
.span1-6,
.span1-7 {
  color: var(--text);
  font-weight: 300;
}

.group-394-item,
.group-394-item-2,
.group-394-item-4,
.group-394-item-6,
.group-394-item-8,
.group-394-item-10 {
  letter-spacing: 0;
  margin-top: 5px;
  min-height: 20px;
}

.group-394-item-1,
.group-394-item-3,
.group-394-item-5,
.group-394-item-7,
.group-394-item-9,
.group-394-item-11 {
  letter-spacing: 0;
  margin-top: 5px;
  min-height: 40px;
}

.weighting-icon {
  margin-bottom: 8px;
  margin-left: 7px;
}
