.frame-1-6 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: inset 1px 1px 12px #000000ba;
  display: flex;
  overflow: hidden;
  width: 570px;
}

.overlap-group2-23 {
  align-items: flex-start;
  background-image: url(/static/img/image-6-5@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 308px;
  min-width: 570px;
  padding: 19.5px 88px;
}

.chart-name-31 {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  min-width: 37px;
  white-space: nowrap;
  position: absolute;
  margin-left: 26px;
  margin-top: 17px;
}

.ellipse-44-3 {
  align-self: center;
  background-color: var(--zest);
  border-radius: 7px;
  height: 14px;
  margin-left: 34px;
  margin-top: 114px;
  width: 14px;
}

.frame-1-6.frame-1-7,
.frame-1-6.frame-1-8 {
  height: 308px;
  left: 90px;
  position: absolute;
  top: 119px;
}
