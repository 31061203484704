.rectangle-135-4 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 7px 27px #00000040;
  height: 775px;
  left: 316px;
  position: absolute;
  top: 119px;
  width: 809px;
  z-index: calc(var(--modal-zindex) + 1);
}

.catch-certificate-2 {
  left: 355px;
  letter-spacing: 0;
  position: absolute;
  top: 145px;
  z-index: calc(var(--modal-zindex) + 1);
}

.group-410-7 {
  height: 26px;
  left: 525px;
  position: absolute;
  top: 144px;
  width: 21px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group14-4 {
  height: 61px;
  left: 355px;
  position: absolute;
  top: 643px;
  width: 331px;
  z-index: calc(var(--modal-zindex) + 1);
}

.phone-2 {
  color: var(--x66);
  font-weight: 300;
  left: 17px;
  position: absolute;
  top: 37px;
  z-index: calc(var(--modal-zindex) + 1);
}

.sealess-rock-2 {
  color: var(--x66);
  font-weight: 300;
  left: 206px;
  position: absolute;
  top: 37px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group15-2 {
  height: 61px;
  left: 355px;
  position: absolute;
  top: 471px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.name-18 {
  color: var(--x66);
  font-weight: 300;
  left: 17px;
  position: absolute;
  top: 37px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group16-2 {
  height: 62px;
  left: 355px;
  position: absolute;
  top: 297px;
  width: 328px;
  z-index: calc(var(--modal-zindex) + 1);
}

.x13kg {
  left: 229px;
  letter-spacing: 0;
  position: absolute;
  top: 37px;
  z-index: calc(var(--modal-zindex) + 1);
}

.span1-20 {
  color: var(--x66);
  font-weight: 300;
  z-index: calc(var(--modal-zindex) + 1);
}

.grade-a-2 {
  color: var(--x66);
  font-weight: 300;
  left: 17px;
  position: absolute;
  top: 37px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group17-2 {
  height: 61px;
  left: 355px;
  position: absolute;
  top: 211px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.pollack-2 {
  color: var(--x66);
  font-weight: 300;
  left: 17px;
  position: absolute;
  top: 37px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group18-2 {
  height: 61px;
  left: 355px;
  position: absolute;
  top: 557px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.devante-ltd-2 {
  color: var(--x66);
  font-weight: 300;
  left: 17px;
  position: absolute;
  top: 37px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group19-2 {
  height: 62px;
  left: 355px;
  position: absolute;
  top: 384px;
  width: 328px;
  z-index: calc(var(--modal-zindex) + 1);
}

.x13kg-1 {
  color: var(--x66);
  font-weight: 300;
  left: 229px;
  position: absolute;
  top: 37px;
  z-index: calc(var(--modal-zindex) + 1);
}

.number-23 {
  color: var(--x66);
  font-weight: 300;
  left: 17px;
  position: absolute;
  top: 37px;
  z-index: calc(var(--modal-zindex) + 1);
}

.group-459-x {
  align-items: flex-start;
  display: flex;
  height: 61px;
  left: 355px;
  min-width: 333px;
  position: absolute;
  top: 729px;
  z-index: calc(var(--modal-zindex) + 1);
}

.flex-col-63 {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 61px;
  width: 136px;
  z-index: calc(var(--modal-zindex) + 1);
}

.catch-certificate-3 {
  letter-spacing: 0;
  margin-right: 9px;
  min-height: 20px;
  min-width: 113px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group20-3 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 5px;
  display: flex;
  height: 37px;
  margin-top: 7px;
  min-width: 136px;
  padding: 6px 10px;
  z-index: calc(var(--modal-zindex) + 1);
}

.k234-h5685-dfh9,
.date-39 {
  color: var(--x66);
  font-weight: 300;
  min-height: 20px;
  position: relative;
  top: 4px;
  z-index: calc(var(--modal-zindex) + 1);
}

.flex-col-64 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 53px;
  min-height: 61px;
  width: 136px;
  z-index: calc(var(--modal-zindex) + 1);
}

.date-of-inssuance {
  letter-spacing: 0;
  margin-right: 4px;
  min-height: 20px;
  min-width: 118px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group21-3 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 5px;
  display: flex;
  height: 37px;
  margin-top: 7px;
  min-width: 136px;
  padding: 6px 22px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group22-2 {
  height: 61px;
  left: 764px;
  position: absolute;
  top: 294px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.ice {
  left: 17px;
  letter-spacing: 0;
  position: absolute;
  top: 37px;
  z-index: calc(var(--modal-zindex) + 1);
}

.span1-21 {
  color: var(--text);
  font-weight: 300;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group23-2 {
  height: 61px;
  left: 764px;
  position: absolute;
  top: 211px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.porthcalw-sd {
  color: var(--text);
  font-weight: 300;
  left: 17px;
  position: absolute;
  top: 37px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group24-2 {
  height: 61px;
  left: 764px;
  position: absolute;
  top: 377px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.x2-4-dock-st-porthcawl-cf36-3-bl-uk,
.st-georges-channel-2,
.name-19,
.x3205911-h3-s {
  color: var(--text);
  font-weight: 300;
  left: 17px;
  position: absolute;
  top: 37px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group25-2 {
  height: 61px;
  left: 764px;
  position: absolute;
  top: 460px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group26-2 {
  height: 61px;
  left: 764px;
  position: absolute;
  top: 543px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group27-2 {
  height: 61px;
  left: 764px;
  position: absolute;
  top: 729px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.chris_-hemsworth_-signature-1 {
  height: 37px;
  left: 804px;
  object-fit: cover;
  position: absolute;
  top: 664px;
  width: 93px;
  z-index: calc(var(--modal-zindex) + 1);
}
