.group-381 {
  align-items: center;
  display: flex;
  height: 26px;
  left: 549px;
  min-width: 93px;
  position: absolute;
  top: 245px;
  z-index: calc(var(--modal-zindex) + 1);
}

.fishing-28 {
  letter-spacing: 0;
  min-width: 64px;
}

.group-73 {
  height: 21px;
  margin-bottom: 1.04px;
  margin-left: 8px;
  width: 18px;
}

.group-381.group-381-1 {
  left: 558px;
  top: 242px;
}
