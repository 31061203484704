header {
  background: linear-gradient(
    180deg,
    rgb(30, 60, 114) 0%,
    rgb(35.22, 101.23, 216.75) 100%
  );
  padding: 0 91px;
  height: 84px;
  width: 100%;
  position: static;
}

.navigation-menu {
  align-items: center;
  display: flex;
  height: 84px;
  justify-content: flex-end;
  color: var(--white);
  position: relative;
}

.navigation-menu a {
  text-decoration: none;
  color: inherit;
  height: 100%;
  display: flex;
  align-items: center;
}

.active-menu-button {
  align-items: center;
  background-color: var(--black-3);
  display: flex;
  justify-content: center;
  min-height: 84px;
  text-shadow: 0px 6px 5px #00000040;
  letter-spacing: 0;
  position: relative;
}

.active-menu-button::after {
  background-color: var(--white);
  height: 4px;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  content: "";
}

.disabled-menu-button {
  cursor: default;
}

.dashboard-menu-button {
  height: 21px;
  padding-left: 25px;
  padding-right: 25px;
}

.passport-menu-button {
  height: 21px;
  padding-left: 25px;
  padding-right: 25px;
}

.audit-logs-menu-button {
  height: 21px;
  padding-left: 25px;
}
