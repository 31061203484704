.group-437 {
  align-items: flex-start;
  display: flex;
  height: 62px;
  left: 0;
  min-width: 328px;
  position: absolute;
  top: 0;
}

.flex-col-34 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 62px;
  width: 137px;
}

.address {
  letter-spacing: 0;
  margin-left: 9px;
  min-height: 20px;
}

.rectangle-143 {
  background-color: var(--white);
  border-radius: 5px;
  height: 34px;
  margin-top: 8px;
  width: 137px;
}

.flex-col-35 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 38px;
  min-height: 62px;
  width: 149px;
}

.total-weight {
  letter-spacing: 0;
  margin-left: 5px;
  min-height: 20px;
}

.rectangle-140 {
  background-color: var(--white);
  border-radius: 5px;
  height: 34px;
  margin-top: 8px;
  width: 149px;
}

.group-437.group-437-6,
.group-437.group-437-7 {
  left: 38px;
  top: 408px;
}

