.group-429 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 0px 0px 4px #00000040;
  display: flex;
  height: 32px;
  left: 897px;
  min-width: 32px;
  padding: 1px;
  position: absolute;
  top: 197px;
  cursor: pointer;
  z-index: calc(var(--modal-zindex) + 1);
}

.cancel-6 {
  height: 30px;
  width: 30px;
}

.group-429.group-429-1,
.group-429.group-429-3 {
  left: 1093px;
  top: 89px;
}

.group-429.group-429-2 {
  left: 897px;
  top: 194px;
}

.group-429.group-429-4,
.group-429.group-429-5 {
  left: 1107px;
  top: 106px;
}

.group-429.group-429-6,
.group-429.group-429-7 {
  left: 759px;
  top: 0;
}

.group-429.group-429-8,
.group-429.group-429-9,
.group-429.group-429-10,
.group-429.group-429-11 {
  left: 378px;
  top: 0;
}
