.group-428 {
  align-items: flex-start;
  display: flex;
  height: 38px;
  left: 610px;
  min-width: 225px;
  position: absolute;
  top: 875px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group29 {
  align-items: center;
  background-color: var(--guardsman-red-3);
  border-radius: 10px;
  display: flex;
  height: 38px;
  min-width: 100px;
  padding: 5px 20px;
  cursor: pointer;
}

.cancel-18,
.save-6 {
  letter-spacing: 0;
}

.overlap-group30 {
  align-items: center;
  background-color: var(--unmellow-yellow);
  border-radius: 10px;
  display: flex;
  height: 38px;
  margin-left: 21px;
  min-width: 100px;
  padding: 7px 31px;
  cursor: pointer;
}

.group-428.group-428-1 {
  top: 826px;
}

.group-428.group-428-2 {
  left: 279px;
  top: 618px;
}

.group-428.group-428-3,
.group-428.group-428-4 {
  left: 89px;
  top: 618px;
}
