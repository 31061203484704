.group-435 {
  align-items: flex-start;
  display: flex;
  height: 61px;
  left: 0;
  min-width: 331px;
  position: absolute;
  top: 0;
}

.flex-col-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 61px;
  width: 179px;
}

.catch-area {
  letter-spacing: 0;
  margin-left: 9px;
  min-height: 20px;
}

.rectangle-138-1 {
  background-color: var(--white);
  border-radius: 5px;
  height: 34px;
  margin-top: 7px;
  width: 179px;
}

.flex-col-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 22px;
  min-height: 61px;
  width: 126px;
}

.catch-method {
  letter-spacing: 0;
  margin-left: 5px;
  min-height: 20px;
}

.rectangle-141 {
  background-color: var(--white);
  border-radius: 5px;
  height: 34px;
  margin-top: 7px;
  width: 126px;
}

.group-435.group-435-2,
.group-435.group-435-3 {
  left: 38px;
  top: 265px;
}

