.frame-1 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: inset 1px 1px 12px #000000ba;
  display: flex;
  height: 308px;
  left: 90px;
  overflow: hidden;
  position: absolute;
  top: 122px;
  width: 570px;
}

.overlap-group4 {
  align-items: flex-start;
  background-image: url(/static/img/image-6@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 308px;
  min-width: 570px;
  padding: 59.5px 131.5px;
  position: relative;
}

.chart-name-21 {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  white-space: nowrap;
  position: absolute;
  top: 18px;
  left: 26px;
}

.frame-1.frame-1-1 {
  left: 99px;
  top: 119px;
}

.frame-1.frame-1-2 {
  height: unset;
  left: unset;
  position: unset;
  top: unset;
}
