.pills-item-40,
.pills-item-41,
.pills-item-42,
.pills-item-43,
.pills-item-44,
.pills-item-45,
.pills-item-46,
.pills-item-47,
.pills-item-48,
.pills-item-49,
.pills-item-50,
.pills-item-51,
.pills-item-52,
.pills-item-53,
.pills-item-54,
.pills-item-55,
.pills-item-56,
.pills-item-57,
.pills-item-58,
.pills-item-59 {
  align-items: center;
  background-color: var(--white);
  border-radius: 0px 6px 6px 0px;
  box-shadow: inset 0px 1px 0px #c4c7cf , inset 0px -1px 0px #c4c7cf , inset -1px 0px 0px #c4c7cf;
  display: flex;
  height: 28px;
  overflow: hidden;
  position: relative;
  width: 89px;
}

