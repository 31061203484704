.section-data {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 114px;
  position: relative;
  width: 148px;
  margin-top: 18px;
  margin-left: 37px;
}

.group-381-2-xx {
  display: flex;
  height: 23px;
  min-width: 80px;
}

.fishing-32 {
  letter-spacing: 0;
  min-height: 23px;
  min-width: 55px;
}

.landing-icon {
  margin-bottom: 8px;
  margin-left: 7px;
}

.group-37,
.group-37-1,
.group-37-2,
.group-37-3,
.group-37-4,
.group-37-5,
.group-37-6 {
  align-items: flex-start;
  display: flex;
  height: 20px;
  margin-top: 11px;
  min-width: 152px;
}

.course-2,
.course-3,
.course-4,
.course-5,
.course-6,
.course-7,
.course-8 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 51px;
}

.route-slooq-1,
.route-slooq-2,
.route-slooq-3,
.route-slooq-4,
.route-slooq-5,
.route-slooq-6,
.route-slooq-7 {
  color: var(--text);
  font-weight: 300;
  margin-left: 10px;
  min-height: 20px;
}

.group-37-7,
.group-37-8,
.group-37-9,
.group-37-10,
.group-37-11,
.group-37-12,
.group-37-13 {
  align-items: flex-start;
  display: flex;
  height: 20px;
  margin-top: 10px;
  min-width: 127px;
}

.date-21,
.date-23,
.date-25,
.date-27,
.date-29,
.date-31,
.date-33 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 35px;
}

.date-22,
.date-24,
.date-26,
.date-28,
.date-30,
.date-32,
.date-34 {
  color: var(--text);
  font-weight: 300;
  margin-left: 26px;
  min-height: 20px;
}

.group-379,
.group-379-1,
.group-379-2,
.group-379-3,
.group-379-4,
.group-379-5,
.group-379-6 {
  align-items: flex-start;
  display: flex;
  height: 20px;
  margin-top: 10px;
  min-width: 119px;
}

.time-2,
.time-3,
.time-4,
.time-5,
.time-6,
.time-7,
.time-8 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 37px;
}

.text-8-1,
.text-20-1,
.text-22-1,
.text-24-1,
.text-26-1,
.text-29-1,
.text-33-1 {
  color: var(--text);
  font-weight: 300;
  margin-left: 24px;
  min-height: 20px;
}
