.group-438 {
  align-items: flex-start;
  display: flex;
  height: 62px;
  left: 0;
  min-width: 328px;
  position: absolute;
  top: 0;
}

.flex-col-22 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 62px;
  width: 137px;
}

.lot-grade {
  letter-spacing: 0;
  margin-left: 3px;
  min-height: 20px;
}

.rectangle-139 {
  background-color: var(--white);
  border-radius: 5px;
  height: 34px;
  margin-top: 8px;
  width: 137px;
}

.flex-col-23 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 34px;
  min-height: 62px;
  width: 153px;
}

.lot-weight {
  letter-spacing: 0;
  margin-left: 9px;
  min-height: 20px;
}

.rectangle-137-1 {
  background-color: var(--white);
  border-radius: 5px;
  height: 34px;
  margin-top: 8px;
  width: 153px;
}

.group-438.group-438-4,
.group-438.group-438-5 {
  left: 38px;
  top: 311px;
}

