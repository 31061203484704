.content-40 {
  align-items: center;
  display: flex;
  height: 22px;
  min-width: 22px;
}

.text-21 {
  letter-spacing: 0;
  line-height: 20px;
  min-height: 20px;
  min-width: 22px;
  white-space: nowrap;
}

.content-40.content-20,
.content-40.content-21,
.content-40.content-22,
.content-40.content-23,
.content-40.content-24,
.content-40.content-25,
.content-40.content-26,
.content-40.content-27,
.content-40.content-28,
.content-40.content-29,
.content-40.content-30,
.content-40.content-31,
.content-40.content-32,
.content-40.content-33,
.content-40.content-34,
.content-40.content-35,
.content-40.content-36,
.content-40.content-37,
.content-40.content-38,
.content-40.content-39 {
  align-items: center;
  display: flex;
  height: 22px;
  left: 577px;
  min-width: 49px;
  position: absolute;
  top: 22px;
}

.content-40.content-20 .text-21,
.content-40.content-21 .text-21,
.content-40.content-22 .text-21,
.content-40.content-23 .text-21,
.content-40.content-24 .text-21,
.content-40.content-25 .text-21,
.content-40.content-26 .text-21,
.content-40.content-27 .text-21,
.content-40.content-28 .text-21,
.content-40.content-29 .text-21,
.content-40.content-30 .text-21,
.content-40.content-31 .text-21,
.content-40.content-32 .text-21,
.content-40.content-33 .text-21,
.content-40.content-34 .text-21,
.content-40.content-35 .text-21,
.content-40.content-36 .text-21,
.content-40.content-37 .text-21,
.content-40.content-38 .text-21,
.content-40.content-39 .text-21 {
  min-width: 49px;
}

