.tab-line-item,
.tab-line-item-1,
.tab-line-item-2,
.tab-line-item-3,
.tab-line-item-4,
.tab-line-item-5,
.tab-line-item-6,
.tab-line-item-7,
.tab-line-item-8,
.tab-line-item-9,
.tab-line-item-10,
.tab-line-item-11,
.tab-line-item-12,
.tab-line-item-13,
.tab-line-item-14,
.tab-line-item-15,
.tab-line-item-16,
.tab-line-item-17,
.tab-line-item-18,
.tab-line-item-19 {
  height: 22px;
  width: 49px;
}

