.rectangle-134-1 {
  -webkit-backdrop-filter: blur(4px) brightness(100%);
  backdrop-filter: blur(4px) brightness(100%);
  background-color: var(--concord);
  height: 952px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
  z-index: calc(var(--modal-zindex) + 1);
}

.health-certificate-2 {
  left: 355px;
  letter-spacing: 0;
  position: absolute;
  top: 145px;
  z-index: calc(var(--modal-zindex) + 2);
}

.group-411-2 {
  height: 20px;
  left: 526px;
  position: absolute;
  top: 143px;
  width: 22px;
  z-index: calc(var(--modal-zindex) + 2);
}

.rectangle-135-1 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 7px 27px #00000040;
  height: 832px;
  left: 306px;
  position: absolute;
  top: 102px;
  width: 809px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group11-1 {
  height: 61px;
  left: 739px;
  position: absolute;
  top: 336px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.x112022 {
  color: var(--x66);
  font-weight: 300;
  left: 17px;
  position: absolute;
  top: 37px;
}

.overlap-group12-1 {
  height: 61px;
  left: 739px;
  position: absolute;
  top: 262px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.place-2 {
  color: var(--x66);
  font-weight: 300;
  left: 17px;
  position: absolute;
  top: 37px;
}

.overlap-group13-1 {
  height: 61px;
  left: 739px;
  position: absolute;
  top: 484px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group14-1 {
  height: 61px;
  left: 739px;
  position: absolute;
  top: 558px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.welsh-porth-police,
.x5518-hb830,
.sammuel-l-13112022,
.w4-d87-k3330 {
  color: var(--text);
  font-weight: 300;
  left: 17px;
  position: absolute;
  top: 37px;
}

.overlap-group15 {
  height: 61px;
  left: 739px;
  position: absolute;
  top: 632px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group16 {
  height: 61px;
  left: 739px;
  position: absolute;
  top: 706px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group17 {
  height: 61px;
  left: 739px;
  position: absolute;
  top: 780px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group18 {
  height: 61px;
  left: 739px;
  position: absolute;
  top: 410px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.place-3,
.name-5 {
  color: var(--x66);
  font-weight: 300;
  left: 17px;
  position: absolute;
  top: 37px;
}

.health-certificate-3 {
  left: 351px;
  letter-spacing: 0;
  position: absolute;
  top: 128px;
}

.group-411-3 {
  height: 20px;
  left: 522px;
  position: absolute;
  top: 126px;
  width: 22px;
}

.overlap-group19 {
  height: 61px;
  left: 351px;
  position: absolute;
  top: 486px;
  width: 331px;
  z-index: calc(var(--modal-zindex) + 1);
}

.st-georges-channel,
.name-4,
.phone {
  color: var(--x66);
  font-weight: 300;
  left: 17px;
  position: absolute;
  top: 37px;
}

.by-net {
  color: var(--x66);
  font-weight: 300;
  left: 218px;
  position: absolute;
  top: 37px;
}

.overlap-group20-1 {
  height: 61px;
  left: 351px;
  position: absolute;
  top: 782px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group21-1 {
  height: 61px;
  left: 351px;
  position: absolute;
  top: 708px;
  width: 331px;
  z-index: calc(var(--modal-zindex) + 1);
}

.sealess-rock {
  color: var(--x66);
  font-weight: 300;
  left: 206px;
  position: absolute;
  top: 37px;
}

.overlap-group22 {
  height: 61px;
  left: 351px;
  position: absolute;
  top: 560px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.overlap-group23 {
  height: 62px;
  left: 351px;
  position: absolute;
  top: 336px;
  width: 328px;
  z-index: calc(var(--modal-zindex) + 1);
}

.x13-kg {
  left: 229px;
  letter-spacing: 0;
  position: absolute;
  top: 37px;
}

.span1 {
  color: var(--x66);
  font-weight: 300;
}

.grade-a {
  color: var(--x66);
  font-weight: 300;
  left: 17px;
  position: absolute;
  top: 37px;
}

.overlap-group24 {
  height: 61px;
  left: 351px;
  position: absolute;
  top: 262px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.pollack {
  color: var(--x66);
  font-weight: 300;
  left: 17px;
  position: absolute;
  top: 37px;
}

.overlap-group25-1 {
  height: 61px;
  left: 351px;
  position: absolute;
  top: 188px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.devante-ltd {
  color: var(--x66);
  font-weight: 300;
  left: 17px;
  position: absolute;
  top: 36px;
}

.overlap-group26-1 {
  height: 62px;
  left: 351px;
  position: absolute;
  top: 411px;
  width: 328px;
  z-index: calc(var(--modal-zindex) + 1);
}

.x13-kg-1 {
  color: var(--x66);
  font-weight: 300;
  left: 229px;
  position: absolute;
  top: 37px;
  z-index: calc(var(--modal-zindex) + 1);
}

.number-6 {
  color: var(--x66);
  font-weight: 300;
  left: 17px;
  position: absolute;
  top: 37px;
}

.overlap-group27 {
  height: 61px;
  left: 739px;
  position: absolute;
  top: 188px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}

.x26-dgklg78,
.bollachius-pollachius {
  left: 17px;
  letter-spacing: 0;
  position: absolute;
  top: 37px;
}

.overlap-group28 {
  height: 61px;
  left: 351px;
  position: absolute;
  top: 634px;
  width: 327px;
  z-index: calc(var(--modal-zindex) + 1);
}
