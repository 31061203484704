/* Initial */

.frame-2 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 665px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 746px;
}

.passport-initial-map-image {
  height: 665px;
  object-fit: cover;
  width: 1440px;
}

.ellipse-container {
  background-image: url(/static/img/vector-5@1x.svg);
  background-size: 100% 100%;
  height: 335px;
  left: 207px;
  position: absolute;
  top: 159px;
  width: 527px;
}

.ellipse-7 {
  background-color: var(--zest);
  border-radius: 5.37px;
  height: 11px;
  left: 140px;
  position: absolute;
  top: 14px;
  width: 11px;
}

.ellipse-8 {
  border-radius: 10.73px;
  height: 21px;
  left: 135px;
  position: absolute;
  top: 9px;
  width: 21px;
}

/* Fishing */

.overlap-group11-15 {
  border-radius: 10px;
  height: 310px;
  position: relative;
  width: 746px;
}

.frame-2-2 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 310px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 746px;
}

.passport-fishing-map-image {
  height: 310px;
  object-fit: cover;
  width: 746px;
}

.ellipse-container-2 {
  background-image: url(/static/img/vector-5-1@2x.svg);
  background-size: 100% 100%;
  height: 219px;
  left: 272px;
  position: absolute;
  top: 72px;
  width: 344px;
}

.ellipse-7-2 {
  background-color: var(--zest);
  border-radius: 3.5px;
  height: 7px;
  left: 92px;
  position: absolute;
  top: 10px;
  width: 7px;
}

.ellipse-8-2 {
  border-radius: 7px;
  height: 15px;
  left: 88px;
  position: absolute;
  top: 6px;
  width: 15px;
}

.group-410-18 {
  align-items: flex-start;
  background-color: var(--zest);
  border-radius: 26px;
  display: flex;
  height: 32px;
  left: 320px;
  min-width: 32px;
  padding: 5px 6.7px;
  position: absolute;
  top: 67px;
}

.group-73-8 {
  height: 20px;
  width: 17px;
}

/* Landing */

.overlap-group14-7 {
  border-radius: 10px;
  height: 310px;
  position: relative;
  width: 746px;
}

.frame-2-4 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 310px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 746px;
}

.passport-landing-map-image {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 310px;
  justify-content: flex-end;
  min-width: 746px;
  padding: 25px 350.5px;
}

.group-428-5 {
  align-items: center;
  background-color: var(--zest);
  border-radius: 26px;
  display: flex;
  height: 32px;
  min-width: 32px;
  padding: 0 7px;
}

.group-424 {
  height: 18px;
  width: 18px;
}

.overlap-group7-19 {
  height: 225px;
  left: 272px;
  position: absolute;
  top: 66px;
  width: 344px;
}

.vector-5-2 {
  height: 219px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 344px;
}

.ellipse-container-3 {
  border-radius: 7px;
  height: 14px;
  left: 123px;
  position: absolute;
  top: 0;
  width: 14px;
}

.ellipse-7-3 {
  background-color: var(--zest);
  border-radius: 3.5px;
  height: 7px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 7px;
}

.ellipse-8-3 {
  border-radius: 7px;
  height: 15px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 15px;
}

/* Weighting */

.overlap-group14-8 {
  border-radius: 10px;
  height: 310px;
  position: relative;
  width: 746px;
}

.frame-2-6 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 310px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 746px;
}

.passport-weighting-map-image {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 310px;
  justify-content: flex-end;
  min-width: 746px;
  padding: 20px 327.5px;
}

.group-425 {
  align-items: center;
  background-color: var(--zest);
  border-radius: 26px;
  display: flex;
  height: 32px;
  min-width: 32px;
  padding: 0 7.7px;
  justify-content: center;
}

.group-75-6 {
  height: 18px;
  width: 15px;
}

.overlap-group7-20 {
  height: 225px;
  left: 272px;
  position: absolute;
  top: 66px;
  width: 344px;
}

.vector-5-3 {
  height: 219px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 344px;
}

.ellipse-container-4 {
  border-radius: 7px;
  height: 14px;
  left: 123px;
  position: absolute;
  top: 0;
  width: 14px;
}

.ellipse-7-4 {
  background-color: var(--zest);
  border-radius: 3.5px;
  height: 7px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 7px;
}

.ellipse-8-4 {
  border-radius: 7px;
  height: 15px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 15px;
}

/* Auctioning */

.overlap-group14-10 {
  border-radius: 10px;
  height: 310px;
  position: relative;
  width: 746px;
}

.frame-2-10 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 310px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 746px;
}

.passport-auctioning-map-image {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 310px;
  justify-content: flex-end;
  min-width: 746px;
  padding: 25px 300.5px;
}

.group-423 {
  align-items: flex-start;
  background-color: var(--zest);
  border-radius: 26px;
  display: flex;
  height: 32px;
  min-width: 32px;
  padding: 6px 6.5px;
}

.group-417 {
  height: 18px;
  width: 18px;
}

.overlap-group7-23 {
  height: 225px;
  left: 272px;
  position: absolute;
  top: 66px;
  width: 344px;
}

.vector-5-5 {
  height: 219px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 344px;
}

.ellipse-container-6 {
  border-radius: 7px;
  height: 14px;
  left: 123px;
  position: absolute;
  top: 0;
  width: 14px;
}

.ellipse-7-6 {
  background-color: var(--zest);
  border-radius: 3.5px;
  height: 7px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 7px;
}

.ellipse-8-6 {
  border-radius: 7px;
  height: 15px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 15px;
}

/* Transportation */

.overlap-group12-20 {
  border-radius: 10px;
  height: 310px;
  position: relative;
  width: 746px;
}

.frame-2-8 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 310px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 746px;
}

.passport-transportation-map-image {
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 310px;
  min-width: 746px;
  padding-top: 150.5px;
  padding-bottom: 124.5px;
  padding-left: 257.5px;
  padding-right: 257.5px;
}

.group-426 {
  align-items: center;
  background-color: var(--zest);
  border-radius: 26px;
  display: flex;
  height: 32px;
  min-width: 32px;
  padding: 0 4.7px;
}

.group-77-6 {
  height: 14px;
  width: 22px;
}

.overlap-group7-21 {
  height: 219px;
  left: 266px;
  position: absolute;
  top: 72px;
  width: 350px;
}

.vector-5-4 {
  height: 219px;
  left: 6px;
  position: absolute;
  top: 0;
  width: 344px;
}

.ellipse-container-5 {
  border-radius: 7px;
  height: 14px;
  left: 0;
  position: absolute;
  top: 141px;
  width: 14px;
}

.ellipse-7-5 {
  background-color: var(--zest);
  border-radius: 3.5px;
  height: 7px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 7px;
}

.ellipse-8-5 {
  border-radius: 7px;
  height: 15px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 15px;
}

/* Custom Clearance */

.overlap-group13-21 {
  border-radius: 10px;
  height: 310px;
  position: relative;
  width: 746px;
}

.frame-2-12 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 310px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 746px;
}

.passport-custom-clearance-map-image {
  align-items: flex-end;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 310px;
  justify-content: flex-end;
  min-width: 746px;
  padding: 40.5px 114.5px;
}

.group-427-5 {
  align-items: center;
  background-color: var(--zest);
  border-radius: 26px;
  display: flex;
  height: 32px;
  min-width: 32px;
  padding: 0 6.6px;
}

.group-78-7 {
  height: 18px;
  width: 15px;
  position: relative;
  left: 1px;
}

.overlap-group7-26 {
  height: 225px;
  left: 272px;
  position: absolute;
  top: 72px;
  width: 351px;
}

.vector-5-6 {
  height: 219px;
  left: 0;
  position: absolute;
  top: 0;
  width: 344px;
}

.ellipse-container-7 {
  border-radius: 7px;
  height: 14px;
  left: 337px;
  position: absolute;
  top: 211px;
  width: 14px;
}

.ellipse-7-7 {
  background-color: var(--zest);
  border-radius: 3.5px;
  height: 7px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 7px;
}

.ellipse-8-7 {
  border-radius: 7px;
  height: 15px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 15px;
}
