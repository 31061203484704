.group-457 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 764px;
  min-height: 84px;
  position: absolute;
  top: 626px;
  width: 325px;
  z-index: calc(var(--modal-zindex) + 1);
}

.master-signature {
  letter-spacing: 0;
  margin-left: 9px;
  min-height: 20px;
}

.rectangle-145-3 {
  background-color: var(--white);
  border-radius: 5px;
  height: 57px;
  margin-top: 7px;
  width: 325px;
}

.group-457.group-451 {
  min-height: 61px;
  top: 294px;
}

.group-457.group-451 .rectangle-145-3,
.group-457.group-454 .rectangle-145-3,
.group-457.group-450 .rectangle-145-3,
.group-457.group-455 .rectangle-145-3,
.group-457.group-456 .rectangle-145-3,
.group-457.group-458 .rectangle-145-3 {
  height: 34px;
}

.group-457.group-454 {
  min-height: 61px;
  top: 211px;
}

.group-457.group-450 {
  min-height: 61px;
  top: 377px;
}

.group-457.group-455 {
  min-height: 61px;
  top: 460px;
}

.group-457.group-456 {
  min-height: 61px;
  top: 543px;
}

.group-457.group-458 {
  min-height: 61px;
  top: 729px;
}
