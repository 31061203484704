.issuer-info-container {
  align-items: center;
  display: flex;
  height: 97px;
  min-width: 324px;
  position: relative;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  margin-top: 22px;
  overflow: hidden;
}

.issuer-name {
  align-items: flex-start;
  display: flex;
  margin-left: 24px;
}

.issuer-name-text_label {
  height: 25px;
  letter-spacing: 0;
  margin-top: -1px;
}

.issuer-avatar {
  align-items: center;
  background-color: var(--malibu);
  border-radius: 27.5px;
  display: flex;
  height: 55px;
  width: 55px;
  padding: 0 8px;
}

.ellipse-46 {
  background-color: var(--white);
  border-radius: 2px;
  height: 4px;
  margin-top: 1px;
  width: 4px;
}

.vector-49 {
  height: 14px;
  width: 34px;
}

@media screen and (max-width: 900px) {
  .issuer-info-container {
    min-width: unset;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 24px;
    column-gap: 24px;
    padding-top: 22px;
    padding-bottom: 22px;
    height: unset;
  }
  .issuer-name {
    margin-left: 0;
    width: calc(100% - 79px);
  }
}
