.tab-line-item-20,
.tab-line-item-21,
.tab-line-item-22,
.tab-line-item-23,
.tab-line-item-24,
.tab-line-item-25,
.tab-line-item-26,
.tab-line-item-27,
.tab-line-item-28,
.tab-line-item-29,
.tab-line-item-30,
.tab-line-item-31,
.tab-line-item-32,
.tab-line-item-33,
.tab-line-item-34,
.tab-line-item-35,
.tab-line-item-36,
.tab-line-item-37,
.tab-line-item-38,
.tab-line-item-39 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 463px;
  min-height: 28px;
  position: absolute;
  top: 22px;
  width: 22px;
}

.border-bottom-2px,
.border-bottom-2px-1,
.border-bottom-2px-2,
.border-bottom-2px-3,
.border-bottom-2px-4,
.border-bottom-2px-5,
.border-bottom-2px-6,
.border-bottom-2px-7,
.border-bottom-2px-8,
.border-bottom-2px-9,
.border-bottom-2px-10,
.border-bottom-2px-11,
.border-bottom-2px-12,
.border-bottom-2px-13,
.border-bottom-2px-14,
.border-bottom-2px-15,
.border-bottom-2px-16,
.border-bottom-2px-17,
.border-bottom-2px-18,
.border-bottom-2px-19 {
  background-color: var(--azure-radiance);
  height: 2px;
  margin-top: 4px;
  width: 22px;
}

