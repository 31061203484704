.pills,
.pills-1,
.pills-2,
.pills-3,
.pills-4,
.pills-5,
.pills-6,
.pills-7,
.pills-8,
.pills-9,
.pills-10,
.pills-11,
.pills-12,
.pills-13,
.pills-14,
.pills-15,
.pills-16,
.pills-17,
.pills-18,
.pills-19 {
  align-items: flex-start;
  display: flex;
  height: 28px;
  min-width: 205px;
  position: relative;
}

