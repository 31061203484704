.card-header,
.card-header-1,
.card-header-2,
.card-header-3,
.card-header-4,
.card-header-5,
.card-header-6,
.card-header-7,
.card-header-8,
.card-header-9,
.card-header-10,
.card-header-11,
.card-header-12,
.card-header-13,
.card-header-14,
.card-header-15,
.card-header-16,
.card-header-17,
.card-header-18,
.card-header-19 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 6px 6px 0px 0px;
  display: flex;
  height: 40px;
  min-width: 652px;
  padding: 7px 20px;
}

.chart-name-1,
.chart-name-2,
.chart-name-3,
.chart-name-4,
.chart-name-5,
.chart-name-6,
.chart-name-7,
.chart-name-8,
.chart-name-9,
.chart-name-10,
.chart-name-11,
.chart-name-12,
.chart-name-13,
.chart-name-14,
.chart-name-15,
.chart-name-16,
.chart-name-17,
.chart-name-18,
.chart-name-19,
.chart-name-20 {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  min-width: 1px;
  white-space: nowrap;
}

.icon-container,
.icon-container-1,
.icon-container-2,
.icon-container-3,
.icon-container-4,
.icon-container-5,
.icon-container-6,
.icon-container-7,
.icon-container-8,
.icon-container-9,
.icon-container-10,
.icon-container-11,
.icon-container-12,
.icon-container-13,
.icon-container-14,
.icon-container-15,
.icon-container-16,
.icon-container-17,
.icon-container-18,
.icon-container-19 {
  align-self: center;
  height: 24px;
  margin-left: 3px;
  width: 16px;
}

