.group-433 {
  align-items: flex-start;
  display: flex;
  height: 61px;
  left: 0;
  min-width: 331px;
  position: absolute;
  top: 0;
}

.flex-col-10 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 61px;
  width: 136px;
}

.pnl {
  letter-spacing: 0;
  margin-left: 9px;
  min-height: 20px;
}

.rectangle-14 {
  background-color: var(--white);
  border-radius: 5px;
  height: 34px;
  margin-top: 7px;
  width: 136px;
}

.flex-col-11 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 55px;
  min-height: 61px;
  width: 136px;
}

.boat-name {
  letter-spacing: 0;
  margin-left: 7px;
  min-height: 20px;
}

.group-433.group-433-4,
.group-433.group-433-5 {
  left: 38px;
  top: 427px;
}

