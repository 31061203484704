.fishing-verified-step {
  height: 49px;
  margin-top: 61px;
  position: relative;
  width: 802px;
  z-index: 7;
  cursor: pointer;
  padding-left: 21px;
  display: flex;
  align-items: center;
}

.step-label {
  color: var(--astronaut);
  font-weight: 600;
  margin-right: 17px;
}

.landing-verified-step {
  height: 49px;
  position: relative;
  width: 802px;
  z-index: 8;
  cursor: pointer;
  padding-left: 21px;
  display: flex;
  align-items: center;
}

.weighting-verified-step {
  align-items: center;
  display: flex;
  height: 49px;
  width: 802px;
  z-index: 11;
  position: relative;
  cursor: pointer;
  padding-left: 21px;
  display: flex;
  align-items: center;
}

.auctioning-verified-step {
  align-items: center;
  display: flex;
  height: 49px;
  width: 802px;
  z-index: 12;
  position: relative;
  cursor: pointer;
  padding-left: 21px;
  display: flex;
  align-items: center;
}

.transportation-verified-step {
  align-items: center;
  display: flex;
  height: 49px;
  width: 802px;
  z-index: 13;
  position: relative;
  cursor: pointer;
  padding-left: 21px;
  display: flex;
  align-items: center;
}

.custom-clearance-verified-step {
  align-items: center;
  display: flex;
  height: 49px;
  width: 802px;
  z-index: 14;
  position: relative;
  cursor: pointer;
  padding-left: 21px;
  display: flex;
  align-items: center;
}

.border-top-verified-step {
  border-top: 0.2px solid var(--bay-of-many);
}

.border-bottom-verified-step {
  border-bottom: 0.2px solid var(--bay-of-many);
}

.step-icon {
  height: 18px;
  width: 18px;
  margin-right: 17px;
}

.expand-icon {
  height: 26px;
  right: 12px;
  position: absolute;
  top: 14px;
  width: 26px;
  transition: transform 0.2s ease-in-out;
}

.expand-less-icon {
  transform: rotate(-180deg);
}

.step-data {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  width: 802px;
  z-index: 14;
  transition: height 0.4s ease-in-out;
}

.step-data-1-2 {
  height: 214px;
}

.step-data-content {
  padding-left: 35px;
  margin-top: 17px;
}

.closed-step {
  height: 0;
  overflow: hidden;
}

.open-step {
  height: 214px;
}

.group-428-1-xy {
  align-items: flex-start;
  display: flex;
  height: 20px;
  min-width: 152px;
}

.course {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 51px;
}

.route-slooq-1 {
  color: var(--text);
  font-weight: 300;
  margin-left: 10px;
  min-height: 20px;
}

.group-429-2-xy {
  align-items: flex-start;
  display: flex;
  height: 20px;
  margin-top: 10px;
  min-width: 127px;
}

.date {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 35px;
}

.date-1 {
  color: var(--text);
  font-weight: 300;
  margin-left: 26px;
  min-height: 20px;
}

.group-430-3 {
  align-items: flex-start;
  display: flex;
  height: 20px;
  margin-top: 10px;
  min-width: 119px;
}

.time {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 37px;
}

.text-1 {
  color: var(--text);
  font-weight: 300;
  margin-left: 24px;
  min-height: 20px;
}

.report-issue-button {
  align-items: flex-start;
  background-color: var(--bright-red-3);
  border-radius: 13px;
  display: flex;
  height: 42px;
  margin-top: 34px;
  margin-bottom: 41px;
  width: 116px;
  padding: 7.5px 15.5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.report-issue-button-text {
  letter-spacing: 0;
}

.step-data-content-3 {
  margin-top: 10px;
}

.open-step-3 {
  height: 393px;
}

.group-449 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 114px;
  margin-top: 15px;
  min-height: 336px;
  width: 237px;
  z-index: 13;
}

.span1 {
  color: var(--text);
  font-weight: 300;
}

.group-449-item {
  letter-spacing: 0;
  margin-top: 5px;
  min-height: 20px;
}

.group-449-item-1 {
  letter-spacing: 0;
  margin-top: 5px;
  min-height: 40px;
}

.span1-1 {
  color: var(--text);
  font-weight: 300;
}

.group-448-item {
  letter-spacing: 0;
  margin-top: 5px;
  min-height: 20px;
}

.step-data-content-4 {
  margin-top: 10px;
}

.open-step-4 {
  height: 300px;
}

.span-11 {
  color: var(--text);
  font-weight: 300;
}

.group-447-item {
  letter-spacing: 0;
  margin-top: 5px;
  min-height: 20px;
}

.place-and-time {
  letter-spacing: 0;
  margin-top: 5px;
  min-height: 40px;
}

.step-data-content-5 {
  margin-top: 10px;
}

.open-step-5 {
  height: 417px;
}

.group-446-xx {
  align-items: flex-start;
  display: flex;
  height: 526px;
  min-width: 654px;
  z-index: 13;
}

.flex-col-38 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 521px;
  width: 244px;
}

.catch-certificate-k234-h5685-dfh99,
.client-name-devante-ltd {
  letter-spacing: 0;
  min-height: 20px;
  margin-top: 0;
}

.span-4 {
  color: var(--text);
  font-weight: 300;
}

.validating-entity-porthcalw-sd,
.exporter-name-ice,
.fao-area-st-georges-channel,
.master-of-vessel-bentley-grey,
.master-signature-bentley-grey,
.licence-number-3205911-h3-s,
.fish-species-pollack,
.lot-weight-13kg-4,
.fishery-name-james-miles,
.lot-grade-grade-a,
.address-x,
.total-weight-13kg,
.catch-area-st-georges-channel,
.catch-method-by-net,
.name-market-operator-john-klopper,
.food-standart-approval-566-kd0993,
.contry-origin-wales,
.contry-of-destination-france,
.fish-latin-name-bo,
.health-certificate-5518-hb830,
.iso-code-w4-d87-k3330 {
  letter-spacing: 0;
  margin-top: 7px;
  min-height: 20px;
}

.exporter-address-2,
.pln-boat-0409954-sealess-rock-4,
.authority-date-sig,
.place-and-time-of-lo {
  letter-spacing: 0;
  margin-top: 7px;
  min-height: 40px;
}

.date-of-issuance-12112022,
.competent-authority-welsh-porth-police {
  letter-spacing: 0;
  min-height: 20px;
}

.place-and-time-desti {
  letter-spacing: 0;
  min-height: 40px;
}

.overlap-group-23 {
  align-items: center;
  background-color: var(--bright-red-3);
  border-radius: 13px;
  display: flex;
  height: 42px;
  margin-top: 41px;
  min-width: 116px;
  padding: 7px 15px;
  cursor: pointer;
}

.flex-col-39 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 108px;
  min-height: 458px;
  width: 266px;
}

.step-data-content-6 {
  margin-top: 17px;
}

.open-step-6 {
  height: 577px;
}

@media screen and (max-width: 900px) {
  .fishing-verified-step {
    width: 100%;
    margin-top: 0;
  }
  .landing-verified-step {
    width: 100%;
  }
  .weighting-verified-step {
    width: 100%;
  }
  .auctioning-verified-step {
    width: 100%;
  }
  .transportation-verified-step {
    width: 100%;
  }
  .custom-clearance-verified-step {
    width: 100%;
  }
  .closed-step {
    width: 100%;
  }
  .step-data-content-6 {
    overflow-y: auto;
  }
}
