.group-397,
.group-397-1,
.group-397-2,
.group-397-3,
.group-397-4,
.group-397-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  margin-left: 37px;
  min-height: 312px;
  width: 249px;
}

.group-384,
.group-384-1,
.group-384-2,
.group-384-3,
.group-384-4,
.group-384-5 {
  display: flex;
  height: 23px;
  min-width: 148px;
}

.transportation-2,
.transportation-3,
.transportation-4,
.transportation-5,
.transportation-6,
.transportation-7 {
  letter-spacing: 0;
  min-height: 23px;
  min-width: 115px;
}

.client-name-devante-ltd-6,
.client-name-devante-ltd-7,
.client-name-devante-ltd-8,
.client-name-devante-ltd-9,
.client-name-devante-ltd-10,
.client-name-devante-ltd-11 {
  letter-spacing: 0;
  margin-top: 4px;
  min-height: 20px;
}

.span-3,
.span-5,
.span-7,
.span-9,
.span-11,
.span-13 {
  color: var(--text);
  font-weight: 300;
}

.group-397-item,
.group-397-item-1,
.group-397-item-2,
.group-397-item-3,
.group-397-item-4,
.group-397-item-5 {
  letter-spacing: 0;
  margin-top: 5px;
  min-height: 20px;
}

.place-and-time,
.place-and-time-1,
.place-and-time-2,
.place-and-time-3,
.place-and-time-4,
.place-and-time-5 {
  letter-spacing: 0;
  margin-top: 5px;
  min-height: 40px;
}

.transportation-icon {
  margin-bottom: 8px;
  margin-left: 7px;
}
