.card,
.card-1,
.card-2,
.card-3,
.card-4,
.card-5,
.card-6,
.card-7,
.card-8,
.card-9,
.card-10,
.card-11,
.card-12,
.card-13,
.card-14,
.card-15,
.card-16,
.card-17,
.card-18,
.card-19 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 1px 2px #191b231f , 0px 0px 1px #191b2329;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 308px;
  position: absolute;
  top: 0;
  width: 652px;
}

.card-body,
.card-body-1,
.card-body-2,
.card-body-3,
.card-body-4,
.card-body-5,
.card-body-6,
.card-body-7,
.card-body-8,
.card-body-9,
.card-body-10,
.card-body-11,
.card-body-12,
.card-body-13,
.card-body-14,
.card-body-15,
.card-body-16,
.card-body-17,
.card-body-18,
.card-body-19 {
  align-items: flex-start;
  display: flex;
  height: 268px;
  justify-content: flex-end;
  min-width: 652px;
  padding: 20px;
  position: relative;
}

