@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
@import "https://fonts.googleapis.com/css?family=Poppins:400,700,900,300,500,600,300italic|Inter:400|Khula:700,600|Open+Sans:400,700,600,800,800italic|Roboto:400";
@font-face {
  font-family: SF Pro Display-Medium;
  font-style: normal;
  font-weight: 500;
  src: url("SFPRODISPLAYMEDIUM.00d4b6d2.OTF");
}

@font-face {
  font-family: SF Pro Display-Semibold;
  font-style: normal;
  font-weight: 400;
  src: url("SF-Pro-Display-Semibold.a0faeab7.otf") format("opentype");
}

html {
  font-size: 62.5%;
}

body, #app {
  min-height: 100vh;
}

header {
  min-width: 1440px;
  z-index: var(--app-bar-zindex);
}

.portal-page-content {
  background-color: var(--alabaster);
  min-width: 1440px;
  min-height: calc(100vh - 84px);
  flex-direction: column;
  align-items: center;
  padding: 35px 91px;
  display: flex;
  position: relative;
}

.portal-page-content a {
  color: inherit;
  text-decoration: none;
}

.verify-page-content {
  background-color: var(--white);
  min-width: 1440px;
  min-height: calc(100vh - 84px);
  padding: 60px 29px 0 51px;
}

.verify-page-content a {
  text-decoration: none;
}

.center-dialog {
  height: 956px;
  min-width: 1440px;
  position: absolute;
  top: 0;
}

.overlay {
  -webkit-backdrop-filter: blur(4px) brightness();
  backdrop-filter: blur(4px) brightness();
  background-color: var(--concord);
  z-index: var(--modal-zindex);
  position: fixed;
  inset: 0;
}

.container-center-horizontal {
  pointer-events: none;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.container-center-horizontal > * {
  pointer-events: auto;
  flex-shrink: 0;
}

.valign-text-middle {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.span-newline {
  position: relative;
  top: 4px;
}

* {
  box-sizing: border-box;
}

:root {
  --alabaster: #fafafa;
  --astronaut: #1e3c72;
  --astronaut-2: #1f3e75;
  --azure-radiance: #008ff8;
  --azure-radiance-2: #008ff833;
  --bay-of-many: #21427d;
  --black: #000;
  --black-2: #00000042;
  --black-3: #00000029;
  --black-haze: #f5f7fa;
  --bon-jour: #dfdfdf;
  --cod-gray: #0b0b0b;
  --concord: #7d7d7d57;
  --gravel: #484848;
  --fuscous-gray: #565656;
  --gray-nurse: #e9e9e9;
  --guardsman-red: #d4040405;
  --guardsman-red-2: #d40404;
  --guardsman-red-3: #d404040d;
  --guardsman-red-4: #d40606;
  --bright-red: #b40000;
  --bright-red-3: #b400000f;
  --gun-powder: #444459;
  --masala: #3c3c3c;
  --mercury: #e7e7e7;
  --mercury-2: #e6e6e6;
  --mine-shaft: #2f2f2f;
  --mirage: #191b23;
  --ocean-blue-pearl: #4e3dc8;
  --sonic-silver: #75757580;
  --text: #757575;
  --tundora: #454545;
  --unmellow-yellow: #3c21f7;
  --malibu: #78a9ff;
  --diesel: #120304;
  --white: #fff;
  --abbey: #4d4d4d;
  --chateau-green: #4abc5d;
  --x5: #d4e8fb;
  --x66: #4e3cc7;
  --zest: #e7852b;
  --zest-2: #e7852b3b;
  --font-size-l: 14px;
  --font-size-m: 13px;
  --font-size-s: 12px;
  --font-size-xl: 15px;
  --font-size-xs: 11px;
  --font-size-xxl: 16px;
  --font-size-xxs: 9px;
  --font-family-inter: "Inter", Helvetica;
  --font-family-poppins: "Poppins", Helvetica;
  --font-family-sf_pro_display-medium: "SF Pro Display-Medium", Helvetica;
  --font-family-sf_pro_display-semibold: "SF Pro Display-Semibold", Helvetica;
  --font-family-khula: "Khula", Helvetica;
  --font-family-open_sans: "Open Sans", Helvetica;
  --font-family-roboto: "Roboto", Helvetica;
  --app-bar-zindex: 1100;
  --modal-zindex: 1300;
}

.t1 {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  letter-spacing: 0;
  font-style: normal;
  font-weight: 600;
}

.text {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  font-style: normal;
  font-weight: 300;
}

.poppins-semi-bold-tundora-13px {
  color: var(--tundora);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-white-13px {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.poppins-normal-sonic-silver-13px {
  color: var(--text);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.poppins-normal-white-11px {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.poppins-normal-white-12px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.poppins-semi-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
}

.poppins-bold-white-9px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 700;
}

.sfprodisplay-medium-gun-powder-12px {
  color: var(--gun-powder);
  font-family: var(--font-family-sf_pro_display-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.poppins-semi-bold-mine-shaft-13px {
  color: var(--mine-shaft);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.inter-normal-mirage-14px {
  color: var(--mirage);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.poppins-normal-fuscous-gray-11px {
  color: var(--fuscous-gray);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.poppins-medium-mirage-14px {
  color: var(--mirage);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.poppins-black-white-20px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
}

.poppins-semi-bold-astronaut-15px {
  color: var(--astronaut);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
}

.poppins-bold-mirage-16px {
  color: var(--mirage);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.poppins-bold-mine-shaft-16px {
  color: var(--mine-shaft);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.poppins-semi-bold-bay-of-many-16px {
  color: var(--bay-of-many);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-bay-of-many-15px {
  color: var(--bay-of-many);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
}

.poppins-medium-cod-gray-13px {
  color: var(--cod-gray);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.poppins-bold-white-14px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.poppins-bold-astronaut-14px {
  color: var(--astronaut);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.poppins-semi-bold-white-16px-2 {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
}

.poppins-medium-sonic-silver-16px {
  color: var(--text);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-sonic-silver-15px {
  color: var(--text);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-sonic-silver-9px {
  color: var(--text);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 500;
}

.sfprodisplay-normal-gun-powder-12px {
  color: var(--gun-powder);
  font-family: var(--font-family-sf_pro_display-semibold);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.poppins-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.poppins-semi-bold-unmellow-yellow-15-5px {
  color: var(--unmellow-yellow);
  font-family: var(--font-family-poppins);
  font-size: 15.5px;
  font-style: normal;
  font-weight: 600;
}

.poppins-medium-sonic-silver-13px {
  color: var(--text);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.poppins-semi-bold-astronaut-17-5px {
  color: var(--astronaut-2);
  font-family: var(--font-family-poppins);
  font-size: 17.5px;
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-ocean-blue-pearl-13px {
  color: var(--x66);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-white-15px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-cod-gray-13px {
  color: var(--cod-gray);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.poppins-bold-bay-of-many-24px {
  color: var(--bay-of-many);
  font-family: var(--font-family-poppins);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.poppins-semi-bold-sonic-silver-16px {
  color: var(--text);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
}

.poppins-light-masala-14px {
  color: var(--masala);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 300;
}

.poppins-semi-bold-sonic-silver-15px {
  color: var(--sonic-silver);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-guardsman-red-15px {
  color: var(--guardsman-red-4);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
}

.poppins-bold-bay-of-many-14px {
  color: var(--bay-of-many);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.poppins-semi-bold-astronaut-17-6px {
  color: var(--astronaut);
  font-family: var(--font-family-poppins);
  font-size: 17.6px;
  font-style: normal;
  font-weight: 600;
}

.poppins-light-ocean-blue-pearl-13px {
  color: var(--x66);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: italic;
  font-weight: 300;
}

.border-1px-mercury {
  border: 1px solid var(--mercury);
}

.border-1px-azure-radiance {
  border: 1px solid var(--azure-radiance);
}

.border-4px-white {
  border: 4px solid var(--white);
}

.border-1px-mercury-2 {
  border: 1px solid var(--mercury-2);
}

.border-1px-gray-nurse {
  border: 1px solid var(--gray-nurse);
}

.border-2px-zest {
  border: 2px solid var(--zest);
}

.border-1px-x5 {
  border: 1px solid var(--x5);
}

.border-2px-sonic-silver {
  border: 2px solid var(--sonic-silver);
}

.border-2px-guardsman-red-2 {
  border: 2px solid var(--guardsman-red-2);
}

.border-1px-zest {
  border: 1px solid var(--zest);
}

.border-1px-diesel {
  border: 1px solid var(--diesel);
}

.opensans-extra-bold-red-40-3px {
  color: var(--red);
  font-family: var(--font-family-open_sans);
  font-size: 40.3px;
  font-style: italic;
  font-weight: 800;
}

.opensans-bold-chateau-green-14-6px {
  color: var(--chateau-green);
  font-family: var(--font-family-open_sans);
  font-size: 14.6px;
  font-style: normal;
  font-weight: 700;
}

.opensans-bold-white-14-6px {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: 14.6px;
  font-style: normal;
  font-weight: 700;
}

.opensans-normal-diesel-13px {
  color: var(--diesel);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.khula-bold-white-28-1px {
  color: var(--white);
  font-family: var(--font-family-khula);
  font-size: 28.1px;
  font-style: normal;
  font-weight: 700;
}

.opensans-bold-mine-shaft-12px {
  color: var(--mine-shaft);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.opensans-semi-bold-mine-shaft-18px {
  color: var(--mine-shaft);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
}

.opensans-bold-mine-shaft-22px {
  color: var(--mine-shaft);
  font-family: var(--font-family-open_sans);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}

.khula-semi-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-khula);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
}

.roboto-normal-abbey-14px {
  color: var(--abbey);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.poppins-semi-bold-bright-red-13px {
  color: var(--bright-red);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.border-1-5px-bright-red {
  border: 1.5px solid var(--bright-red);
}

.poppins-medium-gravel-14px {
  color: var(--gravel);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}



.logo-area {
  height: 51px;
  min-width: 250px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 17px;
  left: 88px;
}

.group-134 {
  height: 40px;
  width: 54px;
  margin-top: 1px;
}

.vector-3 {
  height: 46px;
  width: 1px;
  margin-bottom: 1px;
  margin-left: 11px;
}

.logo-text {
  width: 169px;
  height: 40px;
  align-items: center;
  margin-left: 11px;
  display: flex;
  position: relative;
}

.sustainability-text {
  letter-spacing: 0;
  position: absolute;
  top: 21px;
  left: 0;
}

.fishing-text {
  letter-spacing: 0;
  position: absolute;
  top: 0;
  left: 1px;
}

header {
  height: 84px;
  width: 100%;
  background: linear-gradient(#1e3c72 0%, #2365d9 100%);
  padding: 0 91px;
  position: static;
}

.navigation-menu {
  height: 84px;
  color: var(--white);
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: relative;
}

.navigation-menu a {
  color: inherit;
  height: 100%;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.active-menu-button {
  background-color: var(--black-3);
  min-height: 84px;
  text-shadow: 0 6px 5px #00000040;
  letter-spacing: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.active-menu-button:after {
  background-color: var(--white);
  height: 4px;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.disabled-menu-button {
  cursor: default;
}

.dashboard-menu-button, .passport-menu-button {
  height: 21px;
  padding-left: 25px;
  padding-right: 25px;
}

.audit-logs-menu-button {
  height: 21px;
  padding-left: 25px;
}

.frame-1 {
  background-color: var(--white);
  height: 308px;
  width: 570px;
  border-radius: 10px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 122px;
  left: 90px;
  overflow: hidden;
  box-shadow: inset 1px 1px 12px #000000ba;
}

.overlap-group4 {
  height: 308px;
  min-width: 570px;
  background-image: url("image-6@1x.27687c14.png");
  background-position: 50%;
  background-size: cover;
  align-items: flex-start;
  padding: 59.5px 131.5px;
  display: flex;
  position: relative;
}

.chart-name-21 {
  letter-spacing: 0;
  min-height: 24px;
  white-space: nowrap;
  line-height: 24px;
  position: absolute;
  top: 18px;
  left: 26px;
}

.frame-1.frame-1-1 {
  top: 119px;
  left: 99px;
}

.frame-1.frame-1-2 {
  height: unset;
  left: unset;
  position: unset;
  top: unset;
}

.frame-1-9 {
  background-color: var(--white);
  height: 308px;
  width: 570px;
  border-radius: 10px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  overflow: hidden;
  box-shadow: inset 1px 1px 12px #000000ba;
}

.chart-name-38 {
  letter-spacing: 0;
  min-height: 24px;
  white-space: nowrap;
  margin-top: 17px;
  margin-left: 26px;
  line-height: 24px;
  position: absolute;
}

.overlap-group2-28 {
  height: 308px;
  min-width: 570px;
  background-image: url("image-6-10@1x.0f022774.png");
  background-position: 50%;
  background-size: cover;
  justify-content: flex-end;
  align-self: flex-end;
  align-items: flex-start;
  padding: 86.5px 199px;
  display: flex;
}

.ellipse-44-6 {
  background-color: var(--zest);
  height: 14px;
  width: 14px;
  border-radius: 7px;
}

.frame-1-9.frame-1-10, .frame-1-9.frame-1-11 {
  position: absolute;
  top: 119px;
  left: 90px;
}

.frame-1-16 {
  background-color: var(--white);
  height: 308px;
  width: 570px;
  border-radius: 10px;
  align-items: flex-end;
  display: flex;
  overflow: hidden;
  box-shadow: inset 1px 1px 12px #000000ba;
}

.overlap-group2-35 {
  height: 320px;
  width: 574px;
  margin-bottom: -11.5px;
  position: relative;
}

.image-6-1 {
  height: 308px;
  object-fit: cover;
  width: 570px;
  position: absolute;
}

.chart-name-51 {
  letter-spacing: 0;
  white-space: nowrap;
  line-height: 24px;
  position: absolute;
  top: 18px;
  left: 26px;
}

.ellipse-44-13 {
  background-color: var(--zest-2);
  height: 58px;
  width: 58px;
  border-radius: 29px;
  position: absolute;
  top: 47px;
  left: 208px;
}

.frame-1-16.frame-1-17 {
  position: absolute;
  top: 119px;
  left: 90px;
}

.frame-1-16.frame-1-18 {
  position: absolute;
  top: 122px;
  left: 93px;
}

.frame-1-12 {
  background-color: var(--white);
  width: 570px;
  border-radius: 10px;
  align-items: flex-start;
  display: flex;
  overflow: hidden;
  box-shadow: inset 1px 1px 12px #000000ba;
}

.chart-name-44 {
  letter-spacing: 0;
  min-height: 24px;
  min-width: 37px;
  white-space: nowrap;
  margin-top: 17px;
  margin-left: 26px;
  line-height: 24px;
  position: absolute;
}

.overlap-group2-33 {
  height: 308px;
  min-width: 570px;
  background-image: url("image-6-13@1x.e32523e2.png");
  background-position: 50%;
  background-size: cover;
  justify-content: flex-end;
  align-self: flex-end;
  align-items: flex-start;
  margin-left: 1px;
  padding: 71.5px 156px;
  display: flex;
}

.ellipse-44-9 {
  background-color: var(--zest);
  height: 14px;
  width: 14px;
  border-radius: 7px;
}

.frame-1-12.frame-1-13 {
  height: 308px;
  position: absolute;
  top: 131px;
  left: 90px;
}

.frame-1-12.frame-1-14 {
  height: 308px;
  position: absolute;
  top: 119px;
  left: 93px;
}

.frame-1-6 {
  background-color: var(--white);
  width: 570px;
  border-radius: 10px;
  align-items: flex-start;
  display: flex;
  overflow: hidden;
  box-shadow: inset 1px 1px 12px #000000ba;
}

.overlap-group2-23 {
  height: 308px;
  min-width: 570px;
  background-image: url("image-6-5@1x.ee91dca4.png");
  background-position: 50%;
  background-size: cover;
  align-items: flex-start;
  padding: 19.5px 88px;
  display: flex;
}

.chart-name-31 {
  letter-spacing: 0;
  min-height: 24px;
  min-width: 37px;
  white-space: nowrap;
  margin-top: 17px;
  margin-left: 26px;
  line-height: 24px;
  position: absolute;
}

.ellipse-44-3 {
  background-color: var(--zest);
  height: 14px;
  width: 14px;
  border-radius: 7px;
  align-self: center;
  margin-top: 114px;
  margin-left: 34px;
}

.frame-1-6.frame-1-7, .frame-1-6.frame-1-8 {
  height: 308px;
  position: absolute;
  top: 119px;
  left: 90px;
}

.overlap-group12-23 {
  height: 316px;
  width: 664px;
  margin-left: 19px;
  position: relative;
}

.rectangle-115-17 {
  background-color: var(--white);
  height: 316px;
  width: 663px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 1px;
}

.tasks-17 {
  letter-spacing: 0;
  position: absolute;
  top: 18px;
  left: 29px;
}

.release-transportation-documents-17 {
  letter-spacing: 0;
  position: absolute;
  top: 167px;
  left: 30px;
}

.review-catch-certificate-xdf1-d3-fa-17 {
  letter-spacing: 0;
  position: absolute;
  top: 75px;
  left: 30px;
}

.vector-33-17 {
  width: 664px;
  position: absolute;
  top: 200px;
  left: 0;
}

.vector-34-17 {
  width: 664px;
  position: absolute;
  top: 246px;
  left: 0;
}

.vector-35-17 {
  width: 664px;
  position: absolute;
  top: 108px;
  left: 0;
}

.vector-36-17 {
  width: 664px;
  position: absolute;
  top: 62px;
  left: 0;
}

.vector-38-17 {
  width: 664px;
  position: absolute;
  top: 154px;
  left: 0;
}

.review-lot-457258-dd-17 {
  letter-spacing: 0;
  position: absolute;
  top: 213px;
  left: 30px;
}

.review-lot-876258-lm-17 {
  letter-spacing: 0;
  position: absolute;
  top: 259px;
  left: 30px;
}

.sales-note-1255-17 {
  letter-spacing: 0;
  position: absolute;
  top: 121px;
  left: 30px;
}

.view-141 {
  -webkit-text-fill-color: transparent;
  letter-spacing: 0;
  background: linear-gradient(#1e3c72 0%, #2a5298 100%) text;
  position: absolute;
  top: 215px;
  left: 593px;
  -webkit-background-clip: text !important;
}

.view-142 {
  -webkit-text-fill-color: transparent;
  letter-spacing: 0;
  background: linear-gradient(#1e3c72 0%, #2a5298 100%) text;
  position: absolute;
  top: 261px;
  left: 593px;
  -webkit-background-clip: text !important;
}

.view-143 {
  -webkit-text-fill-color: transparent;
  letter-spacing: 0;
  background: linear-gradient(#1e3c72 0%, #2a5298 100%) text;
  position: absolute;
  top: 169px;
  left: 593px;
  -webkit-background-clip: text !important;
}

.view-144 {
  -webkit-text-fill-color: transparent;
  letter-spacing: 0;
  background: linear-gradient(#1e3c72 0%, #2a5298 100%) text;
  position: absolute;
  top: 123px;
  left: 593px;
  -webkit-background-clip: text !important;
}

.view-145 {
  -webkit-text-fill-color: transparent;
  letter-spacing: 0;
  background: linear-gradient(#1e3c72 0%, #2a5298 100%) text;
  position: absolute;
  top: 77px;
  left: 593px;
  -webkit-background-clip: text !important;
}

.overlap-group11-17 {
  height: 23px;
  width: 20px;
  position: absolute;
  top: 23px;
  left: 599px;
}

.rectangle-133-17 {
  background-color: var(--x5);
  height: 20px;
  width: 20px;
  border-radius: 3.23px;
  position: absolute;
  top: 1px;
  left: 0;
}

.text-31-1 {
  letter-spacing: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlap-group13-20 {
  background-color: var(--white);
  min-height: 316px;
  width: 576px;
  border-radius: 10px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.audit-logs-17 {
  letter-spacing: 0;
  min-height: 24px;
  margin-left: 33px;
  padding-top: 18px;
  padding-bottom: 20px;
}

.flex-row-61 {
  height: 20px;
  min-width: 519px;
  align-self: center;
  align-items: center;
  margin-top: 10px;
  margin-left: 9px;
  display: flex;
}

.scheduled-2-documents-to-be-issued-27 {
  letter-spacing: 0;
  min-height: 20px;
  width: 276px;
}

.view-140 {
  -webkit-text-fill-color: transparent;
  letter-spacing: 0;
  min-height: 17px;
  min-width: 26px;
  background: linear-gradient(#1e3c72 0%, #2a5298 100%) text;
  margin-bottom: 1px;
  margin-left: 217px;
  -webkit-background-clip: text !important;
}

.overlap-group1-60 {
  min-width: 574px;
  background-image: url("vector-39@1x.0e057ff6.svg");
  background-size: 100% 100%;
  align-items: flex-start;
  margin-top: 8px;
  margin-left: 1px;
  display: flex;
}

.flex-row-58 {
  height: 21px;
  min-width: 519px;
  align-self: center;
  align-items: flex-start;
  margin-top: 8px;
  margin-left: 9px;
  display: flex;
}

.scheduled-7 {
  letter-spacing: 0;
  min-height: 20px;
  width: 276px;
  align-self: flex-end;
}

.view-139 {
  -webkit-text-fill-color: transparent;
  letter-spacing: 0;
  min-height: 17px;
  background: linear-gradient(#1e3c72 0%, #2a5298 100%) text;
  margin-left: 217px;
  -webkit-background-clip: text !important;
}

.flex-row-62 {
  height: 22px;
  min-width: 519px;
  align-self: center;
  align-items: flex-start;
  margin-top: 7px;
  margin-left: 9px;
  display: flex;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: var(--gray-nurse);
  margin-top: 8px;
}

.overlap-group11-17 {
  height: 23px;
  width: 20px;
  position: absolute;
  top: 23px;
  left: 599px;
}

.rectangle-133-17 {
  background-color: var(--x5);
  height: 20px;
  width: 20px;
  border-radius: 3.23px;
  position: absolute;
  top: 1px;
  left: 0;
}

.text-31-1 {
  letter-spacing: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.date-66 {
  letter-spacing: 0;
  min-height: 17px;
  margin-top: 1px;
  margin-left: 32px;
}

.card-header, .card-header-1, .card-header-2, .card-header-3, .card-header-4, .card-header-5, .card-header-6, .card-header-7, .card-header-8, .card-header-9, .card-header-10, .card-header-11, .card-header-12, .card-header-13, .card-header-14, .card-header-15, .card-header-16, .card-header-17, .card-header-18, .card-header-19 {
  background-color: var(--white);
  height: 40px;
  min-width: 652px;
  border-radius: 6px 6px 0 0;
  align-items: flex-start;
  padding: 7px 20px;
  display: flex;
}

.chart-name-1, .chart-name-2, .chart-name-3, .chart-name-4, .chart-name-5, .chart-name-6, .chart-name-7, .chart-name-8, .chart-name-9, .chart-name-10, .chart-name-11, .chart-name-12, .chart-name-13, .chart-name-14, .chart-name-15, .chart-name-16, .chart-name-17, .chart-name-18, .chart-name-19, .chart-name-20 {
  letter-spacing: 0;
  min-height: 24px;
  min-width: 1px;
  white-space: nowrap;
  line-height: 24px;
}

.icon-container, .icon-container-1, .icon-container-2, .icon-container-3, .icon-container-4, .icon-container-5, .icon-container-6, .icon-container-7, .icon-container-8, .icon-container-9, .icon-container-10, .icon-container-11, .icon-container-12, .icon-container-13, .icon-container-14, .icon-container-15, .icon-container-16, .icon-container-17, .icon-container-18, .icon-container-19 {
  height: 24px;
  width: 16px;
  align-self: center;
  margin-left: 3px;
}

.flag, .flag-2, .flag-4, .flag-6, .flag-8, .flag-10, .flag-12, .flag-14, .flag-16, .flag-18, .flag-20, .flag-22, .flag-24, .flag-26, .flag-28, .flag-30, .flag-32, .flag-34, .flag-36, .flag-38 {
  height: 16px;
  width: 16px;
  align-items: flex-end;
  padding: 2px 1px;
  display: flex;
}

.flag-1, .flag-3, .flag-5, .flag-7, .flag-9, .flag-11, .flag-13, .flag-15, .flag-17, .flag-19, .flag-21, .flag-23, .flag-25, .flag-27, .flag-29, .flag-31, .flag-33, .flag-35, .flag-37, .flag-39 {
  height: 11px;
  width: 14px;
}

.first-addon, .first-addon-1, .first-addon-2, .first-addon-3, .first-addon-4, .first-addon-5, .first-addon-6, .first-addon-7, .first-addon-8, .first-addon-9, .first-addon-10, .first-addon-11, .first-addon-12, .first-addon-13, .first-addon-14, .first-addon-15, .first-addon-16, .first-addon-17, .first-addon-18, .first-addon-19 {
  height: 16px;
  min-width: 16px;
  align-self: center;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.pills-content {
  height: 20px;
  min-width: 57px;
  align-items: center;
  padding: 0 8px;
  display: flex;
  position: relative;
}

.us {
  letter-spacing: 0;
  min-height: 20px;
  white-space: nowrap;
  margin-top: -1px;
  margin-left: 8px;
  line-height: 20px;
}

.pills-content.pills-content-1, .pills-content.pills-content-4, .pills-content.pills-content-7, .pills-content.pills-content-10, .pills-content.pills-content-13, .pills-content.pills-content-16, .pills-content.pills-content-19, .pills-content.pills-content-22, .pills-content.pills-content-25, .pills-content.pills-content-28, .pills-content.pills-content-31, .pills-content.pills-content-34, .pills-content.pills-content-37, .pills-content.pills-content-40, .pills-content.pills-content-43, .pills-content.pills-content-46, .pills-content.pills-content-49, .pills-content.pills-content-52, .pills-content.pills-content-55, .pills-content.pills-content-58 {
  min-width: 59px;
}

.pills-content.pills-content-2, .pills-content.pills-content-5, .pills-content.pills-content-8, .pills-content.pills-content-11, .pills-content.pills-content-14, .pills-content.pills-content-17, .pills-content.pills-content-20, .pills-content.pills-content-23, .pills-content.pills-content-26, .pills-content.pills-content-29, .pills-content.pills-content-32, .pills-content.pills-content-35, .pills-content.pills-content-38, .pills-content.pills-content-41, .pills-content.pills-content-44, .pills-content.pills-content-47, .pills-content.pills-content-50, .pills-content.pills-content-53, .pills-content.pills-content-56, .pills-content.pills-content-59 {
  min-width: 89px;
}

.pills-item, .pills-item-1, .pills-item-2, .pills-item-3, .pills-item-4, .pills-item-5, .pills-item-6, .pills-item-7, .pills-item-8, .pills-item-9, .pills-item-10, .pills-item-11, .pills-item-12, .pills-item-13, .pills-item-14, .pills-item-15, .pills-item-16, .pills-item-17, .pills-item-18, .pills-item-19 {
  background-color: var(--azure-radiance-2);
  height: 28px;
  width: 57px;
  border-radius: 6px 0 0 6px;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.pills-item-20, .pills-item-21, .pills-item-22, .pills-item-23, .pills-item-24, .pills-item-25, .pills-item-26, .pills-item-27, .pills-item-28, .pills-item-29, .pills-item-30, .pills-item-31, .pills-item-32, .pills-item-33, .pills-item-34, .pills-item-35, .pills-item-36, .pills-item-37, .pills-item-38, .pills-item-39 {
  background-color: var(--white);
  height: 28px;
  width: 59px;
  align-items: center;
  display: flex;
  position: relative;
  box-shadow: inset 0 1px #c4c7cf, inset 0 -1px #c4c7cf, inset -1px 0 #c4c7cf;
}

.pills-item-40, .pills-item-41, .pills-item-42, .pills-item-43, .pills-item-44, .pills-item-45, .pills-item-46, .pills-item-47, .pills-item-48, .pills-item-49, .pills-item-50, .pills-item-51, .pills-item-52, .pills-item-53, .pills-item-54, .pills-item-55, .pills-item-56, .pills-item-57, .pills-item-58, .pills-item-59 {
  background-color: var(--white);
  height: 28px;
  width: 89px;
  border-radius: 0 6px 6px 0;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 1px #c4c7cf, inset 0 -1px #c4c7cf, inset -1px 0 #c4c7cf;
}

.pills, .pills-1, .pills-2, .pills-3, .pills-4, .pills-5, .pills-6, .pills-7, .pills-8, .pills-9, .pills-10, .pills-11, .pills-12, .pills-13, .pills-14, .pills-15, .pills-16, .pills-17, .pills-18, .pills-19 {
  height: 28px;
  min-width: 205px;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.tab-line-item, .tab-line-item-1, .tab-line-item-2, .tab-line-item-3, .tab-line-item-4, .tab-line-item-5, .tab-line-item-6, .tab-line-item-7, .tab-line-item-8, .tab-line-item-9, .tab-line-item-10, .tab-line-item-11, .tab-line-item-12, .tab-line-item-13, .tab-line-item-14, .tab-line-item-15, .tab-line-item-16, .tab-line-item-17, .tab-line-item-18, .tab-line-item-19 {
  height: 22px;
  width: 49px;
}

.tab-line, .tab-line-1, .tab-line-2, .tab-line-3, .tab-line-4, .tab-line-5, .tab-line-6, .tab-line-7, .tab-line-8, .tab-line-9, .tab-line-10, .tab-line-11, .tab-line-12, .tab-line-13, .tab-line-14, .tab-line-15, .tab-line-16, .tab-line-17, .tab-line-18, .tab-line-19 {
  height: 22px;
  min-width: 49px;
  align-items: flex-start;
  margin-top: 3px;
  margin-left: 353px;
  display: flex;
  position: relative;
}

.card, .card-1, .card-2, .card-3, .card-4, .card-5, .card-6, .card-7, .card-8, .card-9, .card-10, .card-11, .card-12, .card-13, .card-14, .card-15, .card-16, .card-17, .card-18, .card-19 {
  background-color: var(--white);
  min-height: 308px;
  width: 652px;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 1px 2px #191b231f, 0 0 1px #191b2329;
}

.card-body, .card-body-1, .card-body-2, .card-body-3, .card-body-4, .card-body-5, .card-body-6, .card-body-7, .card-body-8, .card-body-9, .card-body-10, .card-body-11, .card-body-12, .card-body-13, .card-body-14, .card-body-15, .card-body-16, .card-body-17, .card-body-18, .card-body-19 {
  height: 268px;
  min-width: 652px;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
  display: flex;
  position: relative;
}

.content-40 {
  height: 22px;
  min-width: 22px;
  align-items: center;
  display: flex;
}

.text-21 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 22px;
  white-space: nowrap;
  line-height: 20px;
}

.content-40.content-20, .content-40.content-21, .content-40.content-22, .content-40.content-23, .content-40.content-24, .content-40.content-25, .content-40.content-26, .content-40.content-27, .content-40.content-28, .content-40.content-29, .content-40.content-30, .content-40.content-31, .content-40.content-32, .content-40.content-33, .content-40.content-34, .content-40.content-35, .content-40.content-36, .content-40.content-37, .content-40.content-38, .content-40.content-39 {
  height: 22px;
  min-width: 49px;
  align-items: center;
  display: flex;
  position: absolute;
  top: 22px;
  left: 577px;
}

.content-40.content-20 .text-21, .content-40.content-21 .text-21, .content-40.content-22 .text-21, .content-40.content-23 .text-21, .content-40.content-24 .text-21, .content-40.content-25 .text-21, .content-40.content-26 .text-21, .content-40.content-27 .text-21, .content-40.content-28 .text-21, .content-40.content-29 .text-21, .content-40.content-30 .text-21, .content-40.content-31 .text-21, .content-40.content-32 .text-21, .content-40.content-33 .text-21, .content-40.content-34 .text-21, .content-40.content-35 .text-21, .content-40.content-36 .text-21, .content-40.content-37 .text-21, .content-40.content-38 .text-21, .content-40.content-39 .text-21 {
  min-width: 49px;
}

.tab-line-item-20, .tab-line-item-21, .tab-line-item-22, .tab-line-item-23, .tab-line-item-24, .tab-line-item-25, .tab-line-item-26, .tab-line-item-27, .tab-line-item-28, .tab-line-item-29, .tab-line-item-30, .tab-line-item-31, .tab-line-item-32, .tab-line-item-33, .tab-line-item-34, .tab-line-item-35, .tab-line-item-36, .tab-line-item-37, .tab-line-item-38, .tab-line-item-39 {
  min-height: 28px;
  width: 22px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 22px;
  left: 463px;
}

.border-bottom-2px, .border-bottom-2px-1, .border-bottom-2px-2, .border-bottom-2px-3, .border-bottom-2px-4, .border-bottom-2px-5, .border-bottom-2px-6, .border-bottom-2px-7, .border-bottom-2px-8, .border-bottom-2px-9, .border-bottom-2px-10, .border-bottom-2px-11, .border-bottom-2px-12, .border-bottom-2px-13, .border-bottom-2px-14, .border-bottom-2px-15, .border-bottom-2px-16, .border-bottom-2px-17, .border-bottom-2px-18, .border-bottom-2px-19 {
  background-color: var(--azure-radiance);
  height: 2px;
  width: 22px;
  margin-top: 4px;
}

.tab-line-item-40 {
  height: 22px;
  min-width: 22px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 22px;
  left: 501px;
}

.tab-line-item-40.tab-line-item-41, .tab-line-item-40.tab-line-item-43, .tab-line-item-40.tab-line-item-45, .tab-line-item-40.tab-line-item-47, .tab-line-item-40.tab-line-item-49, .tab-line-item-40.tab-line-item-51, .tab-line-item-40.tab-line-item-53, .tab-line-item-40.tab-line-item-55, .tab-line-item-40.tab-line-item-57, .tab-line-item-40.tab-line-item-59, .tab-line-item-40.tab-line-item-61, .tab-line-item-40.tab-line-item-63, .tab-line-item-40.tab-line-item-65, .tab-line-item-40.tab-line-item-67, .tab-line-item-40.tab-line-item-69, .tab-line-item-40.tab-line-item-71, .tab-line-item-40.tab-line-item-73, .tab-line-item-40.tab-line-item-75, .tab-line-item-40.tab-line-item-77, .tab-line-item-40.tab-line-item-79 {
  left: 539px;
}

.overlap-group1-61 {
  height: 308px;
  width: 652px;
  border-radius: 8px;
  margin-left: 37px;
  position: relative;
}

.chart-name-56 {
  letter-spacing: 0;
  white-space: nowrap;
  line-height: 24px;
  position: absolute;
  top: 18px;
  left: 24px;
}

.chart-9-17 {
  min-height: 195px;
  width: 628px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 95px;
  left: 22px;
}

.overlap-group-161 {
  height: 183px;
  width: 617px;
  margin-top: -1px;
  margin-left: -1.5px;
  position: relative;
}

.vector-8-17 {
  height: 69px;
  width: 615px;
  position: absolute;
  top: 38px;
  left: 2px;
}

.vector-28-17 {
  height: 57px;
  width: 611px;
  position: absolute;
  top: 73px;
  left: 0;
}

.vector-9-17 {
  height: 69px;
  width: 610px;
  position: absolute;
  top: 13px;
  left: 4px;
}

.vector-21-17 {
  height: 182px;
  width: 1px;
  position: absolute;
  top: 0;
  left: 54px;
}

.vector-22-17 {
  height: 182px;
  width: 1px;
  position: absolute;
  top: 0;
  left: 137px;
}

.vector-23-17 {
  height: 182px;
  width: 1px;
  position: absolute;
  top: 0;
  left: 220px;
}

.vector-24-17 {
  height: 182px;
  width: 1px;
  position: absolute;
  top: 1px;
  left: 303px;
}

.vector-25-17 {
  height: 182px;
  width: 1px;
  position: absolute;
  top: 1px;
  left: 386px;
}

.vector-26-17 {
  height: 182px;
  width: 1px;
  position: absolute;
  top: 1px;
  left: 469px;
}

.vector-27-17 {
  height: 182px;
  width: 1px;
  position: absolute;
  top: 1px;
  left: 552px;
}

.ellipse-42-17 {
  height: 18px;
  width: 18px;
  background: linear-gradient(#ff9364 0%, #f25f33 100%);
  border-radius: 9px;
  position: absolute;
  top: 35px;
  left: 437px;
  box-shadow: 0 5px 25px #0000001a;
}

.flex-row-60 {
  height: 6px;
  min-width: 522px;
  align-self: center;
  align-items: flex-start;
  margin-top: 7px;
  margin-right: 21.85px;
  display: flex;
}

.name-47 {
  letter-spacing: 0;
  min-height: 6px;
  opacity: .5;
  text-align: center;
  width: 23px;
}

.flex-row-item-17 {
  letter-spacing: 0;
  min-height: 6px;
  opacity: .5;
  text-align: center;
  width: 24px;
  margin-left: 59px;
}

.place-37 {
  letter-spacing: 0;
  min-height: 6px;
  opacity: .5;
  text-align: center;
  width: 25px;
  margin-left: 59px;
}

.apr-17 {
  letter-spacing: 0;
  min-height: 6px;
  text-align: center;
  width: 23px;
  margin-left: 59px;
}

.name-48 {
  letter-spacing: 0;
  min-height: 6px;
  opacity: .5;
  text-align: center;
  width: 26px;
  margin-left: 59px;
}

.jul-17 {
  letter-spacing: 0;
  min-height: 6px;
  opacity: .5;
  text-align: center;
  width: 20px;
  margin-left: 59px;
}

.flag_of_the_-netherlands-1-17 {
  height: 11px;
  object-fit: cover;
  width: 14px;
  position: absolute;
  top: 69px;
  left: 34px;
}

.group-381 {
  height: 26px;
  min-width: 93px;
  z-index: calc(var(--modal-zindex)  + 1);
  align-items: center;
  display: flex;
  position: absolute;
  top: 245px;
  left: 549px;
}

.fishing-28 {
  letter-spacing: 0;
  min-width: 64px;
}

.group-73 {
  height: 21px;
  width: 18px;
  margin-bottom: 1.04px;
  margin-left: 8px;
}

.group-381.group-381-1 {
  top: 242px;
  left: 558px;
}

.group-429 {
  background-color: var(--white);
  height: 32px;
  min-width: 32px;
  cursor: pointer;
  z-index: calc(var(--modal-zindex)  + 1);
  border-radius: 16px;
  align-items: flex-start;
  padding: 1px;
  display: flex;
  position: absolute;
  top: 197px;
  left: 897px;
  box-shadow: 0 0 4px #00000040;
}

.cancel-6 {
  height: 30px;
  width: 30px;
}

.group-429.group-429-1, .group-429.group-429-3 {
  top: 89px;
  left: 1093px;
}

.group-429.group-429-2 {
  top: 194px;
  left: 897px;
}

.group-429.group-429-4, .group-429.group-429-5 {
  top: 106px;
  left: 1107px;
}

.group-429.group-429-6, .group-429.group-429-7 {
  top: 0;
  left: 759px;
}

.group-429.group-429-8, .group-429.group-429-9, .group-429.group-429-10, .group-429.group-429-11 {
  top: 0;
  left: 378px;
}

.rectangle-135-2 {
  background-color: var(--white);
  height: 560px;
  width: 400px;
  z-index: calc(var(--modal-zindex)  + 1);
  border-radius: 10px;
  position: absolute;
  top: 209px;
  left: 519px;
  box-shadow: 0 7px 27px #00000040;
}

.course-1 {
  letter-spacing: 0;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 339px;
  left: 565px;
}

.date-14 {
  letter-spacing: 0;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 420px;
  left: 565px;
}

.time-1 {
  letter-spacing: 0;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 506px;
  left: 565px;
}

.rectangle-136-1 {
  background-color: var(--white);
  height: 34px;
  width: 325px;
  z-index: calc(var(--modal-zindex)  + 1);
  border-radius: 5px;
  position: absolute;
  top: 369px;
  left: 558px;
}

.rectangle-137-7 {
  background-color: var(--white);
  height: 34px;
  width: 325px;
  z-index: calc(var(--modal-zindex)  + 1);
  border-radius: 5px;
  position: absolute;
  top: 449px;
  left: 558px;
}

.rectangle-138-5 {
  background-color: var(--white);
  height: 34px;
  width: 325px;
  z-index: calc(var(--modal-zindex)  + 1);
  border-radius: 5px;
  position: absolute;
  top: 534px;
  left: 558px;
}

.cancel-button-1 {
  background-color: var(--guardsman-red-3);
  height: 38px;
  width: 100px;
  cursor: pointer;
  z-index: calc(var(--modal-zindex)  + 1);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 678px;
  left: 608px;
}

.save-button-1 {
  background-color: var(--unmellow-yellow);
  height: 38px;
  width: 100px;
  cursor: pointer;
  z-index: calc(var(--modal-zindex)  + 1);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 678px;
  left: 729px;
}

.route-slooq {
  color: var(--text);
  z-index: calc(var(--modal-zindex)  + 1);
  font-weight: 300;
  position: absolute;
  top: 379px;
  left: 577px;
}

.date-15 {
  color: var(--text);
  z-index: calc(var(--modal-zindex)  + 1);
  font-weight: 300;
  position: absolute;
  top: 459px;
  left: 577px;
}

.text-5-1 {
  color: var(--text);
  z-index: calc(var(--modal-zindex)  + 1);
  font-weight: 300;
  position: absolute;
  top: 544px;
  left: 577px;
}

.group-4-3-1 {
  min-height: 61px;
  width: 327px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.name-market-operator {
  letter-spacing: 0;
  min-height: 20px;
  margin-left: 9px;
}

.rectangle-1-3 {
  background-color: var(--white);
  height: 34px;
  width: 325px;
  border-radius: 5px;
  margin-top: 7px;
}

.group-4-3-1.group-432, .group-4-3-1.group-432-1 {
  min-height: 61px;
  width: 327px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 112px;
  left: 38px;
}

.group-4-3-1.group-431-1, .group-4-3-1.group-431-2 {
  min-height: 61px;
  width: 327px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 518px;
  left: 38px;
}

.group-4-3-1.group-431-3, .group-4-3-1.group-431-4 {
  min-height: 61px;
  width: 327px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 505px;
  left: 38px;
}

.group-4-3-1.group-432-2, .group-4-3-1.group-432-3 {
  min-height: 61px;
  width: 327px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 119px;
  left: 38px;
}

.group-4-3-1.group-434, .group-4-3-1.group-434-1 {
  min-height: 61px;
  width: 327px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 215px;
  left: 38px;
}

.group-4-3-1.group-431-5, .group-4-3-1.group-431-6 {
  min-height: 61px;
  width: 327px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 102px;
  left: 38px;
}

.group-4-3-1.group-432-4, .group-4-3-1.group-432-5 {
  min-height: 61px;
  width: 327px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 508px;
  left: 38px;
}

.group-4-3-1.group-434-2, .group-4-3-1.group-434-3 {
  min-height: 61px;
  width: 327px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 346px;
  left: 38px;
}

.group-436, .group-436-1 {
  height: 62px;
  min-width: 333px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 183px;
  left: 38px;
}

.flex-col-93, .flex-col-96 {
  min-height: 62px;
  width: 90px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.lot-size, .lot-size-1 {
  letter-spacing: 0;
  min-height: 20px;
  margin-left: 9px;
}

.rectangle-1-13, .rectangle-1-14 {
  background-color: var(--white);
  height: 34px;
  width: 90px;
  border-radius: 5px;
  margin-top: 8px;
}

.flex-col-94, .flex-col-97 {
  min-height: 62px;
  width: 90px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 26px;
  display: flex;
}

.lot-grade-6, .lot-grade-7 {
  letter-spacing: 0;
  min-height: 20px;
  margin-left: 3px;
}

.flex-col-95, .flex-col-98 {
  min-height: 62px;
  width: 90px;
  flex-direction: column;
  align-items: center;
  margin-left: 31px;
  display: flex;
}

.lot-weight-6, .lot-weight-7 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 71px;
  margin-right: 9px;
}

.group-433 {
  height: 61px;
  min-width: 331px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.flex-col-10 {
  min-height: 61px;
  width: 136px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.pnl {
  letter-spacing: 0;
  min-height: 20px;
  margin-left: 9px;
}

.rectangle-14 {
  background-color: var(--white);
  height: 34px;
  width: 136px;
  border-radius: 5px;
  margin-top: 7px;
}

.flex-col-11 {
  min-height: 61px;
  width: 136px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 55px;
  display: flex;
}

.boat-name {
  letter-spacing: 0;
  min-height: 20px;
  margin-left: 7px;
}

.group-433.group-433-4, .group-433.group-433-5 {
  top: 427px;
  left: 38px;
}

.group-435 {
  height: 61px;
  min-width: 331px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.flex-col-2 {
  min-height: 61px;
  width: 179px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.catch-area {
  letter-spacing: 0;
  min-height: 20px;
  margin-left: 9px;
}

.rectangle-138-1 {
  background-color: var(--white);
  height: 34px;
  width: 179px;
  border-radius: 5px;
  margin-top: 7px;
}

.flex-col-3 {
  min-height: 61px;
  width: 126px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 22px;
  display: flex;
}

.catch-method {
  letter-spacing: 0;
  min-height: 20px;
  margin-left: 5px;
}

.rectangle-141 {
  background-color: var(--white);
  height: 34px;
  width: 126px;
  border-radius: 5px;
  margin-top: 7px;
}

.group-435.group-435-2, .group-435.group-435-3 {
  top: 265px;
  left: 38px;
}

.group-428 {
  height: 38px;
  min-width: 225px;
  z-index: calc(var(--modal-zindex)  + 1);
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 875px;
  left: 610px;
}

.overlap-group29 {
  background-color: var(--guardsman-red-3);
  height: 38px;
  min-width: 100px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  padding: 5px 20px;
  display: flex;
}

.cancel-18, .save-6 {
  letter-spacing: 0;
}

.overlap-group30 {
  background-color: var(--unmellow-yellow);
  height: 38px;
  min-width: 100px;
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  margin-left: 21px;
  padding: 7px 31px;
  display: flex;
}

.group-428.group-428-1 {
  top: 826px;
}

.group-428.group-428-2 {
  top: 618px;
  left: 279px;
}

.group-428.group-428-3, .group-428.group-428-4 {
  top: 618px;
  left: 89px;
}

.group-506 {
  height: 685px;
  min-width: 432px;
  z-index: calc(var(--modal-zindex)  + 1);
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 73px;
  left: 50%;
  transform: translateX(-50%);
}

.overlap-group11-19 {
  height: 685px;
  width: 410px;
  position: relative;
}

.rectangle-135-11 {
  background-color: var(--white);
  height: 670px;
  width: 400px;
  border-radius: 10px;
  position: absolute;
  top: 15px;
  left: 0;
  box-shadow: 0 7px 27px #00000040;
}

.lot-data-21 {
  letter-spacing: 0;
  position: absolute;
  top: 48px;
  left: 39px;
}

.group-412-21 {
  height: 18px;
  width: 27px;
  position: absolute;
  top: 49px;
  left: 123px;
}

.name-53 {
  color: var(--text);
  font-weight: 300;
  position: absolute;
  top: 139px;
  left: 56px;
}

.small {
  letter-spacing: 0;
  position: absolute;
  top: 221px;
  left: 56px;
}

.span1-29 {
  color: var(--text);
  font-weight: 300;
}

.grade-a-7 {
  color: var(--text);
  font-weight: 300;
  position: absolute;
  top: 221px;
  left: 170px;
}

.x13kg-9 {
  color: var(--text);
  font-weight: 300;
  position: absolute;
  top: 221px;
  left: 307px;
}

.st-georges-channel-3 {
  color: var(--text);
  font-weight: 300;
  position: absolute;
  top: 302px;
  left: 56px;
}

.by-net-2 {
  color: var(--text);
  font-weight: 300;
  position: absolute;
  top: 302px;
  left: 256px;
}

.phone-4 {
  color: var(--text);
  font-weight: 300;
  position: absolute;
  top: 465px;
  left: 56px;
}

.sealess-rock-4 {
  color: var(--text);
  font-weight: 300;
  position: absolute;
  top: 465px;
  left: 244px;
}

.name-54 {
  color: var(--text);
  font-weight: 300;
  position: absolute;
  top: 545px;
  left: 56px;
}

.pollack-9 {
  color: var(--text);
  font-weight: 300;
  position: absolute;
  top: 384px;
  left: 56px;
}

.group-438 {
  height: 62px;
  min-width: 328px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.flex-col-22 {
  min-height: 62px;
  width: 137px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.lot-grade {
  letter-spacing: 0;
  min-height: 20px;
  margin-left: 3px;
}

.rectangle-139 {
  background-color: var(--white);
  height: 34px;
  width: 137px;
  border-radius: 5px;
  margin-top: 8px;
}

.flex-col-23 {
  min-height: 62px;
  width: 153px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 34px;
  display: flex;
}

.lot-weight {
  letter-spacing: 0;
  min-height: 20px;
  margin-left: 9px;
}

.rectangle-137-1 {
  background-color: var(--white);
  height: 34px;
  width: 153px;
  border-radius: 5px;
  margin-top: 8px;
}

.group-438.group-438-4, .group-438.group-438-5 {
  top: 311px;
  left: 38px;
}

.group-437 {
  height: 62px;
  min-width: 328px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.flex-col-34 {
  min-height: 62px;
  width: 137px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.address {
  letter-spacing: 0;
  min-height: 20px;
  margin-left: 9px;
}

.rectangle-143 {
  background-color: var(--white);
  height: 34px;
  width: 137px;
  border-radius: 5px;
  margin-top: 8px;
}

.flex-col-35 {
  min-height: 62px;
  width: 149px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 38px;
  display: flex;
}

.total-weight {
  letter-spacing: 0;
  min-height: 20px;
  margin-left: 5px;
}

.rectangle-140 {
  background-color: var(--white);
  height: 34px;
  width: 149px;
  border-radius: 5px;
  margin-top: 8px;
}

.group-437.group-437-6, .group-437.group-437-7 {
  top: 408px;
  left: 38px;
}

.number-48 {
  color: var(--text);
  font-weight: 300;
  position: absolute;
  top: 610px;
  left: 567px;
}

.rectangle-134-8 {
  -webkit-backdrop-filter: blur(4px) brightness();
  backdrop-filter: blur(4px) brightness();
  background-color: var(--concord);
  height: 973px;
  width: 1445px;
  position: absolute;
  top: 0;
  left: 0;
}

.overlap-group12-14 {
  height: 685px;
  width: 410px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 73px;
  left: 510px;
}

.rectangle-135-8 {
  background-color: var(--white);
  height: 670px;
  width: 400px;
  border-radius: 10px;
  position: absolute;
  top: 15px;
  left: 0;
  box-shadow: 0 7px 27px #00000040;
}

.sales-note-14 {
  letter-spacing: 0;
  position: absolute;
  top: 48px;
  left: 39px;
}

.name-38 {
  color: var(--x66);
  font-weight: 300;
  position: absolute;
  top: 542px;
  left: 55px;
}

.x13kg-5 {
  letter-spacing: 0;
  position: absolute;
  top: 349px;
  left: 267px;
}

.span1-24 {
  color: var(--x66);
  font-weight: 300;
}

.grade-a-4 {
  color: var(--x66);
  font-weight: 300;
  position: absolute;
  top: 349px;
  left: 55px;
}

.pollack-6 {
  color: var(--x66);
  font-weight: 300;
  position: absolute;
  top: 253px;
  left: 55px;
}

.devante-ltd-8 {
  color: var(--text);
  font-weight: 300;
  position: absolute;
  top: 156px;
  left: 55px;
}

.x13kg-6 {
  color: var(--text);
  font-weight: 300;
  position: absolute;
  top: 446px;
  left: 267px;
}

.number-49 {
  color: var(--text);
  font-weight: 300;
  position: absolute;
  top: 446px;
  left: 55px;
}

.group-4-2 {
  min-height: 61px;
  width: 327px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.place-time-of-loading-xx {
  letter-spacing: 0;
  min-height: 20px;
  margin-left: 9px;
}

.rectangle-1 {
  background-color: var(--white);
  height: 34px;
  width: 325px;
  border-radius: 5px;
  margin-top: 7px;
}

.group-4-2.group-444, .group-4-2.group-444-1 {
  min-height: 61px;
  width: 327px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 214px;
  left: 412px;
}

.group-4-2.group-445, .group-4-2.group-445-1 {
  min-height: 61px;
  width: 327px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 316px;
  left: 413px;
}

.group-4-2.group-446, .group-4-2.group-446-1 {
  min-height: 61px;
  width: 327px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 418px;
  left: 413px;
}

.group-4-2.group-447, .group-4-2.group-447-1 {
  min-height: 61px;
  width: 327px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 437px;
  left: 38px;
}

.group-4-2.group-448, .group-4-2.group-448-1 {
  min-height: 61px;
  width: 327px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 518px;
  left: 413px;
}

.group-408-10 {
  height: 20px;
  width: 32px;
  position: absolute;
  top: 184px;
  left: 490px;
}

.transport-doc-10 {
  letter-spacing: 0;
  position: absolute;
  top: 180px;
  left: 355px;
}

.devante-ltd-4 {
  color: var(--ocean-blue-pearl);
  font-weight: 300;
  position: absolute;
  top: 279px;
  left: 368px;
}

.rectangle-134-6 {
  -webkit-backdrop-filter: blur(4px) brightness();
  backdrop-filter: blur(4px) brightness();
  background-color: var(--concord);
  height: 952px;
  width: 1440px;
  position: absolute;
  top: 0;
  left: 0;
}

.overlap-group12-10 {
  height: 685px;
  width: 784px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 49px;
  left: 316px;
}

.rectangle-135-6 {
  background-color: var(--white);
  height: 670px;
  width: 779px;
  border-radius: 10px;
  position: absolute;
  top: 15px;
  left: 0;
  box-shadow: 0 7px 27px #00000040;
}

.overlap-group-94 {
  height: 61px;
  width: 327px;
  position: absolute;
  top: 356px;
  left: 38px;
}

.name-27 {
  color: var(--x66);
  font-weight: 300;
  position: absolute;
  top: 38px;
  left: 17px;
}

.name-28 {
  color: var(--text);
  font-weight: 300;
  position: absolute;
  top: 555px;
  left: 55px;
}

.wales-porthcawl-10112022-050306 {
  color: var(--text);
  font-weight: 300;
  position: absolute;
  top: 474px;
  left: 55px;
}

.place-22 {
  color: var(--text);
  font-weight: 300;
  position: absolute;
  top: 251px;
  left: 430px;
}

.france-tourlaville-16112022 {
  color: var(--text);
  font-weight: 300;
  position: absolute;
  top: 555px;
  left: 430px;
}

.place-23 {
  color: var(--text);
  z-index: 14;
  font-weight: 300;
  position: absolute;
  top: 456px;
  left: 430px;
}

.bollachius-pollachius-2 {
  color: var(--text);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  font-style: italic;
  font-weight: 300;
  position: absolute;
  top: 353px;
  left: 430px;
}

.overlap-group1-34 {
  height: 61px;
  width: 327px;
  position: absolute;
  top: 112px;
  left: 413px;
}

.x26-dgklg78-2 {
  letter-spacing: 0;
  position: absolute;
  top: 37px;
  left: 17px;
}

.span1-23 {
  color: var(--text);
  font-weight: 300;
}

.overlap-group2-31 {
  height: 61px;
  width: 327px;
  position: absolute;
  top: 275px;
  left: 38px;
}

.pollack-4 {
  color: var(--x66);
  font-weight: 300;
  position: absolute;
  top: 38px;
  left: 17px;
}

.overlap-group3-11 {
  height: 62px;
  width: 328px;
  position: absolute;
  top: 193px;
  left: 38px;
}

.x13kg-3 {
  color: var(--x66);
  font-weight: 300;
  position: absolute;
  top: 38px;
  left: 229px;
}

.number-34 {
  color: var(--x66);
  font-weight: 300;
  position: absolute;
  top: 38px;
  left: 17px;
}

.group-481 {
  height: 20px;
  width: 32px;
  position: absolute;
  top: 48px;
  left: 174px;
}

.transport-doc-11 {
  letter-spacing: 0;
  position: absolute;
  top: 48px;
  left: 39px;
}

.devante-ltd-5 {
  color: var(--x66);
  font-weight: 300;
  position: absolute;
  top: 149px;
  left: 57px;
}

.group-457 {
  min-height: 84px;
  width: 325px;
  z-index: calc(var(--modal-zindex)  + 1);
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 626px;
  left: 764px;
}

.master-signature {
  letter-spacing: 0;
  min-height: 20px;
  margin-left: 9px;
}

.rectangle-145-3 {
  background-color: var(--white);
  height: 57px;
  width: 325px;
  border-radius: 5px;
  margin-top: 7px;
}

.group-457.group-451 {
  min-height: 61px;
  top: 294px;
}

.group-457.group-451 .rectangle-145-3, .group-457.group-454 .rectangle-145-3, .group-457.group-450 .rectangle-145-3, .group-457.group-455 .rectangle-145-3, .group-457.group-456 .rectangle-145-3, .group-457.group-458 .rectangle-145-3 {
  height: 34px;
}

.group-457.group-454 {
  min-height: 61px;
  top: 211px;
}

.group-457.group-450 {
  min-height: 61px;
  top: 377px;
}

.group-457.group-455 {
  min-height: 61px;
  top: 460px;
}

.group-457.group-456 {
  min-height: 61px;
  top: 543px;
}

.group-457.group-458 {
  min-height: 61px;
  top: 729px;
}

.rectangle-135-4 {
  background-color: var(--white);
  height: 775px;
  width: 809px;
  z-index: calc(var(--modal-zindex)  + 1);
  border-radius: 10px;
  position: absolute;
  top: 119px;
  left: 316px;
  box-shadow: 0 7px 27px #00000040;
}

.catch-certificate-2 {
  letter-spacing: 0;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 145px;
  left: 355px;
}

.group-410-7 {
  height: 26px;
  width: 21px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 144px;
  left: 525px;
}

.overlap-group14-4 {
  height: 61px;
  width: 331px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 643px;
  left: 355px;
}

.phone-2 {
  color: var(--x66);
  z-index: calc(var(--modal-zindex)  + 1);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 17px;
}

.sealess-rock-2 {
  color: var(--x66);
  z-index: calc(var(--modal-zindex)  + 1);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 206px;
}

.overlap-group15-2 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 471px;
  left: 355px;
}

.name-18 {
  color: var(--x66);
  z-index: calc(var(--modal-zindex)  + 1);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 17px;
}

.overlap-group16-2 {
  height: 62px;
  width: 328px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 297px;
  left: 355px;
}

.x13kg {
  letter-spacing: 0;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 37px;
  left: 229px;
}

.span1-20 {
  color: var(--x66);
  z-index: calc(var(--modal-zindex)  + 1);
  font-weight: 300;
}

.grade-a-2 {
  color: var(--x66);
  z-index: calc(var(--modal-zindex)  + 1);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 17px;
}

.overlap-group17-2 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 211px;
  left: 355px;
}

.pollack-2 {
  color: var(--x66);
  z-index: calc(var(--modal-zindex)  + 1);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 17px;
}

.overlap-group18-2 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 557px;
  left: 355px;
}

.devante-ltd-2 {
  color: var(--x66);
  z-index: calc(var(--modal-zindex)  + 1);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 17px;
}

.overlap-group19-2 {
  height: 62px;
  width: 328px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 384px;
  left: 355px;
}

.x13kg-1 {
  color: var(--x66);
  z-index: calc(var(--modal-zindex)  + 1);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 229px;
}

.number-23 {
  color: var(--x66);
  z-index: calc(var(--modal-zindex)  + 1);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 17px;
}

.group-459-x {
  height: 61px;
  min-width: 333px;
  z-index: calc(var(--modal-zindex)  + 1);
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 729px;
  left: 355px;
}

.flex-col-63 {
  min-height: 61px;
  width: 136px;
  z-index: calc(var(--modal-zindex)  + 1);
  flex-direction: column;
  align-items: center;
  display: flex;
}

.catch-certificate-3 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 113px;
  z-index: calc(var(--modal-zindex)  + 1);
  margin-right: 9px;
}

.overlap-group20-3 {
  background-color: var(--white);
  height: 37px;
  min-width: 136px;
  z-index: calc(var(--modal-zindex)  + 1);
  border-radius: 5px;
  align-items: flex-start;
  margin-top: 7px;
  padding: 6px 10px;
  display: flex;
}

.k234-h5685-dfh9, .date-39 {
  color: var(--x66);
  min-height: 20px;
  z-index: calc(var(--modal-zindex)  + 1);
  font-weight: 300;
  position: relative;
  top: 4px;
}

.flex-col-64 {
  min-height: 61px;
  width: 136px;
  z-index: calc(var(--modal-zindex)  + 1);
  flex-direction: column;
  align-items: center;
  margin-left: 53px;
  display: flex;
}

.date-of-inssuance {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 118px;
  z-index: calc(var(--modal-zindex)  + 1);
  margin-right: 4px;
}

.overlap-group21-3 {
  background-color: var(--white);
  height: 37px;
  min-width: 136px;
  z-index: calc(var(--modal-zindex)  + 1);
  border-radius: 5px;
  align-items: flex-start;
  margin-top: 7px;
  padding: 6px 22px;
  display: flex;
}

.overlap-group22-2 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 294px;
  left: 764px;
}

.ice {
  letter-spacing: 0;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 37px;
  left: 17px;
}

.span1-21 {
  color: var(--text);
  z-index: calc(var(--modal-zindex)  + 1);
  font-weight: 300;
}

.overlap-group23-2 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 211px;
  left: 764px;
}

.porthcalw-sd {
  color: var(--text);
  z-index: calc(var(--modal-zindex)  + 1);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 17px;
}

.overlap-group24-2 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 377px;
  left: 764px;
}

.x2-4-dock-st-porthcawl-cf36-3-bl-uk, .st-georges-channel-2, .name-19, .x3205911-h3-s {
  color: var(--text);
  z-index: calc(var(--modal-zindex)  + 1);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 17px;
}

.overlap-group25-2 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 460px;
  left: 764px;
}

.overlap-group26-2 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 543px;
  left: 764px;
}

.overlap-group27-2 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 729px;
  left: 764px;
}

.chris_-hemsworth_-signature-1 {
  height: 37px;
  object-fit: cover;
  width: 93px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 664px;
  left: 804px;
}

.rectangle-134-1 {
  -webkit-backdrop-filter: blur(4px) brightness();
  backdrop-filter: blur(4px) brightness();
  background-color: var(--concord);
  height: 952px;
  width: 1440px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 0;
  left: 0;
}

.health-certificate-2 {
  letter-spacing: 0;
  z-index: calc(var(--modal-zindex)  + 2);
  position: absolute;
  top: 145px;
  left: 355px;
}

.group-411-2 {
  height: 20px;
  width: 22px;
  z-index: calc(var(--modal-zindex)  + 2);
  position: absolute;
  top: 143px;
  left: 526px;
}

.rectangle-135-1 {
  background-color: var(--white);
  height: 832px;
  width: 809px;
  z-index: calc(var(--modal-zindex)  + 1);
  border-radius: 10px;
  position: absolute;
  top: 102px;
  left: 306px;
  box-shadow: 0 7px 27px #00000040;
}

.overlap-group11-1 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 336px;
  left: 739px;
}

.x112022 {
  color: var(--x66);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 17px;
}

.overlap-group12-1 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 262px;
  left: 739px;
}

.place-2 {
  color: var(--x66);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 17px;
}

.overlap-group13-1 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 484px;
  left: 739px;
}

.overlap-group14-1 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 558px;
  left: 739px;
}

.welsh-porth-police, .x5518-hb830, .sammuel-l-13112022, .w4-d87-k3330 {
  color: var(--text);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 17px;
}

.overlap-group15 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 632px;
  left: 739px;
}

.overlap-group16 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 706px;
  left: 739px;
}

.overlap-group17 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 780px;
  left: 739px;
}

.overlap-group18 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 410px;
  left: 739px;
}

.place-3, .name-5 {
  color: var(--x66);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 17px;
}

.health-certificate-3 {
  letter-spacing: 0;
  position: absolute;
  top: 128px;
  left: 351px;
}

.group-411-3 {
  height: 20px;
  width: 22px;
  position: absolute;
  top: 126px;
  left: 522px;
}

.overlap-group19 {
  height: 61px;
  width: 331px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 486px;
  left: 351px;
}

.st-georges-channel, .name-4, .phone {
  color: var(--x66);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 17px;
}

.by-net {
  color: var(--x66);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 218px;
}

.overlap-group20-1 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 782px;
  left: 351px;
}

.overlap-group21-1 {
  height: 61px;
  width: 331px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 708px;
  left: 351px;
}

.sealess-rock {
  color: var(--x66);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 206px;
}

.overlap-group22 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 560px;
  left: 351px;
}

.overlap-group23 {
  height: 62px;
  width: 328px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 336px;
  left: 351px;
}

.x13-kg {
  letter-spacing: 0;
  position: absolute;
  top: 37px;
  left: 229px;
}

.span1 {
  color: var(--x66);
  font-weight: 300;
}

.grade-a {
  color: var(--x66);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 17px;
}

.overlap-group24 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 262px;
  left: 351px;
}

.pollack {
  color: var(--x66);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 17px;
}

.overlap-group25-1 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 188px;
  left: 351px;
}

.devante-ltd {
  color: var(--x66);
  font-weight: 300;
  position: absolute;
  top: 36px;
  left: 17px;
}

.overlap-group26-1 {
  height: 62px;
  width: 328px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 411px;
  left: 351px;
}

.x13-kg-1 {
  color: var(--x66);
  z-index: calc(var(--modal-zindex)  + 1);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 229px;
}

.number-6 {
  color: var(--x66);
  font-weight: 300;
  position: absolute;
  top: 37px;
  left: 17px;
}

.overlap-group27 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 188px;
  left: 739px;
}

.x26-dgklg78, .bollachius-pollachius {
  letter-spacing: 0;
  position: absolute;
  top: 37px;
  left: 17px;
}

.overlap-group28 {
  height: 61px;
  width: 327px;
  z-index: calc(var(--modal-zindex)  + 1);
  position: absolute;
  top: 634px;
  left: 351px;
}

.first-row-widgets {
  display: flex;
}

.steps-widget {
  background-color: var(--white);
  height: 142px;
  min-width: 1259px;
  max-width: 1259px;
  border-radius: 6px;
  align-items: center;
  margin-top: 23px;
  margin-right: 1px;
  padding: 16px 26px 21px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.step-1-active {
  min-width: 180px;
  height: 105px;
  width: 180px;
  background: linear-gradient(#1e3c72 0%, #2a5298 100%);
  border-radius: 8px;
  flex-direction: column;
  align-self: flex-start;
  align-items: flex-start;
  padding: .1px 16.2px;
  display: flex;
  overflow: hidden;
}

.vessel-data {
  letter-spacing: 0;
  min-height: 19px;
  width: 78px;
  margin-top: 10px;
}

.group-container-107 {
  min-width: 137px;
  align-items: flex-end;
  margin-top: 3px;
  display: flex;
}

.overlap-group-162 {
  background-color: var(--black-2);
  height: 13px;
  min-width: 13px;
  border-radius: 6.46px;
  justify-content: center;
  align-items: center;
  margin-bottom: 7.83px;
  padding: 0 3px;
  display: flex;
}

.group-407-27 {
  height: 72px;
  width: 72px;
  margin-left: 52px;
}

.step-2 {
  min-width: 180px;
  background-color: var(--bon-jour);
  height: 105px;
  width: 180px;
  -o-transition: filter .2s ease-in-out, background-image .2s ease-in-out;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1px;
  margin-left: 25px;
  padding: 7.3px 16.2px;
  transition: filter .2s ease-in-out, background-image .2s ease-in-out;
  display: flex;
  overflow: hidden;
}

.step-2-active {
  background-image: linear-gradient(#02aab0 0%, #00cdac 100%);
}

.lot-data {
  letter-spacing: 0;
  min-height: 19px;
  width: 54px;
  margin-top: 3px;
}

.group-container-108 {
  min-width: 141px;
  align-items: flex-end;
  margin-top: 9px;
  display: flex;
}

.overlap-group-163 {
  background-color: var(--black-2);
  height: 13px;
  min-width: 13px;
  border-radius: 6.46px;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.6px;
  padding: 0 3.6px;
  display: flex;
}

.group-412-18 {
  height: 59px;
  width: 88px;
  margin-left: 40px;
}

.step-3 {
  min-width: 180px;
  background-color: var(--bon-jour);
  height: 105px;
  width: 180px;
  -o-transition: filter .2s ease-in-out, background-image .2s ease-in-out;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1px;
  margin-left: 25px;
  padding: 7.3px 16.2px;
  transition: filter .2s ease-in-out, background-image .2s ease-in-out;
  display: flex;
  overflow: hidden;
}

.step-3-active {
  background-image: linear-gradient(#7474bf 0%, #348ac7 100%);
}

.sales-note {
  letter-spacing: 0;
  min-height: 19px;
  width: 69px;
  margin-top: 3px;
}

.step-4 {
  min-width: 180px;
  background-color: var(--bon-jour);
  height: 105px;
  width: 180px;
  -o-transition: filter .2s ease-in-out, background-image .2s ease-in-out;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1px;
  margin-left: 25px;
  padding: 3.6px 15.3px;
  transition: filter .2s ease-in-out, background-image .2s ease-in-out;
  display: flex;
  overflow: hidden;
}

.step-4-active {
  background-image: linear-gradient(#485563 0%, #29323c 100%);
}

.transport-doc {
  letter-spacing: 0;
  min-height: 19px;
  width: 93px;
  margin-top: 6px;
}

.group-container-109 {
  min-width: 145px;
  align-items: flex-end;
  margin-top: 13px;
  display: flex;
}

.overlap-group-165 {
  background-color: var(--black-2);
  height: 13px;
  min-width: 13px;
  border-radius: 6.46px;
  justify-content: center;
  align-items: center;
  margin-bottom: 3.67px;
  padding: 0 3px;
  display: flex;
}

.group-408-19 {
  height: 59px;
  width: 94px;
  margin-left: 38px;
}

.step-5 {
  min-width: 180px;
  background-color: var(--bon-jour);
  height: 105px;
  width: 180px;
  -o-transition: filter .2s ease-in-out, background-image .2s ease-in-out;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1px;
  margin-left: 25px;
  padding: 2.9px 15.3px;
  transition: filter .2s ease-in-out, background-image .2s ease-in-out;
  display: flex;
  overflow: hidden;
}

.step-5-active {
  background-image: linear-gradient(#8d79f6 0%, #5158d7 100%);
}

.catch-certificate {
  letter-spacing: 0;
  min-height: 19px;
  width: 113px;
  margin-top: 7px;
}

.group-container-110 {
  min-width: 144px;
  align-items: flex-end;
  margin-top: 4px;
  display: flex;
}

.overlap-group-166 {
  background-color: var(--black-2);
  height: 13px;
  min-width: 13px;
  border-radius: 6.46px;
  justify-content: center;
  align-items: center;
  margin-bottom: 4.4px;
  padding: 0 4px;
  display: flex;
}

.group-410-20 {
  height: 69px;
  width: 55px;
  margin-left: 76px;
}

.step-6 {
  min-width: 180px;
  background-color: var(--bon-jour);
  height: 105px;
  width: 180px;
  -o-transition: filter .2s ease-in-out, background-image .2s ease-in-out;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1px;
  margin-left: 25px;
  padding: 4.7px 15.3px;
  transition: filter .2s ease-in-out, background-image .2s ease-in-out;
  display: flex;
  overflow: hidden;
}

.step-6-active {
  background-image: linear-gradient(#1d976c 0%, #93f9b9 100%);
}

.health-certificate {
  letter-spacing: 0;
  min-height: 19px;
  width: 116px;
  margin-top: 5px;
}

.group-container-111 {
  min-width: 142px;
  align-items: flex-end;
  margin-top: 8px;
  display: flex;
}

.overlap-group-167 {
  background-color: var(--black-2);
  height: 13px;
  min-width: 13px;
  border-radius: 6.46px;
  justify-content: center;
  align-items: center;
  margin-bottom: 3.41px;
  padding: 0 4px;
  display: flex;
}

.group-411-21 {
  height: 64px;
  width: 70px;
  margin-left: 59px;
}

.number-2-fixed, .number-3-fixed, .number-4-fixed, .number-5-fixed {
  position: relative;
  top: .5px;
}

.current-step {
  cursor: pointer;
  filter: drop-shadow(-4px 6px 9px #00000040);
}

.last-row-widgets {
  min-width: 1259px;
  align-items: flex-start;
  margin-top: 22px;
  margin-right: 1px;
  display: flex;
}

.overlap-group7-25 {
  height: 38px;
  min-width: 144px;
  background: linear-gradient(#0575e6 0%, #021b79 100%);
  border-radius: 10px;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-top: 2px;
  margin-left: 38px;
  padding: 6px 16px;
  display: flex;
}

.go-to-passport-1 {
  letter-spacing: 0;
}

.frame-1-15 {
  background-color: var(--white);
  width: 570px;
  border-radius: 10px;
  align-items: flex-start;
  display: flex;
  overflow: hidden;
  box-shadow: inset 1px 1px 12px #000000ba;
}

.overlap-group10-9 {
  height: 308px;
  min-width: 570px;
  background-position: 50%;
  background-size: cover;
  align-items: flex-start;
  padding: 45.5px 151px;
  display: flex;
}

.chart-name-47 {
  letter-spacing: 0;
  min-height: 24px;
  white-space: nowrap;
  margin-top: 17px;
  margin-left: 26px;
  line-height: 24px;
  position: absolute;
}

.ellipse-44-12 {
  background-color: var(--zest);
  height: 20px;
  width: 20px;
  border-radius: 10px;
  align-self: flex-end;
  margin-bottom: 29px;
  margin-left: 205px;
}

.flex-col-53 {
  background-color: var(--white);
  min-height: 310px;
  width: 390px;
  filter: drop-shadow(0 0 10px #21427d33);
  border-radius: 10px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 26px;
  padding: 18px 11px;
  display: flex;
}

.flex-col-54 {
  width: 218px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 7px;
  margin-left: 9px;
  display: flex;
}

.fish {
  letter-spacing: 0;
}

.lot-weight-457kg {
  letter-spacing: 0;
  margin-top: 12px;
}

.flex-row-8 {
  min-width: 337px;
  align-items: flex-start;
  margin-top: 12px;
  margin-left: 9px;
  display: flex;
  position: relative;
}

.flex-col-55 {
  width: 119px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.pln-boat-0409954-sealess-rock {
  letter-spacing: 0;
  margin-top: 12px;
}

.group-117 {
  height: 130px;
  width: 130px;
  margin-left: 88px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.group-117 > img {
  height: 130px;
  width: 130px;
}

.overlap-group-container-2 {
  min-width: 390px;
  align-items: flex-start;
  margin-top: 25px;
  display: flex;
}

.overlap-group2-22 {
  background-color: var(--x5);
  height: 100px;
  min-width: 187px;
  border-radius: 8px;
  align-items: flex-start;
  padding: 9px 14px;
  display: flex;
}

.fishing-30 {
  letter-spacing: 0;
  min-height: 21px;
  min-width: 53px;
}

.fishing-step-icon {
  height: 52px;
  width: 45px;
  align-self: flex-end;
  margin-bottom: 6px;
  margin-left: 51px;
}

.overlap-group3-3 {
  background-color: var(--x5);
  height: 100px;
  min-width: 187px;
  border-radius: 8px;
  align-items: flex-start;
  margin-left: 16px;
  padding: 10px 16px;
  display: flex;
}

.place-6 {
  letter-spacing: 0;
  min-height: 21px;
  min-width: 59px;
}

.landing-step-icon {
  height: 54px;
  width: 54px;
  align-self: flex-end;
  margin-bottom: 5px;
  margin-left: 38px;
}

.overlap-group-container-3 {
  min-width: 390px;
  align-items: flex-start;
  margin-top: 12px;
  display: flex;
}

.overlap-group4-7 {
  background-color: var(--x5);
  height: 100px;
  min-width: 187px;
  border-radius: 8px;
  align-items: flex-start;
  padding: 10px 14px;
  display: flex;
}

.weighting {
  letter-spacing: 0;
  min-height: 21px;
  min-width: 76px;
}

.weighting-step-icon {
  height: 52px;
  width: 45px;
  align-self: flex-end;
  margin-bottom: 4px;
  margin-left: 31px;
}

.overlap-group5-4 {
  background-color: var(--x5);
  min-height: 100px;
  width: 187px;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
  padding: 10px 16px;
  display: flex;
}

.auctioning {
  letter-spacing: 0;
  min-height: 21px;
  width: 100px;
}

.auctioning-step-icon {
  height: 51px;
  width: 52px;
  align-self: flex-end;
  margin-top: 2px;
  margin-right: 3.48px;
}

.overlap-group-container-4 {
  height: 100px;
  min-width: 390px;
  align-items: flex-start;
  margin-top: 12px;
  display: flex;
}

.overlap-group6-4 {
  background-color: var(--x5);
  min-height: 100px;
  width: 187px;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  padding: 11px 16px;
  display: flex;
}

.transportation {
  letter-spacing: 0;
  min-height: 21px;
}

.transport-step-icon {
  height: 44px;
  width: 70px;
  align-self: flex-end;
  margin-top: 10px;
  margin-right: 4.89px;
}

.overlap-group7-4 {
  background-color: var(--x5);
  min-height: 100px;
  width: 187px;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 16px;
  padding: 9px 18px;
  display: flex;
}

.custom-clearance {
  letter-spacing: 0;
  min-height: 21px;
  min-width: 134px;
  margin-right: 17px;
}

.custom-cl-step-icon {
  height: 50px;
  width: 42px;
  margin-top: 8px;
  margin-right: 2.92px;
}

.passport-button {
  cursor: pointer;
}

.passport-button svg {
  color: #1e3c72;
  opacity: .2;
}

.passport-active-button {
  color: var(--white);
  background: linear-gradient(#1e3c72 0%, #2a5298 100%);
}

.passport-active-button svg {
  color: var(--white);
}

.passport-disabled-button {
  cursor: default;
  color: silver;
  background-color: #e7e7e7;
}

.passport-disabled-button svg {
  color: #d4d4d4;
  opacity: 1;
}

.passport-transaction-fields {
  margin-left: 9px;
}

.transaction-id-field {
  min-height: 44px;
  margin-top: 12px;
}

.transaction-id-field-label {
  align-items: center;
  margin-bottom: 7px;
  display: flex;
}

.transaction-id-field-label > :not(:last-child) {
  margin-right: 12px;
}

.transaction-id-field-value {
  height: 14px;
  color: #767676;
  display: block;
}

.transaction-url-field {
  color: var(--bay-of-many);
  align-items: center;
  margin-top: 8px;
  display: flex;
}

.transaction-url-field:hover > :first-child {
  text-decoration: underline;
}

.transaction-url-field > :not(:last-child) {
  margin-right: 12px;
}

.hash-tooltip {
  cursor: pointer;
}

.frame-2 {
  background-color: var(--white);
  height: 665px;
  width: 746px;
  border-radius: 10px;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.passport-initial-map-image {
  height: 665px;
  object-fit: cover;
  width: 1440px;
}

.ellipse-container {
  height: 335px;
  width: 527px;
  background-image: url("vector-5@1x.441db1ab.svg");
  background-size: 100% 100%;
  position: absolute;
  top: 159px;
  left: 207px;
}

.ellipse-7 {
  background-color: var(--zest);
  height: 11px;
  width: 11px;
  border-radius: 5.37px;
  position: absolute;
  top: 14px;
  left: 140px;
}

.ellipse-8 {
  height: 21px;
  width: 21px;
  border-radius: 10.73px;
  position: absolute;
  top: 9px;
  left: 135px;
}

.overlap-group11-15 {
  height: 310px;
  width: 746px;
  border-radius: 10px;
  position: relative;
}

.frame-2-2 {
  background-color: var(--white);
  height: 310px;
  width: 746px;
  border-radius: 10px;
  align-items: flex-end;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.passport-fishing-map-image {
  height: 310px;
  object-fit: cover;
  width: 746px;
}

.ellipse-container-2 {
  height: 219px;
  width: 344px;
  background-image: url("vector-5-1@2x.3945a8f9.svg");
  background-size: 100% 100%;
  position: absolute;
  top: 72px;
  left: 272px;
}

.ellipse-7-2 {
  background-color: var(--zest);
  height: 7px;
  width: 7px;
  border-radius: 3.5px;
  position: absolute;
  top: 10px;
  left: 92px;
}

.ellipse-8-2 {
  height: 15px;
  width: 15px;
  border-radius: 7px;
  position: absolute;
  top: 6px;
  left: 88px;
}

.group-410-18 {
  background-color: var(--zest);
  height: 32px;
  min-width: 32px;
  border-radius: 26px;
  align-items: flex-start;
  padding: 5px 6.7px;
  display: flex;
  position: absolute;
  top: 67px;
  left: 320px;
}

.group-73-8 {
  height: 20px;
  width: 17px;
}

.overlap-group14-7 {
  height: 310px;
  width: 746px;
  border-radius: 10px;
  position: relative;
}

.frame-2-4 {
  background-color: var(--white);
  height: 310px;
  width: 746px;
  border-radius: 10px;
  align-items: flex-end;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.passport-landing-map-image {
  height: 310px;
  min-width: 746px;
  background-position: 50%;
  background-size: cover;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 25px 350.5px;
  display: flex;
}

.group-428-5 {
  background-color: var(--zest);
  height: 32px;
  min-width: 32px;
  border-radius: 26px;
  align-items: center;
  padding: 0 7px;
  display: flex;
}

.group-424 {
  height: 18px;
  width: 18px;
}

.overlap-group7-19 {
  height: 225px;
  width: 344px;
  position: absolute;
  top: 66px;
  left: 272px;
}

.vector-5-2 {
  height: 219px;
  width: 344px;
  position: absolute;
  top: 6px;
  left: 0;
}

.ellipse-container-3 {
  height: 14px;
  width: 14px;
  border-radius: 7px;
  position: absolute;
  top: 0;
  left: 123px;
}

.ellipse-7-3 {
  background-color: var(--zest);
  height: 7px;
  width: 7px;
  border-radius: 3.5px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.ellipse-8-3 {
  height: 15px;
  width: 15px;
  border-radius: 7px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.overlap-group14-8 {
  height: 310px;
  width: 746px;
  border-radius: 10px;
  position: relative;
}

.frame-2-6 {
  background-color: var(--white);
  height: 310px;
  width: 746px;
  border-radius: 10px;
  align-items: flex-end;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.passport-weighting-map-image {
  height: 310px;
  min-width: 746px;
  background-position: 50%;
  background-size: cover;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px 327.5px;
  display: flex;
}

.group-425 {
  background-color: var(--zest);
  height: 32px;
  min-width: 32px;
  border-radius: 26px;
  justify-content: center;
  align-items: center;
  padding: 0 7.7px;
  display: flex;
}

.group-75-6 {
  height: 18px;
  width: 15px;
}

.overlap-group7-20 {
  height: 225px;
  width: 344px;
  position: absolute;
  top: 66px;
  left: 272px;
}

.vector-5-3 {
  height: 219px;
  width: 344px;
  position: absolute;
  top: 6px;
  left: 0;
}

.ellipse-container-4 {
  height: 14px;
  width: 14px;
  border-radius: 7px;
  position: absolute;
  top: 0;
  left: 123px;
}

.ellipse-7-4 {
  background-color: var(--zest);
  height: 7px;
  width: 7px;
  border-radius: 3.5px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.ellipse-8-4 {
  height: 15px;
  width: 15px;
  border-radius: 7px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.overlap-group14-10 {
  height: 310px;
  width: 746px;
  border-radius: 10px;
  position: relative;
}

.frame-2-10 {
  background-color: var(--white);
  height: 310px;
  width: 746px;
  border-radius: 10px;
  align-items: flex-end;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.passport-auctioning-map-image {
  height: 310px;
  min-width: 746px;
  background-position: 50%;
  background-size: cover;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 25px 300.5px;
  display: flex;
}

.group-423 {
  background-color: var(--zest);
  height: 32px;
  min-width: 32px;
  border-radius: 26px;
  align-items: flex-start;
  padding: 6px 6.5px;
  display: flex;
}

.group-417 {
  height: 18px;
  width: 18px;
}

.overlap-group7-23 {
  height: 225px;
  width: 344px;
  position: absolute;
  top: 66px;
  left: 272px;
}

.vector-5-5 {
  height: 219px;
  width: 344px;
  position: absolute;
  top: 6px;
  left: 0;
}

.ellipse-container-6 {
  height: 14px;
  width: 14px;
  border-radius: 7px;
  position: absolute;
  top: 0;
  left: 123px;
}

.ellipse-7-6 {
  background-color: var(--zest);
  height: 7px;
  width: 7px;
  border-radius: 3.5px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.ellipse-8-6 {
  height: 15px;
  width: 15px;
  border-radius: 7px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.overlap-group12-20 {
  height: 310px;
  width: 746px;
  border-radius: 10px;
  position: relative;
}

.frame-2-8 {
  background-color: var(--white);
  height: 310px;
  width: 746px;
  border-radius: 10px;
  align-items: flex-end;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.passport-transportation-map-image {
  height: 310px;
  min-width: 746px;
  background-position: 50%;
  background-size: cover;
  align-items: flex-end;
  padding: 150.5px 257.5px 124.5px;
  display: flex;
}

.group-426 {
  background-color: var(--zest);
  height: 32px;
  min-width: 32px;
  border-radius: 26px;
  align-items: center;
  padding: 0 4.7px;
  display: flex;
}

.group-77-6 {
  height: 14px;
  width: 22px;
}

.overlap-group7-21 {
  height: 219px;
  width: 350px;
  position: absolute;
  top: 72px;
  left: 266px;
}

.vector-5-4 {
  height: 219px;
  width: 344px;
  position: absolute;
  top: 0;
  left: 6px;
}

.ellipse-container-5 {
  height: 14px;
  width: 14px;
  border-radius: 7px;
  position: absolute;
  top: 141px;
  left: 0;
}

.ellipse-7-5 {
  background-color: var(--zest);
  height: 7px;
  width: 7px;
  border-radius: 3.5px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.ellipse-8-5 {
  height: 15px;
  width: 15px;
  border-radius: 7px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.overlap-group13-21 {
  height: 310px;
  width: 746px;
  border-radius: 10px;
  position: relative;
}

.frame-2-12 {
  background-color: var(--white);
  height: 310px;
  width: 746px;
  border-radius: 10px;
  align-items: flex-end;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.passport-custom-clearance-map-image {
  height: 310px;
  min-width: 746px;
  background-position: 50%;
  background-size: cover;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 40.5px 114.5px;
  display: flex;
}

.group-427-5 {
  background-color: var(--zest);
  height: 32px;
  min-width: 32px;
  border-radius: 26px;
  align-items: center;
  padding: 0 6.6px;
  display: flex;
}

.group-78-7 {
  height: 18px;
  width: 15px;
  position: relative;
  left: 1px;
}

.overlap-group7-26 {
  height: 225px;
  width: 351px;
  position: absolute;
  top: 72px;
  left: 272px;
}

.vector-5-6 {
  height: 219px;
  width: 344px;
  position: absolute;
  top: 0;
  left: 0;
}

.ellipse-container-7 {
  height: 14px;
  width: 14px;
  border-radius: 7px;
  position: absolute;
  top: 211px;
  left: 337px;
}

.ellipse-7-7 {
  background-color: var(--zest);
  height: 7px;
  width: 7px;
  border-radius: 3.5px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.ellipse-8-7 {
  height: 15px;
  width: 15px;
  border-radius: 7px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.section-data {
  min-height: 114px;
  width: 148px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 18px;
  margin-left: 37px;
  display: flex;
  position: relative;
}

.group-381-2-xx {
  height: 23px;
  min-width: 80px;
  display: flex;
}

.fishing-32 {
  letter-spacing: 0;
  min-height: 23px;
  min-width: 55px;
}

.fishing-icon {
  margin-bottom: 8px;
  margin-left: 7px;
}

.group-37, .group-37-1, .group-37-2, .group-37-3, .group-37-4, .group-37-5, .group-37-6 {
  height: 20px;
  min-width: 152px;
  align-items: flex-start;
  margin-top: 11px;
  display: flex;
}

.course-2, .course-3, .course-4, .course-5, .course-6, .course-7, .course-8 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 51px;
}

.route-slooq-1, .route-slooq-2, .route-slooq-3, .route-slooq-4, .route-slooq-5, .route-slooq-6, .route-slooq-7 {
  color: var(--text);
  min-height: 20px;
  margin-left: 10px;
  font-weight: 300;
}

.group-37-7, .group-37-8, .group-37-9, .group-37-10, .group-37-11, .group-37-12, .group-37-13 {
  height: 20px;
  min-width: 127px;
  align-items: flex-start;
  margin-top: 10px;
  display: flex;
}

.date-21, .date-23, .date-25, .date-27, .date-29, .date-31, .date-33 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 35px;
}

.date-22, .date-24, .date-26, .date-28, .date-30, .date-32, .date-34 {
  color: var(--text);
  min-height: 20px;
  margin-left: 26px;
  font-weight: 300;
}

.group-379, .group-379-1, .group-379-2, .group-379-3, .group-379-4, .group-379-5, .group-379-6 {
  height: 20px;
  min-width: 119px;
  align-items: flex-start;
  margin-top: 10px;
  display: flex;
}

.time-2, .time-3, .time-4, .time-5, .time-6, .time-7, .time-8 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 37px;
}

.text-8-1, .text-20-1, .text-22-1, .text-24-1, .text-26-1, .text-29-1, .text-33-1 {
  color: var(--text);
  min-height: 20px;
  margin-left: 24px;
  font-weight: 300;
}

.section-data {
  min-height: 114px;
  width: 148px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 18px;
  margin-left: 37px;
  display: flex;
  position: relative;
}

.group-381-2-xx {
  height: 23px;
  min-width: 80px;
  display: flex;
}

.fishing-32 {
  letter-spacing: 0;
  min-height: 23px;
  min-width: 55px;
}

.landing-icon {
  margin-bottom: 8px;
  margin-left: 7px;
}

.group-37, .group-37-1, .group-37-2, .group-37-3, .group-37-4, .group-37-5, .group-37-6 {
  height: 20px;
  min-width: 152px;
  align-items: flex-start;
  margin-top: 11px;
  display: flex;
}

.course-2, .course-3, .course-4, .course-5, .course-6, .course-7, .course-8 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 51px;
}

.route-slooq-1, .route-slooq-2, .route-slooq-3, .route-slooq-4, .route-slooq-5, .route-slooq-6, .route-slooq-7 {
  color: var(--text);
  min-height: 20px;
  margin-left: 10px;
  font-weight: 300;
}

.group-37-7, .group-37-8, .group-37-9, .group-37-10, .group-37-11, .group-37-12, .group-37-13 {
  height: 20px;
  min-width: 127px;
  align-items: flex-start;
  margin-top: 10px;
  display: flex;
}

.date-21, .date-23, .date-25, .date-27, .date-29, .date-31, .date-33 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 35px;
}

.date-22, .date-24, .date-26, .date-28, .date-30, .date-32, .date-34 {
  color: var(--text);
  min-height: 20px;
  margin-left: 26px;
  font-weight: 300;
}

.group-379, .group-379-1, .group-379-2, .group-379-3, .group-379-4, .group-379-5, .group-379-6 {
  height: 20px;
  min-width: 119px;
  align-items: flex-start;
  margin-top: 10px;
  display: flex;
}

.time-2, .time-3, .time-4, .time-5, .time-6, .time-7, .time-8 {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 37px;
}

.text-8-1, .text-20-1, .text-22-1, .text-24-1, .text-26-1, .text-29-1, .text-33-1 {
  color: var(--text);
  min-height: 20px;
  margin-left: 24px;
  font-weight: 300;
}

.group-394, .group-394-1, .group-394-2, .group-394-3, .group-394-4, .group-394-5 {
  min-height: 294px;
  width: 237px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 18px;
  margin-left: 37px;
  display: flex;
}

.group-383, .group-383-1, .group-383-2, .group-383-3, .group-383-4, .group-383-5 {
  height: 23px;
  min-width: 107px;
  display: flex;
}

.weighting-2, .weighting-3, .weighting-4, .weighting-5, .weighting-6, .weighting-7 {
  letter-spacing: 0;
  min-height: 23px;
  min-width: 79px;
}

.fishery-name-james-miles, .fishery-name-james-miles-1, .fishery-name-james-miles-2, .fishery-name-james-miles-3, .fishery-name-james-miles-4, .fishery-name-james-miles-5 {
  letter-spacing: 0;
  min-height: 20px;
  margin-top: 11px;
}

.span1-2, .span1-3, .span1-4, .span1-5, .span1-6, .span1-7 {
  color: var(--text);
  font-weight: 300;
}

.group-394-item, .group-394-item-2, .group-394-item-4, .group-394-item-6, .group-394-item-8, .group-394-item-10 {
  letter-spacing: 0;
  min-height: 20px;
  margin-top: 5px;
}

.group-394-item-1, .group-394-item-3, .group-394-item-5, .group-394-item-7, .group-394-item-9, .group-394-item-11 {
  letter-spacing: 0;
  min-height: 40px;
  margin-top: 5px;
}

.weighting-icon {
  margin-bottom: 8px;
  margin-left: 7px;
}

.group-396, .group-396-1, .group-396-2, .group-396-3, .group-396-4, .group-396-5 {
  min-height: 205px;
  width: 178px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 18px;
  margin-left: 37px;
  display: flex;
}

.group-374, .group-374-1, .group-374-2, .group-374-3, .group-374-4, .group-374-5 {
  height: 23px;
  min-width: 112px;
  display: flex;
}

.auctioning-2, .auctioning-3, .auctioning-4, .auctioning-5, .auctioning-6, .auctioning-7 {
  letter-spacing: 0;
  min-height: 23px;
  min-width: 84px;
}

.client-name-devante-ltd-xx, .client-name-devante-ltd-1, .client-name-devante-ltd-2, .client-name-devante-ltd-3, .client-name-devante-ltd-4, .client-name-devante-ltd-5 {
  letter-spacing: 0;
  min-height: 20px;
  margin-top: 12px;
}

.span1-8, .span1-9, .span1-10, .span1-11, .span1-12, .span1-13 {
  color: var(--text);
  font-weight: 300;
}

.group-396-item, .group-396-item-1, .group-396-item-2, .group-396-item-3, .group-396-item-4, .group-396-item-5 {
  letter-spacing: 0;
  min-height: 20px;
  margin-top: 5px;
}

.auctioning-icon {
  margin-bottom: 8px;
  margin-left: 7px;
}

.group-397, .group-397-1, .group-397-2, .group-397-3, .group-397-4, .group-397-5 {
  min-height: 312px;
  width: 249px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 18px;
  margin-left: 37px;
  display: flex;
}

.group-384, .group-384-1, .group-384-2, .group-384-3, .group-384-4, .group-384-5 {
  height: 23px;
  min-width: 148px;
  display: flex;
}

.transportation-2, .transportation-3, .transportation-4, .transportation-5, .transportation-6, .transportation-7 {
  letter-spacing: 0;
  min-height: 23px;
  min-width: 115px;
}

.client-name-devante-ltd-6, .client-name-devante-ltd-7, .client-name-devante-ltd-8, .client-name-devante-ltd-9, .client-name-devante-ltd-10, .client-name-devante-ltd-11 {
  letter-spacing: 0;
  min-height: 20px;
  margin-top: 4px;
}

.span-3, .span-5, .span-7, .span-9, .span-11, .span-13 {
  color: var(--text);
  font-weight: 300;
}

.group-397-item, .group-397-item-1, .group-397-item-2, .group-397-item-3, .group-397-item-4, .group-397-item-5 {
  letter-spacing: 0;
  min-height: 20px;
  margin-top: 5px;
}

.place-and-time, .place-and-time-1, .place-and-time-2, .place-and-time-3, .place-and-time-4, .place-and-time-5 {
  letter-spacing: 0;
  min-height: 40px;
  margin-top: 5px;
}

.transportation-icon {
  margin-bottom: 8px;
  margin-left: 7px;
}

.group-407-14 {
  height: 24px;
  min-width: 24px;
  cursor: pointer;
  background: linear-gradient(#1e3c72 0%, #2a5298 100%);
  border-radius: 12px;
  align-self: flex-end;
  align-items: flex-end;
  margin-right: 17px;
  padding: 7.9px 5.4px;
  transition: all .4s ease-in-out;
  display: flex;
  position: absolute;
  bottom: 13px;
}

.rotate-arrow-icon {
  transform: rotate(-180deg);
}

.vector-4-10 {
  height: 7px;
  width: 13px;
}

.group-407-14.group-407-15 {
  top: 2579px;
}

.group-405 {
  min-height: 688px;
  width: 284px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.fish-species-pollack {
  letter-spacing: 0;
  min-height: 20px;
}

.span-21 {
  color: var(--text);
  font-weight: 300;
}

.group-405-item {
  letter-spacing: 0;
  min-height: 20px;
  margin-top: 5px;
}

.group-405-item-1 {
  letter-spacing: 0;
  min-height: 40px;
  margin-top: 5px;
}

.food-standart-approval-566-kd0993 {
  letter-spacing: 0;
  min-height: 20px;
  margin-top: 93px;
}

.group-405 {
  min-height: 688px;
  width: 284px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.fish-species-pollack {
  letter-spacing: 0;
  min-height: 20px;
}

.span-21 {
  color: var(--text);
  font-weight: 300;
}

.group-405-item {
  letter-spacing: 0;
  min-height: 20px;
  margin-top: 5px;
}

.group-405-item-1 {
  letter-spacing: 0;
  min-height: 40px;
  margin-top: 5px;
}

.food-standart-approval-566-kd0993 {
  letter-spacing: 0;
  min-height: 20px;
  margin-top: 93px;
}

.group-400, .group-400-1, .group-400-2, .group-400-3, .group-400-4, .group-400-5 {
  min-height: 274px;
  width: 300px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 18px;
  margin-left: 37px;
  display: flex;
}

.group-385, .group-385-1, .group-385-2, .group-385-3, .group-385-4, .group-385-5 {
  height: 23px;
  min-width: 168px;
  display: flex;
}

.custom-clearance-2, .custom-clearance-3, .custom-clearance-4, .custom-clearance-5, .custom-clearance-6, .custom-clearance-7 {
  letter-spacing: 0;
  min-width: 142px;
}

.group-78-1, .group-78-2, .group-78-3, .group-78-4, .group-78-5, .group-78-6 {
  height: 18px;
  width: 16px;
  margin-bottom: 3.5px;
  margin-left: 7px;
}

.catch-certificate-k234-h5685-dfh99-xx, .catch-certificate-k234-h5685-dfh99-1, .catch-certificate-k234-h5685-dfh99-2, .catch-certificate-k234-h5685-dfh99-3, .catch-certificate-k234-h5685-dfh99-4, .catch-certificate-k234-h5685-dfh99-5 {
  letter-spacing: 0;
  min-height: 20px;
  margin-top: 11px;
}

.span1-14, .span1-15, .span1-16, .span1-17, .span1-18, .span1-19 {
  color: var(--text);
  font-weight: 300;
}

.group-400-item, .group-400-item-1, .group-400-item-2, .group-400-item-3, .group-400-item-4, .group-400-item-5 {
  letter-spacing: 0;
  min-height: 20px;
  margin-top: 5px;
}

.exporter-address-2, .exporter-address-2-1, .exporter-address-2-2, .exporter-address-2-3, .exporter-address-2-4, .exporter-address-2-5 {
  letter-spacing: 0;
  min-height: 40px;
  margin-top: 5px;
}



.group-73-9 {
  height: 18px;
  width: 16px;
  margin-bottom: 1px;
  margin-left: 7px;
}

.overlap-group13-15 {
  background-color: var(--white);
  height: 330px;
  min-width: 746px;
  border-radius: 10px;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 25px;
  padding: 0 1px;
  display: flex;
}

.pollock1-1-2 {
  height: 261px;
  object-fit: cover;
  width: 348px;
  align-self: center;
  margin-top: 9px;
}

.frame-step-data {
  height: 329px;
  width: 338px;
  margin-left: 29px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame-2-5 {
  min-height: 329px;
  width: 337px;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 16px;
  display: flex;
  position: relative;
}

.group-401-2 {
  min-height: 2584px;
  width: 247px;
  flex-direction: column;
  align-self: center;
  align-items: flex-start;
  margin-top: -285px;
  margin-right: 16px;
  display: flex;
  position: relative;
}

.group-399-3 {
  min-height: 2093px;
  width: 247px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.group-398-4 {
  min-height: 1516px;
  width: 219px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.group-395-5 {
  min-height: 1095px;
  width: 219px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.group-393-6 {
  min-height: 428px;
  width: 148px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.group-392-6 {
  min-height: 114px;
  width: 148px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 200px;
  display: flex;
  position: relative;
}

.group-382-6 {
  height: 23px;
  min-width: 95px;
  align-items: flex-start;
  display: flex;
}

.place-32 {
  letter-spacing: 0;
  min-height: 23px;
  min-width: 62px;
}

.group-74-6 {
  height: 18px;
  width: 18px;
  margin-top: 1px;
  margin-left: 13px;
}

.digital-passport {
  background-color: var(--white);
  height: auto;
  min-width: 1260px;
  border-radius: 15px;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 4px;
  padding: 34px 45px;
  display: flex;
  box-shadow: 1px 14px 29px #0a2a881a;
}

.flex-col-52 {
  min-height: 727px;
  width: 390px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.title {
  letter-spacing: 0;
  min-height: 36px;
}

.overlap-group13-4 {
  height: 665px;
  width: 746px;
  border-radius: 10px;
  flex-direction: column;
  margin-left: 32px;
  display: flex;
  position: relative;
}

.verify-header {
  background: unset;
  background-color: #1d1b5a;
  position: sticky;
  top: 0;
}

.home-menu-button {
  height: 26px;
  letter-spacing: 0;
  min-width: 44px;
}

.about-menu-button {
  height: 26px;
  letter-spacing: 0;
  min-width: 44px;
  margin-left: 30px;
}

.what-we-offer-menu-button {
  height: 26px;
  letter-spacing: 0;
  min-width: 107px;
  margin-left: 30px;
}

.how-it-works-menu-button {
  height: 26px;
  letter-spacing: 0;
  min-width: 97px;
  margin-left: 30px;
}

.contact-menu-button {
  height: 26px;
  letter-spacing: 0;
  min-width: 56px;
  margin-left: 30px;
}

.verification {
  color: var(--white);
  font-family: var(--font-family-khula);
  height: 45px;
  letter-spacing: 0;
  text-transform: uppercase;
  font-size: 28px;
  font-weight: 700;
  position: absolute;
  top: 21px;
  left: 51px;
}

@media screen and (max-width: 900px) {
  .verify-header {
    min-width: unset;
    width: 100vw;
    padding-right: 51px;
  }

  .verify-header .verification {
    font-size: 24px;
    top: 24px;
  }

  .navigation-menu > .home-menu-button, .navigation-menu > .about-menu-button, .navigation-menu > .what-we-offer-menu-button, .navigation-menu > .how-it-works-menu-button, .navigation-menu > .contact-menu-button {
    width: 0;
    display: none;
    overflow: hidden;
  }

  .verify-header > .navigation-menu:before {
    content: "";
    width: 33px;
    height: 22px;
    background-image: url("burger-menu.b080ad23.svg");
  }
}

.group-454 {
  height: 567px;
  min-width: 403px;
  z-index: calc(var(--modal-zindex)  + 1);
  align-items: flex-start;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlap-group11-1x {
  height: 567px;
  width: 395px;
}

.rectangle-447 {
  background-color: var(--white);
  height: 551px;
  width: 384px;
  border-radius: 10px;
  position: absolute;
  top: 16px;
  left: 0;
  box-shadow: 0 4px 8px #00000040;
}

.report-an-issue {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  letter-spacing: 0;
  font-weight: 700;
  position: absolute;
  top: 48px;
  left: 29px;
}

.rectangle-448 {
  height: 248px;
  width: 328px;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  position: absolute;
  top: 217px;
  left: 23px;
}

.rectangle-449 {
  background-color: var(--bay-of-many);
  height: 40px;
  width: 77px;
  border-radius: 10px;
  position: absolute;
  top: 497px;
  left: 153px;
}

.rectangle-450 {
  border: 2px solid var(--gravel);
  height: 40px;
  width: 126px;
  border-radius: 10px;
  position: absolute;
  top: 112px;
  left: 30px;
}

.place-26 {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  letter-spacing: 0;
  font-weight: 600;
  position: absolute;
  top: 508px;
  left: 171px;
}

.subject {
  letter-spacing: 0;
  position: absolute;
  top: 124px;
  left: 52px;
}

.write-a-message {
  letter-spacing: 0;
  position: absolute;
  top: 185px;
  left: 29px;
}

.group-429-1 {
  background-color: var(--white);
  height: 32px;
  min-width: 32px;
  cursor: pointer;
  border-radius: 16px;
  align-items: flex-start;
  padding: 1.1px;
  display: flex;
  position: absolute;
  top: 0;
  left: 363px;
  box-shadow: 0 0 4px #00000040;
}

.cancel {
  height: 30px;
  width: 30px;
}

.x4-1 {
  height: 26px;
  width: 26px;
  position: absolute;
  top: 119px;
  left: 117px;
}

.fishing-verified-step {
  height: 49px;
  width: 802px;
  z-index: 7;
  cursor: pointer;
  align-items: center;
  margin-top: 61px;
  padding-left: 21px;
  display: flex;
  position: relative;
}

.step-label {
  color: var(--astronaut);
  margin-right: 17px;
  font-weight: 600;
}

.landing-verified-step {
  height: 49px;
  width: 802px;
  z-index: 8;
  cursor: pointer;
  align-items: center;
  padding-left: 21px;
  display: flex;
  position: relative;
}

.weighting-verified-step {
  height: 49px;
  width: 802px;
  z-index: 11;
  cursor: pointer;
  align-items: center;
  padding-left: 21px;
  display: flex;
  position: relative;
}

.auctioning-verified-step {
  height: 49px;
  width: 802px;
  z-index: 12;
  cursor: pointer;
  align-items: center;
  padding-left: 21px;
  display: flex;
  position: relative;
}

.transportation-verified-step {
  height: 49px;
  width: 802px;
  z-index: 13;
  cursor: pointer;
  align-items: center;
  padding-left: 21px;
  display: flex;
  position: relative;
}

.custom-clearance-verified-step {
  height: 49px;
  width: 802px;
  z-index: 14;
  cursor: pointer;
  align-items: center;
  padding-left: 21px;
  display: flex;
  position: relative;
}

.border-top-verified-step {
  border-top: .2px solid var(--bay-of-many);
}

.border-bottom-verified-step {
  border-bottom: .2px solid var(--bay-of-many);
}

.step-icon {
  height: 18px;
  width: 18px;
  margin-right: 17px;
}

.expand-icon {
  height: 26px;
  width: 26px;
  transition: transform .2s ease-in-out;
  position: absolute;
  top: 14px;
  right: 12px;
}

.expand-less-icon {
  transform: rotate(-180deg);
}

.step-data {
  width: 802px;
  z-index: 14;
  flex-direction: column;
  align-self: flex-start;
  align-items: flex-start;
  transition: height .4s ease-in-out;
  display: flex;
}

.step-data-1-2 {
  height: 214px;
}

.step-data-content {
  margin-top: 17px;
  padding-left: 35px;
}

.closed-step {
  height: 0;
  overflow: hidden;
}

.open-step {
  height: 214px;
}

.group-428-1-xy {
  height: 20px;
  min-width: 152px;
  align-items: flex-start;
  display: flex;
}

.course {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 51px;
}

.route-slooq-1 {
  color: var(--text);
  min-height: 20px;
  margin-left: 10px;
  font-weight: 300;
}

.group-429-2-xy {
  height: 20px;
  min-width: 127px;
  align-items: flex-start;
  margin-top: 10px;
  display: flex;
}

.date {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 35px;
}

.date-1 {
  color: var(--text);
  min-height: 20px;
  margin-left: 26px;
  font-weight: 300;
}

.group-430-3 {
  height: 20px;
  min-width: 119px;
  align-items: flex-start;
  margin-top: 10px;
  display: flex;
}

.time {
  letter-spacing: 0;
  min-height: 20px;
  min-width: 37px;
}

.text-1 {
  color: var(--text);
  min-height: 20px;
  margin-left: 24px;
  font-weight: 300;
}

.report-issue-button {
  background-color: var(--bright-red-3);
  height: 42px;
  width: 116px;
  cursor: pointer;
  border-radius: 13px;
  align-items: center;
  margin-top: 34px;
  margin-bottom: 41px;
  padding: 7.5px 15.5px;
  display: flex;
}

.report-issue-button-text {
  letter-spacing: 0;
}

.step-data-content-3 {
  margin-top: 10px;
}

.open-step-3 {
  height: 393px;
}

.group-449 {
  min-height: 336px;
  width: 237px;
  z-index: 13;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
  margin-left: 114px;
  display: flex;
}

.span1 {
  color: var(--text);
  font-weight: 300;
}

.group-449-item {
  letter-spacing: 0;
  min-height: 20px;
  margin-top: 5px;
}

.group-449-item-1 {
  letter-spacing: 0;
  min-height: 40px;
  margin-top: 5px;
}

.span1-1 {
  color: var(--text);
  font-weight: 300;
}

.group-448-item {
  letter-spacing: 0;
  min-height: 20px;
  margin-top: 5px;
}

.step-data-content-4 {
  margin-top: 10px;
}

.open-step-4 {
  height: 300px;
}

.span-11 {
  color: var(--text);
  font-weight: 300;
}

.group-447-item {
  letter-spacing: 0;
  min-height: 20px;
  margin-top: 5px;
}

.place-and-time {
  letter-spacing: 0;
  min-height: 40px;
  margin-top: 5px;
}

.step-data-content-5 {
  margin-top: 10px;
}

.open-step-5 {
  height: 417px;
}

.group-446-xx {
  height: 526px;
  min-width: 654px;
  z-index: 13;
  align-items: flex-start;
  display: flex;
}

.flex-col-38 {
  min-height: 521px;
  width: 244px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.catch-certificate-k234-h5685-dfh99, .client-name-devante-ltd {
  letter-spacing: 0;
  min-height: 20px;
  margin-top: 0;
}

.span-4 {
  color: var(--text);
  font-weight: 300;
}

.validating-entity-porthcalw-sd, .exporter-name-ice, .fao-area-st-georges-channel, .master-of-vessel-bentley-grey, .master-signature-bentley-grey, .licence-number-3205911-h3-s, .fish-species-pollack, .lot-weight-13kg-4, .fishery-name-james-miles, .lot-grade-grade-a, .address-x, .total-weight-13kg, .catch-area-st-georges-channel, .catch-method-by-net, .name-market-operator-john-klopper, .food-standart-approval-566-kd0993, .contry-origin-wales, .contry-of-destination-france, .fish-latin-name-bo, .health-certificate-5518-hb830, .iso-code-w4-d87-k3330 {
  letter-spacing: 0;
  min-height: 20px;
  margin-top: 7px;
}

.exporter-address-2, .pln-boat-0409954-sealess-rock-4, .authority-date-sig, .place-and-time-of-lo {
  letter-spacing: 0;
  min-height: 40px;
  margin-top: 7px;
}

.date-of-issuance-12112022, .competent-authority-welsh-porth-police {
  letter-spacing: 0;
  min-height: 20px;
}

.place-and-time-desti {
  letter-spacing: 0;
  min-height: 40px;
}

.overlap-group-23 {
  background-color: var(--bright-red-3);
  height: 42px;
  min-width: 116px;
  cursor: pointer;
  border-radius: 13px;
  align-items: center;
  margin-top: 41px;
  padding: 7px 15px;
  display: flex;
}

.flex-col-39 {
  min-height: 458px;
  width: 266px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 108px;
  display: flex;
}

.step-data-content-6 {
  margin-top: 17px;
}

.open-step-6 {
  height: 577px;
}

@media screen and (max-width: 900px) {
  .fishing-verified-step {
    width: 100%;
    margin-top: 0;
  }

  .landing-verified-step, .weighting-verified-step, .auctioning-verified-step, .transportation-verified-step, .custom-clearance-verified-step, .closed-step {
    width: 100%;
  }

  .step-data-content-6 {
    overflow-y: auto;
  }
}

.verify-page {
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 15px;
  display: flex;
  position: relative;
}

.verify-preview-container {
  max-width: 854px;
  max-height: 611px;
  position: relative;
  overflow: hidden;
}

.verify-preview-inner-container {
  background-color: var(--black-haze);
  height: 100%;
  border-radius: 14px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 24px;
  display: flex;
  box-shadow: inset 0 0 1px #00000040;
}

.verify-preview-image {
  max-width: 100%;
  max-height: 477px;
  border-radius: 8.94375px;
  box-shadow: .59625px 8.3475px 17.2913px #0a2a881a;
}

.download-button-container {
  height: 40px;
  min-width: 150px;
  cursor: pointer;
  border-radius: 37px;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  display: flex;
}

.download-button-text {
  letter-spacing: 0;
  text-align: center;
  width: 76px;
}

.download-icon {
  height: 28px;
  width: 28px;
  margin-right: 5px;
}

.data-tabs {
  min-width: 1309px;
  margin-bottom: 220px;
}

@media screen and (max-width: 900px) {
  .verify-page-content {
    min-width: unset;
    padding-right: 51px;
  }

  .verify-page {
    flex-direction: column;
    row-gap: 15px;
  }

  .verify-preview-container {
    width: 100%;
  }

  .passport-data-container {
    width: 100%;
    position: static;
  }

  .data-tabs {
    width: 100%;
    min-width: unset;
  }

  .download-button-container {
    display: none;
  }
}

.issuer-info-container {
  height: 97px;
  min-width: 324px;
  width: 100%;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
  margin-top: 22px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.issuer-name {
  align-items: flex-start;
  margin-left: 24px;
  display: flex;
}

.issuer-name-text_label {
  height: 25px;
  letter-spacing: 0;
  margin-top: -1px;
}

.issuer-avatar {
  background-color: var(--malibu);
  height: 55px;
  width: 55px;
  border-radius: 27.5px;
  align-items: center;
  padding: 0 8px;
  display: flex;
}

.ellipse-46 {
  background-color: var(--white);
  height: 4px;
  width: 4px;
  border-radius: 2px;
  margin-top: 1px;
}

.vector-49 {
  height: 14px;
  width: 34px;
}

@media screen and (max-width: 900px) {
  .issuer-info-container {
    min-width: unset;
    height: unset;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
    padding-top: 22px;
    padding-bottom: 22px;
  }

  .issuer-name {
    width: calc(100% - 79px);
    margin-left: 0;
  }
}

.passport-data-container {
  height: 611px;
  max-width: 491px;
  width: 491px;
  border-radius: 10px;
  align-items: flex-start;
  display: flex;
  position: sticky;
  top: 144px;
  left: 920px;
}

.passport-data-inner-container {
  min-height: 611px;
  width: 100%;
  border-radius: 10px;
  position: relative;
}

.inner-col-1 {
  background-color: var(--alabaster);
  min-height: 611px;
  width: 100%;
  border-radius: 10px;
  flex-direction: column;
  align-items: flex-end;
  padding: 25px 43.6px;
  display: flex;
}

.inner-col-2 {
  min-height: 469px;
  width: 100%;
  flex-direction: column;
  align-self: center;
  align-items: flex-start;
  margin-left: 1px;
  display: flex;
}

.overlap-group1-1 {
  height: 30px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.passport-file-title {
  align-items: center;
  column-gap: 12px;
  display: flex;
}

.small-download-button-container {
  display: none;
}

.enable-label-1 {
  letter-spacing: 0;
}

.file-verified-icon {
  height: 28px;
  width: 22px;
}

.line-2 {
  height: 1px;
  width: 400px;
  margin-top: 22px;
}

.line-3 {
  height: 1px;
  width: 400px;
  margin-top: 97px;
}

.flex-row-1 {
  min-width: 298px;
  align-items: center;
  margin-top: 31px;
  display: flex;
}

.popularc {
  height: 28px;
  width: 28px;
}

.edit-title {
  height: 16px;
  letter-spacing: 0;
  width: 100px;
  margin-left: 14px;
}

.overlap-group2-1 {
  height: 16px;
  width: 150px;
  white-space: nowrap;
  margin-left: 6px;
  position: relative;
}

.enable-label-2 {
  height: 16px;
  letter-spacing: 0;
  width: fit-content;
}

.verify-issuer-check-icon {
  height: 34px;
  width: 34px;
  position: absolute;
  top: -10px;
  right: 0;
}

.flex-row-2 {
  min-width: 148px;
  align-items: center;
  margin-top: 32px;
  display: flex;
}

.file-name-1 {
  height: 16px;
  letter-spacing: 0;
  width: 106px;
  margin-bottom: 2px;
  margin-left: 14px;
}

.line-4 {
  height: 1px;
  width: 400px;
  margin-top: 30px;
}

.buttons-container {
  width: 100%;
  min-height: 144px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  gap: 15px;
  margin-top: 30px;
  padding-top: 49px;
  padding-bottom: 37px;
  display: flex;
  position: relative;
}

.verify-button {
  height: 42px;
  width: 301px;
  cursor: pointer;
  background-color: #2b4d89;
  border-radius: 37px;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  transition: all .2s ease-in-out;
  display: flex;
  position: absolute;
}

.verify-button:hover {
  width: 317px;
  height: 47px;
  box-shadow: -4px 4px 6px #00000040;
}

.verify-text {
  letter-spacing: 0;
  min-width: 50px;
  text-align: center;
}

.policy-icon {
  height: 27px;
  width: 27px;
  margin-right: 5px;
}

.verified-icon {
  margin-right: 13px;
}

.verified-button {
  border: 2px solid var(--chateau-green);
  cursor: auto;
  width: 160px;
  height: 42px;
  transform: unset;
  background-color: #0000;
  position: static;
}

.verified-button:hover {
  box-shadow: unset;
  width: 160px;
  height: 42px;
  position: static;
}

.verified-button .verify-text {
  color: var(--chateau-green);
}

.proof-file-cloud-icon {
  height: 24px;
  width: 24px;
}

.proof-file-button {
  background-color: var(--bay-of-many);
  height: 42px;
  min-width: 160px;
  cursor: pointer;
  border-radius: 37px;
  align-items: flex-start;
  padding: 8px 20px;
  transition: box-shadow .2s ease-in-out;
  display: flex;
}

.proof-file-button:hover {
  box-shadow: -4px 4px 6px #00000040;
}

.proof-file-text {
  letter-spacing: 0;
  min-width: 84px;
  text-align: center;
  align-self: center;
  margin-bottom: 1.25px;
  margin-left: 8px;
}

.line-5 {
  height: 1px;
  width: 400px;
  margin-top: 51px;
}

.file-name-2 {
  height: 25px;
  letter-spacing: 0;
  margin-top: 15px;
  margin-left: 14px;
}

.share-buttons-container {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 21px;
  padding-left: 14px;
  display: flex;
}

.share-button {
  height: 46px;
  width: 46px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.share-copy-button {
  background-color: #1a7b91;
}

.share-twitter-button {
  background-color: #1da1f2;
}

.share-twitter-button > img {
  width: 30px;
  height: 30px;
}

.share-facebook-button {
  background-color: #3b5998;
}

.share-facebook-button > img {
  width: 30px;
  height: 30px;
}

.share-plus-button {
  background-color: #ff6550;
}

.share-plus-button > img {
  width: 30px;
  height: 30px;
}

.poppins-494949-16px {
  color: #494949;
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
}

.transaction-id-field-2 {
  min-height: 44px;
  margin-top: 38px;
}

.transaction-id-field-value-2 {
  height: 14px;
  color: #767676;
  font-family: var(--font-family-poppins);
  text-overflow: ellipsis;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  display: block;
  overflow: hidden;
}

.transaction-url-field-2 {
  height: 23px;
  color: var(--bay-of-many);
  align-items: center;
  margin-top: 33px;
  display: flex;
}

.transaction-url-field-2:hover > :first-child {
  text-decoration: underline;
}

.transaction-url-field-2 > :not(:last-child) {
  margin-right: 12px;
}

@media screen and (max-width: 900px) {
  .passport-data-container {
    max-width: 798px;
    width: 100%;
    min-height: 656px;
    height: unset;
    position: static;
  }

  .verify-button {
    max-width: 250px;
    width: 100%;
  }

  .verify-button.verified-button {
    width: 160px;
  }

  .small-download-button-container {
    width: 32px;
    height: 32px;
    cursor: pointer;
    background: linear-gradient(3deg, #1e3c72 100%, #2365d9 100%);
    border-radius: 37px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .small-download-button-icon {
    width: 30px;
    height: 30px;
    background-color: #fafafa;
    border-radius: 37px;
    padding: 2px;
  }

  .flex-row-1 {
    min-width: unset;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 8px;
  }

  .share-buttons-container {
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
  }

  .overlap-group1-1 {
    height: unset;
    flex-wrap: wrap;
    gap: 8px;
  }

  .inner-col-1 {
    padding: 25px;
  }
}

/*# sourceMappingURL=index.1a0e22f4.css.map */
