.group-396,
.group-396-1,
.group-396-2,
.group-396-3,
.group-396-4,
.group-396-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  margin-left: 37px;
  min-height: 205px;
  width: 178px;
}

.group-374,
.group-374-1,
.group-374-2,
.group-374-3,
.group-374-4,
.group-374-5 {
  display: flex;
  height: 23px;
  min-width: 112px;
}

.auctioning-2,
.auctioning-3,
.auctioning-4,
.auctioning-5,
.auctioning-6,
.auctioning-7 {
  letter-spacing: 0;
  min-height: 23px;
  min-width: 84px;
}

.client-name-devante-ltd-xx,
.client-name-devante-ltd-1,
.client-name-devante-ltd-2,
.client-name-devante-ltd-3,
.client-name-devante-ltd-4,
.client-name-devante-ltd-5 {
  letter-spacing: 0;
  margin-top: 12px;
  min-height: 20px;
}

.span1-8,
.span1-9,
.span1-10,
.span1-11,
.span1-12,
.span1-13 {
  color: var(--text);
  font-weight: 300;
}

.group-396-item,
.group-396-item-1,
.group-396-item-2,
.group-396-item-3,
.group-396-item-4,
.group-396-item-5 {
  letter-spacing: 0;
  margin-top: 5px;
  min-height: 20px;
}

.auctioning-icon {
  margin-bottom: 8px;
  margin-left: 7px;
}
