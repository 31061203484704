:root {
  --alabaster: #fafafa;
  --astronaut: #1e3c72;
  --astronaut-2: #1f3e75;
  --azure-radiance: #008ff8;
  --azure-radiance-2: #008ff833;
  --bay-of-many: #21427d;
  --black: #000000;
  --black-2: #00000042;
  --black-3: #00000029;
  --black-haze: #f5f7fa;
  --bon-jour: #dfdfdf;
  --cod-gray: #0b0b0b;
  --concord: #7d7d7d57;
  --gravel: #484848;
  --fuscous-gray: #565656;
  --gray-nurse: #e9e9e9;
  --guardsman-red: #d4040405;
  --guardsman-red-2: #d40404;
  --guardsman-red-3: #d404040d;
  --guardsman-red-4: #d40606;
  --bright-red: #b40000;
  --bright-red-3: #b400000f;
  --gun-powder: #444459;
  --masala: #3c3c3c;
  --mercury: #e7e7e7;
  --mercury-2: #e6e6e6;
  --mine-shaft: #2f2f2f;
  --mirage: #191b23;
  --ocean-blue-pearl: #4e3dc8;
  --sonic-silver: #75757580;
  --text: #757575;
  --tundora: #454545;
  --unmellow-yellow: #3c21f7;
  --malibu: #78a9ff;
  --diesel: #120304;
  --white: #ffffff;
  --abbey: #4d4d4d;
  --chateau-green: #4abc5d;
  --x5: #d4e8fb;
  --x66: #4e3cc7;
  --zest: #e7852b;
  --zest-2: #e7852b3b;

  --font-size-l: 14px;
  --font-size-m: 13px;
  --font-size-s: 12px;
  --font-size-xl: 15px;
  --font-size-xs: 11px;
  --font-size-xxl: 16px;
  --font-size-xxs: 9px;

  --font-family-inter: "Inter", Helvetica;
  --font-family-poppins: "Poppins", Helvetica;
  --font-family-sf_pro_display-medium: "SF Pro Display-Medium", Helvetica;
  --font-family-sf_pro_display-semibold: "SF Pro Display-Semibold", Helvetica;
  --font-family-khula: "Khula", Helvetica;
  --font-family-open_sans: "Open Sans", Helvetica;
  --font-family-roboto: "Roboto", Helvetica;

  --app-bar-zindex: 1100;
  --modal-zindex: 1300;
}

.t1 {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0;
}

.text {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0;
}

.poppins-semi-bold-tundora-13px {
  color: var(--tundora);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-white-13px {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.poppins-normal-sonic-silver-13px {
  color: var(--text);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.poppins-normal-white-11px {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.poppins-normal-white-12px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.poppins-semi-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
}

.poppins-bold-white-9px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 700;
}

.sfprodisplay-medium-gun-powder-12px {
  color: var(--gun-powder);
  font-family: var(--font-family-sf_pro_display-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.poppins-semi-bold-mine-shaft-13px {
  color: var(--mine-shaft);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.inter-normal-mirage-14px {
  color: var(--mirage);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.poppins-normal-fuscous-gray-11px {
  color: var(--fuscous-gray);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.poppins-medium-mirage-14px {
  color: var(--mirage);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.poppins-black-white-20px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
}

.poppins-semi-bold-astronaut-15px {
  color: var(--astronaut);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
}

.poppins-bold-mirage-16px {
  color: var(--mirage);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.poppins-bold-mine-shaft-16px {
  color: var(--mine-shaft);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.poppins-semi-bold-bay-of-many-16px {
  color: var(--bay-of-many);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-bay-of-many-15px {
  color: var(--bay-of-many);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
}

.poppins-medium-cod-gray-13px {
  color: var(--cod-gray);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.poppins-bold-white-14px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.poppins-bold-astronaut-14px {
  color: var(--astronaut);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.poppins-semi-bold-white-16px-2 {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
}

.poppins-medium-sonic-silver-16px {
  color: var(--text);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-sonic-silver-15px {
  color: var(--text);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-sonic-silver-9px {
  color: var(--text);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 500;
}

.sfprodisplay-normal-gun-powder-12px {
  color: var(--gun-powder);
  font-family: var(--font-family-sf_pro_display-semibold);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.poppins-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.poppins-semi-bold-unmellow-yellow-15-5px {
  color: var(--unmellow-yellow);
  font-family: var(--font-family-poppins);
  font-size: 15.5px;
  font-style: normal;
  font-weight: 600;
}

.poppins-medium-sonic-silver-13px {
  color: var(--text);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.poppins-semi-bold-astronaut-17-5px {
  color: var(--astronaut-2);
  font-family: var(--font-family-poppins);
  font-size: 17.5px;
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-ocean-blue-pearl-13px {
  color: var(--x66);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-white-15px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-cod-gray-13px {
  color: var(--cod-gray);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.poppins-bold-bay-of-many-24px {
  color: var(--bay-of-many);
  font-family: var(--font-family-poppins);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.poppins-semi-bold-sonic-silver-16px {
  color: var(--text);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
}

.poppins-light-masala-14px {
  color: var(--masala);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 300;
}

.poppins-semi-bold-sonic-silver-15px {
  color: var(--sonic-silver);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-guardsman-red-15px {
  color: var(--guardsman-red-4);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
}

.poppins-bold-bay-of-many-14px {
  color: var(--bay-of-many);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.poppins-semi-bold-astronaut-17-6px {
  color: var(--astronaut);
  font-family: var(--font-family-poppins);
  font-size: 17.6px;
  font-style: normal;
  font-weight: 600;
}

.poppins-light-ocean-blue-pearl-13px {
  color: var(--x66);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: italic;
  font-weight: 300;
}

.border-1px-mercury {
  border: 1px solid var(--mercury);
}

.border-1px-azure-radiance {
  border: 1px solid var(--azure-radiance);
}

.border-4px-white {
  border: 4px solid var(--white);
}

.border-1px-mercury-2 {
  border: 1px solid var(--mercury-2);
}

.border-1px-gray-nurse {
  border: 1px solid var(--gray-nurse);
}

.border-2px-zest {
  border: 2px solid var(--zest);
}

.border-1px-x5 {
  border: 1px solid var(--x5);
}

.border-2px-sonic-silver {
  border: 2px solid var(--sonic-silver);
}

.border-2px-guardsman-red-2 {
  border: 2px solid var(--guardsman-red-2);
}

.border-1px-zest {
  border: 1px solid var(--zest);
}

.border-1px-diesel {
  border: 1px solid var(--diesel);
}

.opensans-extra-bold-red-40-3px {
  color: var(--red);
  font-family: var(--font-family-open_sans);
  font-size: 40.3px;
  font-style: italic;
  font-weight: 800;
}

.opensans-bold-chateau-green-14-6px {
  color: var(--chateau-green);
  font-family: var(--font-family-open_sans);
  font-size: 14.6px;
  font-style: normal;
  font-weight: 700;
}

.opensans-bold-white-14-6px {
  color: var(--white);
  font-family: var(--font-family-open_sans);
  font-size: 14.6px;
  font-style: normal;
  font-weight: 700;
}

.opensans-normal-diesel-13px {
  color: var(--diesel);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.khula-bold-white-28-1px {
  color: var(--white);
  font-family: var(--font-family-khula);
  font-size: 28.1px;
  font-style: normal;
  font-weight: 700;
}

.opensans-bold-mine-shaft-12px {
  color: var(--mine-shaft);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.opensans-semi-bold-mine-shaft-18px {
  color: var(--mine-shaft);
  font-family: var(--font-family-open_sans);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
}

.opensans-bold-mine-shaft-22px {
  color: var(--mine-shaft);
  font-family: var(--font-family-open_sans);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}

.khula-semi-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-khula);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
}

.roboto-normal-abbey-14px {
  color: var(--abbey);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.poppins-semi-bold-bright-red-13px {
  color: var(--bright-red);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.border-1-5px-bright-red {
  border: 1.5px solid var(--bright-red);
}

.poppins-medium-gravel-14px {
  color: var(--gravel);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}
