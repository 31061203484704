.verify-page {
  justify-content: center;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  column-gap: 15px;
}

.verify-preview-container {
  max-width: 854px;
  max-height: 611px;
  overflow: hidden;
  position: relative;
}

.verify-preview-inner-container {
  background-color: var(--black-haze);
  border-radius: 14px;
  box-shadow: inset 0px 0px 1px #00000040;
  padding: 24px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.verify-preview-image {
  max-width: 100%;
  max-height: 477px;
  box-shadow: 0.59625px 8.3475px 17.2913px rgba(10, 42, 136, 0.1);
  border-radius: 8.94375px;
}

.download-button-container {
  align-items: center;
  border-radius: 37px;
  display: flex;
  height: 40px;
  justify-content: center;
  min-width: 150px;
  cursor: pointer;
  margin-top: 24px;
}

.download-button-text {
  letter-spacing: 0;
  text-align: center;
  width: 76px;
}

.download-icon {
  height: 28px;
  width: 28px;
  margin-right: 5px;
}

.data-tabs {
  min-width: 1309px;
  margin-bottom: 220px;
}

@media screen and (max-width: 900px) {
  .verify-page-content {
    min-width: unset;
    padding-right: 51px;
  }
  .verify-page {
    flex-direction: column;
    row-gap: 15px;
  }
  .verify-preview-container {
    width: 100%;
  }
  .passport-data-container {
    width: 100%;
    position: static;
  }
  .data-tabs {
    width: 100%;
    min-width: unset;
  }
  .download-button-container {
    display: none;
  }
}
