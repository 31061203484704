.group-4-3-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 61px;
  position: absolute;
  top: 0;
  width: 327px;
}

.name-market-operator {
  letter-spacing: 0;
  margin-left: 9px;
  min-height: 20px;
}

.rectangle-1-3 {
  background-color: var(--white);
  border-radius: 5px;
  height: 34px;
  margin-top: 7px;
  width: 325px;
}

.group-4-3-1.group-432,
.group-4-3-1.group-432-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 38px;
  min-height: 61px;
  position: absolute;
  top: 112px;
  width: 327px;
}

.group-4-3-1.group-431-1,
.group-4-3-1.group-431-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 38px;
  min-height: 61px;
  position: absolute;
  top: 518px;
  width: 327px;
}

.group-4-3-1.group-431-3,
.group-4-3-1.group-431-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 38px;
  min-height: 61px;
  position: absolute;
  top: 505px;
  width: 327px;
}

.group-4-3-1.group-432-2,
.group-4-3-1.group-432-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 38px;
  min-height: 61px;
  position: absolute;
  top: 119px;
  width: 327px;
}

.group-4-3-1.group-434,
.group-4-3-1.group-434-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 38px;
  min-height: 61px;
  position: absolute;
  top: 215px;
  width: 327px;
}

.group-4-3-1.group-431-5,
.group-4-3-1.group-431-6 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 38px;
  min-height: 61px;
  position: absolute;
  top: 102px;
  width: 327px;
}

.group-4-3-1.group-432-4,
.group-4-3-1.group-432-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 38px;
  min-height: 61px;
  position: absolute;
  top: 508px;
  width: 327px;
}

.group-4-3-1.group-434-2,
.group-4-3-1.group-434-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 38px;
  min-height: 61px;
  position: absolute;
  top: 346px;
  width: 327px;
}

