.pills-item-20,
.pills-item-21,
.pills-item-22,
.pills-item-23,
.pills-item-24,
.pills-item-25,
.pills-item-26,
.pills-item-27,
.pills-item-28,
.pills-item-29,
.pills-item-30,
.pills-item-31,
.pills-item-32,
.pills-item-33,
.pills-item-34,
.pills-item-35,
.pills-item-36,
.pills-item-37,
.pills-item-38,
.pills-item-39 {
  align-items: center;
  background-color: var(--white);
  box-shadow: inset 0px 1px 0px #c4c7cf , inset 0px -1px 0px #c4c7cf , inset -1px 0px 0px #c4c7cf;
  display: flex;
  height: 28px;
  position: relative;
  width: 59px;
}

