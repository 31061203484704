.rectangle-135-2 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 7px 27px #00000040;
  height: 560px;
  left: 519px;
  position: absolute;
  top: 209px;
  width: 400px;
  z-index: calc(var(--modal-zindex) + 1);
}

.course-1 {
  left: 565px;
  letter-spacing: 0;
  position: absolute;
  top: 339px;
  z-index: calc(var(--modal-zindex) + 1);
}

.date-14 {
  left: 565px;
  letter-spacing: 0;
  position: absolute;
  top: 420px;
  z-index: calc(var(--modal-zindex) + 1);
}

.time-1 {
  left: 565px;
  letter-spacing: 0;
  position: absolute;
  top: 506px;
  z-index: calc(var(--modal-zindex) + 1);
}

.rectangle-136-1 {
  background-color: var(--white);
  border-radius: 5px;
  height: 34px;
  left: 558px;
  position: absolute;
  top: 369px;
  width: 325px;
  z-index: calc(var(--modal-zindex) + 1);
}

.rectangle-137-7 {
  background-color: var(--white);
  border-radius: 5px;
  height: 34px;
  left: 558px;
  position: absolute;
  top: 449px;
  width: 325px;
  z-index: calc(var(--modal-zindex) + 1);
}

.rectangle-138-5 {
  background-color: var(--white);
  border-radius: 5px;
  height: 34px;
  left: 558px;
  position: absolute;
  top: 534px;
  width: 325px;
  z-index: calc(var(--modal-zindex) + 1);
}

.cancel-button-1 {
  background-color: var(--guardsman-red-3);
  border-radius: 10px;
  height: 38px;
  left: 608px;
  position: absolute;
  top: 678px;
  width: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: calc(var(--modal-zindex) + 1);
}

.save-button-1 {
  background-color: var(--unmellow-yellow);
  border-radius: 10px;
  height: 38px;
  left: 729px;
  position: absolute;
  top: 678px;
  width: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: calc(var(--modal-zindex) + 1);
}

.route-slooq {
  color: var(--text);
  font-weight: 300;
  left: 577px;
  position: absolute;
  top: 379px;
  z-index: calc(var(--modal-zindex) + 1);
}

.date-15 {
  color: var(--text);
  font-weight: 300;
  left: 577px;
  position: absolute;
  top: 459px;
  z-index: calc(var(--modal-zindex) + 1);
}

.text-5-1 {
  color: var(--text);
  font-weight: 300;
  left: 577px;
  position: absolute;
  top: 544px;
  z-index: calc(var(--modal-zindex) + 1);
}
