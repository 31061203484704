/* Fishing */

.group-73-9 {
  height: 18px;
  margin-bottom: 1px;
  margin-left: 7px;
  width: 16px;
}

/* Landing */

.overlap-group13-15 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  height: 330px;
  justify-content: flex-end;
  margin-top: 25px;
  min-width: 746px;
  padding: 0 1px;
}

.pollock1-1-2 {
  align-self: center;
  height: 261px;
  margin-top: 9px;
  object-fit: cover;
  width: 348px;
}

.frame-step-data {
  display: flex;
  height: 329px;
  margin-left: 29px;
  overflow: hidden;
  width: 338px;
  position: relative;
}

.frame-2-5 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 329px;
  padding: 0 16px;
  position: relative;
  width: 337px;
}

.group-401-2 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  margin-top: -285px;
  min-height: 2584px;
  position: relative;
  width: 247px;
}

.group-399-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 2093px;
  position: relative;
  width: 247px;
}

.group-398-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 1516px;
  position: relative;
  width: 219px;
}

.group-395-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 1095px;
  position: relative;
  width: 219px;
}

.group-393-6 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 428px;
  position: relative;
  width: 148px;
}

.group-392-6 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 200px;
  min-height: 114px;
  position: relative;
  width: 148px;
}

.group-382-6 {
  align-items: flex-start;
  display: flex;
  height: 23px;
  min-width: 95px;
}

.place-32 {
  letter-spacing: 0;
  min-height: 23px;
  min-width: 62px;
}

.group-74-6 {
  height: 18px;
  margin-left: 13px;
  margin-top: 1px;
  width: 18px;
}
