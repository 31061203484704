.group-4-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 61px;
  position: absolute;
  top: 0;
  width: 327px;
}

.place-time-of-loading-xx {
  letter-spacing: 0;
  margin-left: 9px;
  min-height: 20px;
}

.rectangle-1 {
  background-color: var(--white);
  border-radius: 5px;
  height: 34px;
  margin-top: 7px;
  width: 325px;
}

.group-4-2.group-444,
.group-4-2.group-444-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 412px;
  min-height: 61px;
  position: absolute;
  top: 214px;
  width: 327px;
}

.group-4-2.group-445,
.group-4-2.group-445-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 413px;
  min-height: 61px;
  position: absolute;
  top: 316px;
  width: 327px;
}

.group-4-2.group-446,
.group-4-2.group-446-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 413px;
  min-height: 61px;
  position: absolute;
  top: 418px;
  width: 327px;
}

.group-4-2.group-447,
.group-4-2.group-447-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 38px;
  min-height: 61px;
  position: absolute;
  top: 437px;
  width: 327px;
}

.group-4-2.group-448,
.group-4-2.group-448-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 413px;
  min-height: 61px;
  position: absolute;
  top: 518px;
  width: 327px;
}
