.group-408-10 {
  height: 20px;
  left: 490px;
  position: absolute;
  top: 184px;
  width: 32px;
}

.transport-doc-10 {
  left: 355px;
  letter-spacing: 0;
  position: absolute;
  top: 180px;
}

.devante-ltd-4 {
  color: var(--ocean-blue-pearl);
  font-weight: 300;
  left: 368px;
  position: absolute;
  top: 279px;
}

.rectangle-134-6 {
  -webkit-backdrop-filter: blur(4px) brightness(100%);
  backdrop-filter: blur(4px) brightness(100%);
  background-color: var(--concord);
  height: 952px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.overlap-group12-10 {
  height: 685px;
  left: 316px;
  position: absolute;
  top: 49px;
  width: 784px;
  z-index: calc(var(--modal-zindex) + 1);
}

.rectangle-135-6 {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 7px 27px #00000040;
  height: 670px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 779px;
}

.overlap-group-94 {
  height: 61px;
  left: 38px;
  position: absolute;
  top: 356px;
  width: 327px;
}

.name-27 {
  color: var(--x66);
  font-weight: 300;
  left: 17px;
  position: absolute;
  top: 38px;
}

.name-28 {
  color: var(--text);
  font-weight: 300;
  left: 55px;
  position: absolute;
  top: 555px;
}

.wales-porthcawl-10112022-050306 {
  color: var(--text);
  font-weight: 300;
  left: 55px;
  position: absolute;
  top: 474px;
}

.place-22 {
  color: var(--text);
  font-weight: 300;
  left: 430px;
  position: absolute;
  top: 251px;
}

.france-tourlaville-16112022 {
  color: var(--text);
  font-weight: 300;
  left: 430px;
  position: absolute;
  top: 555px;
}

.place-23 {
  color: var(--text);
  font-weight: 300;
  left: 430px;
  position: absolute;
  top: 456px;
  z-index: 14;
}

.bollachius-pollachius-2 {
  color: var(--text);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: italic;
  font-weight: 300;
  left: 430px;
  letter-spacing: 0;
  position: absolute;
  top: 353px;
}

.overlap-group1-34 {
  height: 61px;
  left: 413px;
  position: absolute;
  top: 112px;
  width: 327px;
}

.x26-dgklg78-2 {
  left: 17px;
  letter-spacing: 0;
  position: absolute;
  top: 37px;
}

.span1-23 {
  color: var(--text);
  font-weight: 300;
}

.overlap-group2-31 {
  height: 61px;
  left: 38px;
  position: absolute;
  top: 275px;
  width: 327px;
}

.pollack-4 {
  color: var(--x66);
  font-weight: 300;
  left: 17px;
  position: absolute;
  top: 38px;
}

.overlap-group3-11 {
  height: 62px;
  left: 38px;
  position: absolute;
  top: 193px;
  width: 328px;
}

.x13kg-3 {
  color: var(--x66);
  font-weight: 300;
  left: 229px;
  position: absolute;
  top: 38px;
}

.number-34 {
  color: var(--x66);
  font-weight: 300;
  left: 17px;
  position: absolute;
  top: 38px;
}

.group-481 {
  height: 20px;
  left: 174px;
  position: absolute;
  top: 48px;
  width: 32px;
}

.transport-doc-11 {
  left: 39px;
  letter-spacing: 0;
  position: absolute;
  top: 48px;
}

.devante-ltd-5 {
  color: var(--x66);
  font-weight: 300;
  left: 57px;
  position: absolute;
  top: 149px;
}
