.flag,
.flag-2,
.flag-4,
.flag-6,
.flag-8,
.flag-10,
.flag-12,
.flag-14,
.flag-16,
.flag-18,
.flag-20,
.flag-22,
.flag-24,
.flag-26,
.flag-28,
.flag-30,
.flag-32,
.flag-34,
.flag-36,
.flag-38 {
  align-items: flex-end;
  display: flex;
  height: 16px;
  padding: 2px 1px;
  width: 16px;
}

.flag-1,
.flag-3,
.flag-5,
.flag-7,
.flag-9,
.flag-11,
.flag-13,
.flag-15,
.flag-17,
.flag-19,
.flag-21,
.flag-23,
.flag-25,
.flag-27,
.flag-29,
.flag-31,
.flag-33,
.flag-35,
.flag-37,
.flag-39 {
  height: 11px;
  width: 14px;
}

