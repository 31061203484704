.tab-line-item-40 {
  align-items: flex-start;
  display: flex;
  height: 22px;
  left: 501px;
  min-width: 22px;
  position: absolute;
  top: 22px;
}

.tab-line-item-40.tab-line-item-41,
.tab-line-item-40.tab-line-item-43,
.tab-line-item-40.tab-line-item-45,
.tab-line-item-40.tab-line-item-47,
.tab-line-item-40.tab-line-item-49,
.tab-line-item-40.tab-line-item-51,
.tab-line-item-40.tab-line-item-53,
.tab-line-item-40.tab-line-item-55,
.tab-line-item-40.tab-line-item-57,
.tab-line-item-40.tab-line-item-59,
.tab-line-item-40.tab-line-item-61,
.tab-line-item-40.tab-line-item-63,
.tab-line-item-40.tab-line-item-65,
.tab-line-item-40.tab-line-item-67,
.tab-line-item-40.tab-line-item-69,
.tab-line-item-40.tab-line-item-71,
.tab-line-item-40.tab-line-item-73,
.tab-line-item-40.tab-line-item-75,
.tab-line-item-40.tab-line-item-77,
.tab-line-item-40.tab-line-item-79 {
  left: 539px;
}

