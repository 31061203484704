.group-400,
.group-400-1,
.group-400-2,
.group-400-3,
.group-400-4,
.group-400-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 274px;
  width: 300px;
  margin-top: 18px;
  margin-left: 37px;
}

.group-385,
.group-385-1,
.group-385-2,
.group-385-3,
.group-385-4,
.group-385-5 {
  display: flex;
  height: 23px;
  min-width: 168px;
}

.custom-clearance-2,
.custom-clearance-3,
.custom-clearance-4,
.custom-clearance-5,
.custom-clearance-6,
.custom-clearance-7 {
  letter-spacing: 0;
  min-width: 142px;
}

.group-78-1,
.group-78-2,
.group-78-3,
.group-78-4,
.group-78-5,
.group-78-6 {
  height: 18px;
  margin-bottom: 3.5px;
  margin-left: 7px;
  width: 16px;
}

.catch-certificate-k234-h5685-dfh99-xx,
.catch-certificate-k234-h5685-dfh99-1,
.catch-certificate-k234-h5685-dfh99-2,
.catch-certificate-k234-h5685-dfh99-3,
.catch-certificate-k234-h5685-dfh99-4,
.catch-certificate-k234-h5685-dfh99-5 {
  letter-spacing: 0;
  margin-top: 11px;
  min-height: 20px;
}

.span1-14,
.span1-15,
.span1-16,
.span1-17,
.span1-18,
.span1-19 {
  color: var(--text);
  font-weight: 300;
}

.group-400-item,
.group-400-item-1,
.group-400-item-2,
.group-400-item-3,
.group-400-item-4,
.group-400-item-5 {
  letter-spacing: 0;
  margin-top: 5px;
  min-height: 20px;
}

.exporter-address-2,
.exporter-address-2-1,
.exporter-address-2-2,
.exporter-address-2-3,
.exporter-address-2-4,
.exporter-address-2-5 {
  letter-spacing: 0;
  margin-top: 5px;
  min-height: 40px;
}
