.first-row-widgets {
  display: flex;
}

.steps-widget {
  align-items: center;
  background-color: var(--white);
  border-radius: 6px;
  display: flex;
  height: 142px;
  margin-right: 1px;
  margin-top: 23px;
  min-width: 1259px;
  padding-top: 16px;
  padding-bottom: 21px;
  padding-left: 26px;
  padding-right: 26px;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 1259px;
}

.step-1-active {
  min-width: 180px;
  align-items: flex-start;
  align-self: flex-start;
  background: linear-gradient(
    180deg,
    rgb(30, 60, 114) 0%,
    rgb(42, 82, 152) 100%
  );
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 105px;
  padding: 0.1px 16.2px;
  width: 180px;
  overflow: hidden;
}

.vessel-data {
  letter-spacing: 0;
  margin-top: 10px;
  min-height: 19px;
  width: 78px;
}

.group-container-107 {
  align-items: flex-end;
  display: flex;
  margin-top: 3px;
  min-width: 137px;
}

.overlap-group-162 {
  align-items: center;
  background-color: var(--black-2);
  border-radius: 6.46px;
  display: flex;
  height: 13px;
  justify-content: center;
  margin-bottom: 7.83px;
  min-width: 13px;
  padding: 0 3px;
}

.group-407-27 {
  height: 72px;
  margin-left: 52px;
  width: 72px;
}

.step-2 {
  min-width: 180px;
  background-color: var(--bon-jour);
  align-items: flex-start;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  margin-top: 1px;
  height: 105px;
  padding: 7.3px 16.2px;
  width: 180px;
  overflow: hidden;
  -webkit-transition: filter 0.2s ease-in-out, background-image 0.2s ease-in-out;
  -o-transition: filter 0.2s ease-in-out, background-image 0.2s ease-in-out;
  transition: filter 0.2s ease-in-out, background-image 0.2s ease-in-out;
}
.step-2-active {
  background-image: linear-gradient(#02aab0 0%, #00cdac 100%);
}

.lot-data {
  letter-spacing: 0;
  margin-top: 3px;
  min-height: 19px;
  width: 54px;
}

.group-container-108 {
  align-items: flex-end;
  display: flex;
  margin-top: 9px;
  min-width: 141px;
}

.overlap-group-163 {
  align-items: center;
  background-color: var(--black-2);
  border-radius: 6.46px;
  display: flex;
  height: 13px;
  justify-content: center;
  margin-bottom: 1.6px;
  min-width: 13px;
  padding: 0 3.6px;
}

.group-412-18 {
  height: 59px;
  margin-left: 40px;
  width: 88px;
}

.step-3 {
  min-width: 180px;
  align-items: flex-start;
  background-color: var(--bon-jour);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  margin-top: 1px;
  height: 105px;
  padding: 7.3px 16.2px;
  width: 180px;
  overflow: hidden;
  -webkit-transition: filter 0.2s ease-in-out, background-image 0.2s ease-in-out;
  -o-transition: filter 0.2s ease-in-out, background-image 0.2s ease-in-out;
  transition: filter 0.2s ease-in-out, background-image 0.2s ease-in-out;
}
.step-3-active {
  background-image: linear-gradient(#7474bf 0%, #348ac7 100%);
}

.sales-note {
  letter-spacing: 0;
  min-height: 19px;
  width: 69px;
  margin-top: 3px;
}

.step-4 {
  min-width: 180px;
  align-items: flex-start;
  background-color: var(--bon-jour);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  margin-top: 1px;
  height: 105px;
  padding: 3.6px 15.3px;
  width: 180px;
  overflow: hidden;
  -webkit-transition: filter 0.2s ease-in-out, background-image 0.2s ease-in-out;
  -o-transition: filter 0.2s ease-in-out, background-image 0.2s ease-in-out;
  transition: filter 0.2s ease-in-out, background-image 0.2s ease-in-out;
}
.step-4-active {
  background-image: linear-gradient(#485563 0%, #29323c 100%);
}

.transport-doc {
  letter-spacing: 0;
  margin-top: 6px;
  min-height: 19px;
  width: 93px;
}

.group-container-109 {
  align-items: flex-end;
  display: flex;
  margin-top: 13px;
  min-width: 145px;
}

.overlap-group-165 {
  align-items: center;
  background-color: var(--black-2);
  border-radius: 6.46px;
  display: flex;
  justify-content: center;
  height: 13px;
  margin-bottom: 3.67px;
  min-width: 13px;
  padding: 0 3px;
}

.group-408-19 {
  height: 59px;
  margin-left: 38px;
  width: 94px;
}

.step-5 {
  min-width: 180px;
  align-items: flex-start;
  background-color: var(--bon-jour);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  margin-top: 1px;
  height: 105px;
  padding: 2.9px 15.3px;
  width: 180px;
  overflow: hidden;
  -webkit-transition: filter 0.2s ease-in-out, background-image 0.2s ease-in-out;
  -o-transition: filter 0.2s ease-in-out, background-image 0.2s ease-in-out;
  transition: filter 0.2s ease-in-out, background-image 0.2s ease-in-out;
}
.step-5-active {
  background-image: linear-gradient(#8d79f6 0%, #5158d7 100%);
}

.catch-certificate {
  letter-spacing: 0;
  margin-top: 7px;
  min-height: 19px;
  width: 113px;
}

.group-container-110 {
  align-items: flex-end;
  display: flex;
  margin-top: 4px;
  min-width: 144px;
}

.overlap-group-166 {
  align-items: center;
  background-color: var(--black-2);
  border-radius: 6.46px;
  display: flex;
  justify-content: center;
  height: 13px;
  margin-bottom: 4.4px;
  min-width: 13px;
  padding: 0 4px;
}

.group-410-20 {
  height: 69px;
  margin-left: 76px;
  width: 55px;
}

.step-6 {
  min-width: 180px;
  align-items: flex-start;
  background-color: var(--bon-jour);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  margin-top: 1px;
  height: 105px;
  padding: 4.7px 15.3px;
  width: 180px;
  overflow: hidden;
  -webkit-transition: filter 0.2s ease-in-out, background-image 0.2s ease-in-out;
  -o-transition: filter 0.2s ease-in-out, background-image 0.2s ease-in-out;
  transition: filter 0.2s ease-in-out, background-image 0.2s ease-in-out;
}
.step-6-active {
  background-image: linear-gradient(#1d976c 0%, #93f9b9 100%);
}

.health-certificate {
  letter-spacing: 0;
  margin-top: 5px;
  min-height: 19px;
  width: 116px;
}

.group-container-111 {
  align-items: flex-end;
  display: flex;
  margin-top: 8px;
  min-width: 142px;
}

.overlap-group-167 {
  align-items: center;
  background-color: var(--black-2);
  border-radius: 6.46px;
  display: flex;
  justify-content: center;
  height: 13px;
  margin-bottom: 3.41px;
  min-width: 13px;
  padding: 0 4px;
}

.group-411-21 {
  height: 64px;
  margin-left: 59px;
  width: 70px;
}

.number-2-fixed {
  position: relative;
  top: 0.5px;
}

.number-3-fixed {
  position: relative;
  top: 0.5px;
}

.number-4-fixed {
  position: relative;
  top: 0.5px;
}

.number-5-fixed {
  position: relative;
  top: 0.5px;
}

.current-step {
  cursor: pointer;
  filter: drop-shadow(-4px 6px 9px rgba(0, 0, 0, 0.25));
}

.last-row-widgets {
  align-items: flex-start;
  display: flex;
  margin-right: 1px;
  margin-top: 22px;
  min-width: 1259px;
}

.overlap-group7-25 {
  align-items: flex-start;
  align-self: center;
  background: linear-gradient(
    180deg,
    rgb(5, 117, 230) 0%,
    rgb(2, 27, 121) 100%
  );
  border-radius: 10px;
  display: flex;
  height: 38px;
  justify-content: flex-end;
  margin-left: 38px;
  margin-top: 2px;
  min-width: 144px;
  padding: 6px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.go-to-passport-1 {
  letter-spacing: 0;
}

.frame-1-15 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: inset 1px 1px 12px #000000ba;
  display: flex;
  overflow: hidden;
  width: 570px;
}

.overlap-group10-9 {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 308px;
  min-width: 570px;
  padding: 45.5px 151px;
}

.chart-name-47 {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 24px;
  white-space: nowrap;
  margin-left: 26px;
  margin-top: 17px;
  position: absolute;
}

.ellipse-44-12 {
  align-self: flex-end;
  background-color: var(--zest);
  border-radius: 10px;
  height: 20px;
  margin-bottom: 29px;
  margin-left: 205px;
  width: 20px;
}
