.group-405 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 688px;
  width: 284px;
}

.fish-species-pollack {
  letter-spacing: 0;
  min-height: 20px;
}

.span-21 {
  color: var(--text);
  font-weight: 300;
}

.group-405-item {
  letter-spacing: 0;
  margin-top: 5px;
  min-height: 20px;
}

.group-405-item-1 {
  letter-spacing: 0;
  margin-top: 5px;
  min-height: 40px;
}

.food-standart-approval-566-kd0993 {
  letter-spacing: 0;
  margin-top: 93px;
  min-height: 20px;
}
