.passport-data-container {
  align-items: flex-start;
  display: flex;
  height: 611px;
  left: 920px;
  max-width: 491px;
  width: 491px;
  position: sticky;
  top: 144px;
  border-radius: 10px;
}

.passport-data-inner-container {
  border-radius: 10px;
  min-height: 611px;
  position: relative;
  width: 100%;
}

.inner-col-1 {
  align-items: flex-end;
  background-color: var(--alabaster);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  min-height: 611px;
  padding: 25px 43.6px;
  width: 100%;
}

.inner-col-2 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-left: 1px;
  min-height: 469px;
  width: 100%;
}

.overlap-group1-1 {
  height: 30px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.passport-file-title {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.small-download-button-container {
  display: none;
}

.enable-label-1 {
  letter-spacing: 0;
}

.file-verified-icon {
  height: 28px;
  width: 22px;
}

.line-2 {
  height: 1px;
  margin-top: 22px;
  width: 400px;
}

.line-3 {
  height: 1px;
  margin-top: 97px;
  width: 400px;
}

.flex-row-1 {
  align-items: center;
  display: flex;
  margin-top: 31px;
  min-width: 298px;
}

.popularc {
  height: 28px;
  width: 28px;
}

.edit-title {
  height: 16px;
  letter-spacing: 0;
  margin-left: 14px;
  width: 100px;
}

.overlap-group2-1 {
  height: 16px;
  margin-left: 6px;
  position: relative;
  width: 150px;
  white-space: nowrap;
}

.enable-label-2 {
  height: 16px;
  letter-spacing: 0;
  width: fit-content;
}

.verify-issuer-check-icon {
  height: 34px;
  width: 34px;
  position: absolute;
  top: -10px;
  right: 0;
}

.flex-row-2 {
  align-items: center;
  display: flex;
  margin-top: 32px;
  min-width: 148px;
}

.file-name-1 {
  height: 16px;
  letter-spacing: 0;
  margin-bottom: 2px;
  margin-left: 14px;
  width: 106px;
}

.line-4 {
  height: 1px;
  margin-top: 30px;
  width: 400px;
}

.buttons-container {
  align-items: center;
  display: flex;
  padding-top: 49px;
  padding-bottom: 37px;
  margin-top: 30px;
  width: 100%;
  justify-content: space-around;
  position: relative;
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: 15px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  min-height: 144px;
}

.verify-button {
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #2b4d89;
  height: 42px;
  width: 301px;
  border-radius: 37px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding: 8px 20px;
  position: absolute;
}

.verify-button:hover {
  width: 317px;
  height: 47px;
  box-shadow: -4px 4px 6px rgba(0, 0, 0, 0.25);
}

.verify-text {
  letter-spacing: 0;
  min-width: 50px;
  text-align: center;
}

.policy-icon {
  height: 27px;
  width: 27px;
  margin-right: 5px;
}

.verified-icon {
  margin-right: 13px;
}

.verified-button {
  border: 2px solid var(--chateau-green);
  cursor: auto;
  background-color: transparent;
  width: 160px;
  height: 42px;
  position: static;
  transform: unset;
}
.verified-button:hover {
  box-shadow: unset;
  position: static;
  width: 160px;
  height: 42px;
}

.verified-button .verify-text {
  color: var(--chateau-green);
}

.proof-file-cloud-icon {
  height: 24px;
  width: 24px;
}

.proof-file-button {
  background-color: var(--bay-of-many);
  height: 42px;
  min-width: 160px;
  border-radius: 37px;
  align-items: flex-start;
  padding: 8px 20px;
  display: flex;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
}
.proof-file-button:hover {
  box-shadow: -4px 4px 6px rgba(0, 0, 0, 0.25);
}

.proof-file-text {
  letter-spacing: 0;
  min-width: 84px;
  text-align: center;
  align-self: center;
  margin-bottom: 1.25px;
  margin-left: 8px;
}

.line-5 {
  height: 1px;
  margin-top: 51px;
  width: 400px;
}

.file-name-2 {
  height: 25px;
  letter-spacing: 0;
  margin-left: 14px;
  margin-top: 15px;
}

.share-buttons-container {
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-top: 21px;
  width: 100%;
  padding-left: 14px;
}

.share-button {
  height: 46px;
  width: 46px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-copy-button {
  background-color: #1a7b91;
}

.share-twitter-button {
  background-color: #1da1f2;
}
.share-twitter-button > img {
  width: 30px;
  height: 30px;
}

.share-facebook-button {
  background-color: #3b5998;
}
.share-facebook-button > img {
  width: 30px;
  height: 30px;
}

.share-plus-button {
  background-color: #ff6550;
}
.share-plus-button > img {
  width: 30px;
  height: 30px;
}

.poppins-494949-16px {
  color: #494949;
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
}

.transaction-id-field-2 {
  margin-top: 38px;
  min-height: 44px;
}

.transaction-id-field-value-2 {
  display: block;
  height: 14px;
  color: #767676;
  font-family: var(--font-family-poppins);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
}

.transaction-url-field-2 {
  margin-top: 33px;
  height: 23px;
  display: flex;
  align-items: center;
  color: var(--bay-of-many);
}

.transaction-url-field-2:hover > *:first-child {
  text-decoration: underline;
}

.transaction-url-field-2 > *:not(:last-child) {
  margin-right: 12px;
}

@media screen and (max-width: 900px) {
  .passport-data-container {
    max-width: 798px;
    width: 100%;
    position: static;
    min-height: 656px;
    height: unset;
  }
  .verify-button {
    max-width: 250px;
    width: 100%;
  }
  .verify-button.verified-button {
    width: 160px;
  }
  .small-download-button-container {
    display: inline;
    border-radius: 37px;
    width: 32px;
    height: 32px;
    background: linear-gradient(
      3deg,
      rgb(30, 60, 114) 100%,
      rgb(35, 101, 217) 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .small-download-button-icon {
    background-color: #fafafa;
    border-radius: 37px;
    width: 30px;
    height: 30px;
    padding: 2px;
  }
  .flex-row-1 {
    flex-wrap: wrap;
    min-width: unset;
    row-gap: 8px;
    width: 100%;
  }
  .share-buttons-container {
    flex-wrap: wrap;
    row-gap: 12px;
    column-gap: 12px;
    justify-content: center;
  }
  .overlap-group1-1 {
    height: unset;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 8px;
  }
  .inner-col-1 {
    padding: 25px;
  }
}
