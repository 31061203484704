.overlap-group1-61 {
  border-radius: 8px;
  height: 308px;
  margin-left: 37px;
  position: relative;
  width: 652px;
}

.chart-name-56 {
  left: 24px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 18px;
  white-space: nowrap;
}

.chart-9-17 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 22px;
  min-height: 195px;
  position: absolute;
  top: 95px;
  width: 628px;
}

.overlap-group-161 {
  height: 183px;
  margin-left: -1.5px;
  margin-top: -1px;
  position: relative;
  width: 617px;
}

.vector-8-17 {
  height: 69px;
  left: 2px;
  position: absolute;
  top: 38px;
  width: 615px;
}

.vector-28-17 {
  height: 57px;
  left: 0;
  position: absolute;
  top: 73px;
  width: 611px;
}

.vector-9-17 {
  height: 69px;
  left: 4px;
  position: absolute;
  top: 13px;
  width: 610px;
}

.vector-21-17 {
  height: 182px;
  left: 54px;
  position: absolute;
  top: 0;
  width: 1px;
}

.vector-22-17 {
  height: 182px;
  left: 137px;
  position: absolute;
  top: 0;
  width: 1px;
}

.vector-23-17 {
  height: 182px;
  left: 220px;
  position: absolute;
  top: 0;
  width: 1px;
}

.vector-24-17 {
  height: 182px;
  left: 303px;
  position: absolute;
  top: 1px;
  width: 1px;
}

.vector-25-17 {
  height: 182px;
  left: 386px;
  position: absolute;
  top: 1px;
  width: 1px;
}

.vector-26-17 {
  height: 182px;
  left: 469px;
  position: absolute;
  top: 1px;
  width: 1px;
}

.vector-27-17 {
  height: 182px;
  left: 552px;
  position: absolute;
  top: 1px;
  width: 1px;
}

.ellipse-42-17 {
  background: linear-gradient(
    180deg,
    rgb(255, 147.49, 99.87) 0%,
    rgb(242.25, 95.22, 51.48) 100%
  );
  border-radius: 9px;
  box-shadow: 0px 5px 25px #0000001a;
  height: 18px;
  left: 437px;
  position: absolute;
  top: 35px;
  width: 18px;
}

.flex-row-60 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  height: 6px;
  margin-right: 21.85px;
  margin-top: 7px;
  min-width: 522px;
}

.name-47 {
  letter-spacing: 0;
  min-height: 6px;
  opacity: 0.5;
  text-align: center;
  width: 23px;
}

.flex-row-item-17 {
  letter-spacing: 0;
  margin-left: 59px;
  min-height: 6px;
  opacity: 0.5;
  text-align: center;
  width: 24px;
}

.place-37 {
  letter-spacing: 0;
  margin-left: 59px;
  min-height: 6px;
  opacity: 0.5;
  text-align: center;
  width: 25px;
}

.apr-17 {
  letter-spacing: 0;
  margin-left: 59px;
  min-height: 6px;
  text-align: center;
  width: 23px;
}

.name-48 {
  letter-spacing: 0;
  margin-left: 59px;
  min-height: 6px;
  opacity: 0.5;
  text-align: center;
  width: 26px;
}

.jul-17 {
  letter-spacing: 0;
  margin-left: 59px;
  min-height: 6px;
  opacity: 0.5;
  text-align: center;
  width: 20px;
}

.flag_of_the_-netherlands-1-17 {
  height: 11px;
  left: 34px;
  object-fit: cover;
  position: absolute;
  top: 69px;
  width: 14px;
}
