.frame-1-12 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: inset 1px 1px 12px #000000ba;
  display: flex;
  overflow: hidden;
  width: 570px;
}

.chart-name-44 {
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 26px;
  margin-top: 17px;
  min-height: 24px;
  min-width: 37px;
  white-space: nowrap;
  position: absolute;
}

.overlap-group2-33 {
  align-items: flex-start;
  align-self: flex-end;
  background-image: url(/static/img/image-6-13@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 308px;
  justify-content: flex-end;
  margin-left: 1px;
  min-width: 570px;
  padding: 71.5px 156px;
}

.ellipse-44-9 {
  background-color: var(--zest);
  border-radius: 7px;
  height: 14px;
  width: 14px;
}

.frame-1-12.frame-1-13 {
  height: 308px;
  left: 90px;
  position: absolute;
  top: 131px;
}

.frame-1-12.frame-1-14 {
  height: 308px;
  left: 93px;
  position: absolute;
  top: 119px;
}
