.digital-passport {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 15px;
  box-shadow: 1px 14px 29px #0a2a881a;
  display: flex;
  height: auto;
  justify-content: flex-end;
  margin-right: 4px;
  min-width: 1260px;
  padding: 34px 45px;
}

.flex-col-52 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 727px;
  width: 390px;
}

.title {
  letter-spacing: 0;
  min-height: 36px;
}

.overlap-group13-4 {
  border-radius: 10px;
  height: 665px;
  margin-left: 32px;
  position: relative;
  width: 746px;
  display: flex;
  flex-direction: column;
}
